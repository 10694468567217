import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  YourInfoIcon,
  CitiesServed,
  ServicesOfferd,
  ServicePricing,
  AgreementIcon,
  SafetyPolicyIcon,
  DoneIcon,
  CompetencyTestIcon,
} from '../../assets/images/svg-icons';

export const getStepCount = (path) => {
  switch (path) {
    case '/signup/your-info': return 1;
    case '/signup/cities-served': return 2;
    case '/signup/services-offered': return 3;
    case '/signup/service-pricing': return 4;
    case '/signup/competency-test': return 5;
    case '/signup/sla-agreement': return 6;
    case '/signup/safety-policy': return 7;
    case '/signup/done': return 8;
    default: return 1;
  }
};

const Progress = () => {
  const location = useLocation();
  const currentStep = getStepCount(location?.pathname);
  const isActive = (step) => (currentStep >= step);

  const navigate = useNavigate();

  return (
    <div className={`header-progress-bar ${currentStep === 8 ? 'disable-pointer-events' : ''}`}>
      <div
        className={`header-progress-bar__module ${isActive(1) ? 'active' : ''} ${currentStep === 1 ? 'active-show' : ''} `}
        onClick={() => {
          if (isActive(1)) {
            navigate('/signup/your-info');
          }
        }}
        onKeyPress={() => {
          if (isActive(1)) {
            navigate('/signup/your-info');
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div className="icon">
          <YourInfoIcon />
        </div>
        <p>Your Info</p>
      </div>
      <div
        className={`header-progress-bar__module ${isActive(2) ? 'active' : ''} ${currentStep === 2 ? 'active-show' : ''}`}
        onClick={() => {
          if (isActive(2)) {
            navigate('/signup/cities-served');
          }
        }}
        onKeyPress={() => {
          if (isActive(2)) {
            navigate('/signup/cities-served');
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div className="icon">
          <CitiesServed />
        </div>
        <p>Service Area</p>
      </div>
      <div
        className={`header-progress-bar__module ${isActive(3) ? 'active' : ''} ${currentStep === 3 ? 'active-show' : ''}`}
        onClick={() => {
          if (isActive(3)) {
            navigate('/signup/services-offered');
          }
        }}
        onKeyPress={() => {
          if (isActive(3)) {
            navigate('/signup/services-offered');
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div className="icon">
          <ServicesOfferd />
        </div>
        <p>Services Offered</p>
      </div>
      <div
        className={`header-progress-bar__module ${isActive(4) ? 'active' : ''} ${currentStep === 4 ? 'active-show' : ''}`}
        onClick={() => {
          if (isActive(4)) {
            navigate('/signup/service-pricing');
          }
        }}
        onKeyPress={() => {
          if (isActive(4)) {
            navigate('/signup/service-pricing');
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div className="icon">
          <ServicePricing />
        </div>
        <p>Service Pricing</p>
      </div>
      <div
        className={`header-progress-bar__module ${isActive(5) ? 'active' : ''} ${currentStep === 5 ? 'active-show' : ''}`}
        onClick={() => {
          if (isActive(5)) {
            navigate('/signup/competency-test');
          }
        }}
        onKeyPress={() => {
          if (isActive(5)) {
            navigate('/signup/competency-test');
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div className="icon">
          <CompetencyTestIcon />
        </div>
        <p>Competency Test</p>
      </div>
      <div
        className={`header-progress-bar__module ${isActive(6) ? 'active' : ''} ${currentStep === 6 ? 'active-show' : ''}`}
        onClick={() => {
          if (isActive(6)) {
            navigate('/signup/sla-agreement');
          }
        }}
        onKeyPress={() => {
          if (isActive(6)) {
            navigate('/signup/sla-agreement');
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div className="icon">
          <AgreementIcon />
        </div>
        <p>SLA Agreement</p>
      </div>
      <div
        className={`header-progress-bar__module ${isActive(7) ? 'active' : ''} ${currentStep === 7 ? 'active-show' : ''}`}
        onClick={() => {
          if (isActive(7)) {
            navigate('/signup/safety-policy');
          }
        }}
        onKeyPress={() => {
          if (isActive(7)) {
            navigate('/signup/safety-policy');
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div className="icon">
          <SafetyPolicyIcon />
        </div>
        <p>Bckgd & Safety Policy</p>
      </div>
      <div
        className={`header-progress-bar__module ${isActive(8) ? 'active' : ''} ${currentStep === 8 ? 'active-show' : ''}`}
        onClick={() => {
          if (isActive(8)) {
            navigate('/signup/done');
          }
        }}
        onKeyPress={() => {
          if (isActive(8)) {
            navigate('/signup/done');
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div className="icon">
          <DoneIcon />
        </div>
        <p>Done</p>
      </div>
    </div>
  );
};

export default Progress;
