import React from 'react';

import YourInfo from './your-info';
import Cities from './cities-served';
import Services from './services-offered';
import Agreement from './agreement';
import Safety from './safety-policy';
import Done from './done';
import Facebook from './facebook';
import Twitter from './twitter';
import Instagram from './instagram';
import Info from './info-icon';
import Tick from './tick-icon';
import TickHeavy from './tick-heavy';
import InofIcon from './info-icon-black';
import CompetencyIcon from './competency-icon';
import Filter from './filter-icon';
import CheckrLogo from './checkr-logo';
import ServicePrice from './service-pricing';
import WarningIcon from './warning';
import StatusHighIcon from './status-high';
import LocationHeavy from './location-heavy';
import WarningRed from './warning-red';
import BrowseSm from './browse-sm';

export const YourInfoIcon = (props) => <YourInfo {...props} />;
export const CitiesServed = (props) => <Cities {...props} />;
export const ServicesOfferd = (props) => <Services {...props} />;
export const AgreementIcon = (props) => <Agreement {...props} />;
export const SafetyPolicyIcon = (props) => <Safety {...props} />;
export const DoneIcon = (props) => <Done {...props} />;
export const FacebookIcon = (props) => <Facebook {...props} />;
export const TwitterIcon = (props) => <Twitter {...props} />;
export const InstagramIcon = (props) => <Instagram {...props} />;
export const InfoIcon = (props) => <Info {...props} />;
export const TickIcon = (props) => <Tick {...props} />;
export const TickHeavyIcon = (props) => <TickHeavy {...props} />;
export const LocationHeavyIcon = (props) => <LocationHeavy {...props} />;
export const InofIconBlack = (props) => <InofIcon {...props} />;
export const CompetencyTestIcon = (props) => <CompetencyIcon {...props} />;
export const FilterIcon = (props) => <Filter {...props} />;
export const CheckrLogoIcon = (props) => <CheckrLogo {...props} />;
export const ServicePricing = (props) => <ServicePrice {...props} />;
export const Warning = (props) => <WarningIcon {...props} />;
export const WarningRedIcon = (props) => <WarningRed {...props} />;
export const StatusHigh = (props) => <StatusHighIcon {...props} />;
export const BrowseSmallIcon = (props) => <BrowseSm {...props} />;
