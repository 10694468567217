import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useProfile } from '../../context/profile-context/profile-context';
import Footer from './footer';
import Header from './header';

const PortalLayout = () => {
  const { profileDetails } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(profileDetails)) {
      navigate('/profile');
    }
  }, [profileDetails, navigate]);

  useEffect(() => {
    if (window?.smartlook) {
      window.smartlook('init', process.env.REACT_APP_SMART_LOOK_ID, { region: 'eu' });
      window.smartlook('record', { numbers: true });
      window.smartlook('identify', profileDetails?.user_hash, {
        name: `${profileDetails?.first_name} ${profileDetails?.last_name}`,
      });
    }
  }, [
    profileDetails?.user_hash,
    profileDetails?.first_name,
    profileDetails?.last_name,
  ]);

  return (
    !isEmpty(profileDetails) ? (
      <div>
        <Header />
        <div className="signup-outer">
          <Outlet />
        </div>
        <Footer />
      </div>
    ) : ''
  );
};

export default PortalLayout;
