import { request } from '../helpers/api';

export const getCompanyInfo = (params) => (
  request({
    method: 'GET',
    url: '/signup/company-info',
    params,
  })
);

export const saveCompanyInfo = (params) => (
  request({
    method: 'POST',
    url: '/signup/company-info',
    params,
  })
);

export const getCitisServed = (params) => (
  request({
    method: 'GET',
    url: '/signup/company-market-city',
    params,
  })
);

export const saveCitiesServed = (params) => (
  request({
    method: 'POST',
    url: '/signup/company-market-city',
    params,
  })
);

export const getServiceOffered = (params) => (
  request({
    method: 'GET',
    url: '/signup/company-services',
    params,
  })
);

export const saveServiceOffered = (params) => (
  request({
    method: 'POST',
    url: '/signup/company-services',
    params,
  })
);

export const getCompetencyTest = (params) => (
  request({
    method: 'GET',
    url: '/signup/competency-test',
    params,
  })
);

export const saveCompetencyTest = (params) => (
  request({
    method: 'POST',
    url: '/signup/competency-test',
    params,
  })
);

export const getSlaAgreement = (params) => (
  request({
    method: 'GET',
    url: '/signup/sla-agreement',
    params,
  })
);

export const saveSlaAgreement = (params) => (
  request({
    method: 'POST',
    url: '/signup/sla-agreement',
    params,
  })
);

export const getSafetyPolicy = (params) => (
  request({
    method: 'GET',
    url: '/signup/background-safety-policy',
    params,
  })
);

export const saveSafetyPolicy = (params) => (
  request({
    method: 'POST',
    url: '/signup/background-safety-policy',
    params,
  })
);

export const getProfile = (params) => (
  request({
    method: 'GET',
    url: '/signup/profile',
    params,
  })
);

export const logout = (params) => (
  request({
    method: 'POST',
    url: '/signup/logout',
    params,
  })
);

export const successCompletion = (params) => (
  request({
    method: 'GET',
    url: '/signup/successful-completion',
    params,
  })
);

export const getReferral = (params) => (
  request({
    method: 'GET',
    url: '/signup/referred-users',
    params,
  })
);

export const getServicePricing = (params) => (
  request({
    method: 'GET',
    url: '/signup/service-pricing',
    params,
  })
);

export const editServicePricing = (servicePricingId, params) => (
  request({
    method: 'PUT',
    url: `/signup/service-pricing/${servicePricingId}`,
    params,
  })
);

export const addServicePricing = (params) => (
  request({
    method: 'POST',
    url: '/signup/service-pricing',
    params,
  })
);

export const completeServicePricing = (params) => (
  request({
    method: 'POST',
    url: '/signup/complete-service-pricing',
    params,
  })
);

export const getLulaProScoringMetrics = (params) => (
  request({
    method: 'GET',
    url: '/signup/static-content/lula-pro-scoring-metrics',
    params,
  })
);

export const uploadGeneralInsuranceDocuments = (params) => (
  request({
    method: 'POST',
    url: '/signup/upload-document',
    params,
    content_type: 'form-data',
  })
);

export const deleteGeneralInsuranceDocuments = (params) => (
  request({
    method: 'DELETE',
    url: '/signup/delete-document',
    params,
  })
);
