import React from 'react';

const WarningRed = () => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1289 0C5.06351 0 0.128906 4.93467 0.128906 11.0001C0.128906 17.0655 5.06351 22 11.1289 22C17.1943 22 22.1289 17.0655 22.1289 11.0001C22.1289 4.93467 17.1943 0 11.1289 0ZM11.1289 20C6.16624 20 2.12891 15.9627 2.12891 11.0001C2.12891 6.03747 6.16624 2 11.1289 2C16.0916 2 20.1289 6.03747 20.1289 11.0001C20.1289 15.9627 16.0915 20 11.1289 20Z" fill="#FF4949" />
    <path d="M11.129 4.66699C10.3939 4.66699 9.7959 5.26539 9.7959 6.00093C9.7959 6.73579 10.3939 7.33366 11.129 7.33366C11.864 7.33366 12.462 6.73579 12.462 6.00093C12.462 5.26539 11.864 4.66699 11.129 4.66699Z" fill="#FF4949" />
    <path d="M11.1289 9.33301C10.5766 9.33301 10.1289 9.78074 10.1289 10.333V16.333C10.1289 16.8853 10.5766 17.333 11.1289 17.333C11.6812 17.333 12.1289 16.8853 12.1289 16.333V10.333C12.1289 9.78074 11.6812 9.33301 11.1289 9.33301Z" fill="#FF4949" />
  </svg>

);

export default WarningRed;
