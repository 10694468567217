import React from 'react';

const InofIconBlack = (props) => (
  <svg {...props} width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 0C5.1589 0 0 5.15897 0 11.5001C0 17.8412 5.1589 23 11.5 23C17.8411 23 23 17.8412 23 11.5001C23 5.15897 17.8411 0 11.5 0ZM11.5 20.9091C6.31176 20.9091 2.09091 16.6882 2.09091 11.5001C2.09091 6.3119 6.31176 2.09091 11.5 2.09091C16.6882 2.09091 20.9091 6.3119 20.9091 11.5001C20.9091 16.6882 16.6882 20.9091 11.5 20.9091Z" fill="black" />
    <path d="M11.4999 4.87875C10.7314 4.87875 10.1062 5.50435 10.1062 6.27332C10.1062 7.04159 10.7314 7.66663 11.4999 7.66663C12.2683 7.66663 12.8935 7.04159 12.8935 6.27332C12.8935 5.50435 12.2683 4.87875 11.4999 4.87875Z" fill="black" />
    <path d="M11.5 9.75763C10.9227 9.75763 10.4546 10.2257 10.4546 10.8031V17.0758C10.4546 17.6532 10.9227 18.1213 11.5 18.1213C12.0774 18.1213 12.5455 17.6532 12.5455 17.0758V10.8031C12.5455 10.2257 12.0774 9.75763 11.5 9.75763Z" fill="black" />
  </svg>
);
export default InofIconBlack;
