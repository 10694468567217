import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, Col, Container, Form, FormGroup, Row,
} from 'reactstrap';
import { Warning, WarningRedIcon } from '../../../assets/images/svg-icons';
import { useProfile } from '../../../context/profile-context/profile-context';
import catchHandler from '../../../helpers/catch-handler';
import { scrollToError } from '../../../helpers/utils';
import {
  getCitisServed,
  saveCitiesServed,
} from '../../../services/pro-signup-services';
import CheckboxArray from '../../shared/form-fields/checkbox-array';
import InputField from '../../shared/form-fields/input-field';
import { getStepCount } from '../../wizard/progress';
import { fetchProfile } from '../profile';
import useCitiesServed from './hooks/use-cities-served';

const CitiesServed = () => {
  const [dataSource, setDataSource] = useState({});
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [savingDetails, setSavingDetails] = useState(false);
  const [pickedStates, setPickedStates] = useState([]);
  const [pickedMarketCities, setPickedMarketCities] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const { setProfileDetails } = useProfile();

  const [citiesSchema] = useCitiesServed(
    pickedStates,
    pickedMarketCities,
    dataSource,
  );

  const buildFormData = useCallback(() => {
    let selectedStateIds = [];
    const selectedCities = [];
    const selectedCounties = {};
    if (!isEmpty(dataSource?.states)) {
      selectedStateIds = dataSource?.selected_states?.map((item) => ({
        id: item?.state_id,
      }));
      dataSource?.selected_market_cities?.forEach((item) => {
        if (isEmpty(selectedCities?.filter((city) => city?.id === item?.market_city_id))) {
          selectedCities.push({ id: item?.market_city_id });
        }
      });
      Object.keys(dataSource?.selected_counties)?.forEach((marketCityId) => {
        const counties = dataSource?.selected_counties[marketCityId];
        selectedCounties[`market_city_id_${marketCityId}`] = counties?.map(
          (item) => ({ id: item?.county_id }),
        );
      });
    }

    return {
      states: selectedStateIds,
      market_cities: selectedCities,
      counties: selectedCounties,
      searchKeyword: '',
      cities: '',
      is_provider_state_not_in_list: Number(dataSource?.is_provider_state_not_in_list) === 1,
    };
  }, [dataSource]);

  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(),
    resolver: yupResolver(citiesSchema),
  });

  const {
    formState: { errors },
    reset,
    setError,
    watch,
    setValue,
    clearErrors,
    trigger,
    getValues,
  } = methods;

  const {
    searchKeyword,
    states,
    market_cities: marketCities,
    is_provider_state_not_in_list: isProviderStateNotInList,
  } = watch();

  useEffect(() => {
    setPickedStates(states);
  }, [states]);

  useEffect(() => {
    setPickedMarketCities(marketCities);
  }, [marketCities]);

  useEffect(() => {
    setFetchingDetails(true);
    getCitisServed()
      .then((res) => {
        setFetchingDetails(false);
        if (!isEmpty(res?.data)) {
          const allStates = {};
          const cities = {};
          const counties = {};
          res?.data?.states?.forEach((state) => {
            allStates[state?.state_id] = {
              state_id: state?.state_id,
              state_name: state?.state_name,
              market_cities: state?.market_cities?.map(
                (item) => item?.market_city_id,
              ),
            };
            state?.market_cities?.forEach((city) => {
              const currentCountyIds = state?.counties?.[
                city?.market_city_id
              ]?.map((item) => item?.county_id);
              const countiesUnderMarketCities = cities[city?.market_city_id]?.counties;
              cities[city?.market_city_id] = {
                ...city,
                counties: countiesUnderMarketCities
                  ? [
                    ...(countiesUnderMarketCities || []),
                    ...(currentCountyIds || [])] : (currentCountyIds || []),
              };
            });
            Object.keys(state?.counties)?.forEach((marketCityId) => {
              const countiesArray = state?.counties[marketCityId];
              countiesArray?.forEach((county) => {
                counties[county?.county_id] = county;
              });
            });
          });
          setDataSource({
            ...res?.data,
            states: allStates,
            cities,
            counties,
          });
        }
      })
      .catch((err) => {
        catchHandler(err, setError, true);
        setFetchingDetails(false);
      });
  }, [setError]);

  useEffect(() => {
    if (!isEmpty(dataSource?.states)) {
      reset(buildFormData());
    }
  }, [dataSource, reset, buildFormData]);

  const onError = (formErrors) => {
    const formattedErrors = {};
    Object.keys(formErrors || {})?.forEach((item) => {
      if (item === 'counties') {
        Object.keys(formErrors?.[item])?.forEach((errorKey) => {
          formattedErrors[`${item}.${errorKey}`] = formErrors?.[item]?.[errorKey];
        });
      } else {
        formattedErrors[item] = formErrors?.[item];
      }
    });
    scrollToError(formattedErrors, true);
  };

  const onSubmit = (formData) => {
    setSavingDetails(true);
    const selectedStates = formData?.states?.map((state) => {
      const countiesIds = [];
      const stateData = dataSource?.states?.[state?.id];
      const selectedMarketCitiesUnderState = formData?.market_cities?.filter(
        (item) => stateData?.market_cities?.includes(item?.id),
      );

      const marketCityIds = selectedMarketCitiesUnderState?.map((city) => {
        const counties = formData?.counties?.[
          `market_city_id_${city?.id}`
        ]?.map((county) => county.id);
        if (!isEmpty(counties)) {
          countiesIds.push(...counties);
        }
        return city?.id;
      });

      return {
        state_id: state?.id,
        market_city_ids: marketCityIds,
        county_ids: countiesIds,
      };
    });
    const payload = {
      states: selectedStates,
      ...(isEmpty(selectedStates)
        ? { is_provider_state_not_in_list: formData?.is_provider_state_not_in_list ? 1 : 0 }
        : { is_provider_state_not_in_list: 0 }),
    };

    saveCitiesServed(payload)
      .then(() => {
        fetchProfile(setSavingDetails, navigate, setProfileDetails, {
          current_step: getStepCount(location?.pathname) - 1,
        });
      })
      .catch((err) => {
        setSavingDetails(false);
        catchHandler(err, setError, true);
      });
  };

  const showStates = () => {
    let statesArray = [];
    if (searchKeyword) {
      statesArray = Object.keys(dataSource?.states ?? {})?.filter((stateId) => {
        const stateObj = dataSource?.states[stateId];
        if (
          stateObj?.state_name
            ?.replace(/ /g, '')
            ?.toLowerCase()
            ?.includes(searchKeyword?.replace(/ /g, '')?.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      if (isEmpty(statesArray)) {
        return <p>No results found</p>;
      }
    } else {
      statesArray = Object.keys(dataSource?.states || {});
    }

    return statesArray?.map((stateId) => {
      const id = `state${stateId}`;
      return (
        <Col key={id}>
          <div className="city-item">
            <CheckboxArray
              name="states"
              id={id}
              currentValue={{
                id: stateId,
              }}
              className="custom-check"
              checkHandler={() => {
                if (isProviderStateNotInList) {
                  setValue('is_provider_state_not_in_list', false);
                }
              }}
              unCheckHandler={() => {
                const selectedStates = getValues('states');
                const citiesOfThisState = dataSource?.states?.[stateId]?.market_cities;

                const newMarketCities = marketCities?.filter((item) => {
                  const checkWithSelectedStates = selectedStates?.filter((state) => {
                    const stateData = dataSource?.states?.[state?.id];
                    if (stateData?.market_cities?.includes(item?.id)) {
                      return true;
                    }
                    return false;
                  });

                  if (!citiesOfThisState?.includes(item?.id)) {
                    return true;
                  } if (!isEmpty(checkWithSelectedStates)) {
                    return true;
                  }
                  return false;
                });
                setValue('market_cities', newMarketCities);
              }}
            />
            <div className="city-item--inner">
              <div className="check-box" />
              <div className="city-name">
                <div>{dataSource?.states[stateId]?.state_name}</div>
              </div>
            </div>
          </div>
        </Col>
      );
    });
  };
  const showCounty = () => marketCities?.map((cityId, index) => {
    const cityData = dataSource?.cities[cityId?.id];
    const counties = cityData?.counties || [];

    return (
      <React.Fragment key={`selected-marketcities${cityId?.id}`}>
        {index === 0 ? (
          <>
            <Row>
              <Col className="my-4">
                <Row>
                  <Col>
                    <h3>Which counties do you serve?</h3>
                    <div className="header-divider" />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="global-warnings">
                  <div className="global-warnings--data">
                    <Warning />
                    <p>
                      We auto-selected counties mapped to the selected cities.
                      You can edit the selections.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : ''}
        <Row>
          <Col>
            <div className="sub-head-city">
              <div>
                <h5>
                  {cityData?.market_city}
                  {' '}
                </h5>
                <p>and surrounding suburbs</p>
              </div>
              <div>
                {(counties ?? [])?.length > 1 ? (
                  <>
                    (
                    <Button
                      onClick={() => {
                        const currentCountyValues = counties?.map(
                          (countyId) => ({ id: countyId }),
                        );

                        setValue(
                          `counties.market_city_id_${cityId?.id}`,
                          currentCountyValues,
                        );
                        if (!isEmpty(errors?.counties?.[`market_city_id_${cityId?.id}`])) {
                          clearErrors(`counties.market_city_id_${cityId?.id}`);
                        }
                      }}
                      className="select-link"
                    >
                      Select All
                    </Button>
                    ) / (
                    <Button
                      onClick={() => {
                        setValue(
                          `counties.market_city_id_${cityId?.id}`,
                          [],
                        );
                      }}
                      className="select-link"
                    >
                      Deselect All
                    </Button>
                    )
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="service-city">
          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5">
            {(counties || []).map((countyId) => {
              const id = `county${countyId}`;
              const countyData = dataSource?.counties[countyId];

              return (
                <Col key={id}>
                  <div className="city-item">
                    <CheckboxArray
                      name={`counties.market_city_id_${cityId?.id}`}
                      id={id}
                      currentValue={{
                        id: countyId,
                      }}
                      className="custom-check"
                    />
                    <div className="city-item--inner">
                      <div className="check-box" />
                      <div className="city-name">
                        <div>{`${countyData?.county_name}, ${countyData?.state_code}`}</div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <ErrorMessage
            errors={errors}
            name={`counties.market_city_id_${cityId?.id}`}
            render={({ message }) => (
              <small name="cities-error" className="text-danger">
                {message}
              </small>
            )}
          />
        </div>
      </React.Fragment>
    );
  });
  return (
    <div>
      {fetchingDetails ? (
        <div className="page-loader" />
      ) : (
        <Container>
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
              <Row>
                <Col className="my-4">
                  <Row>
                    <Col xs="12" md="8">
                      <h3>Which state(s) do you serve?</h3>
                      <div className="header-divider" />
                    </Col>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <InputField
                          className="filter-by"
                          type="search"
                          placeholder="Filter by State"
                          name="searchKeyword"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="global-warnings">
                    <div className="global-warnings--data">
                      {dataSource?.provider_state_not_list_alert_message
                        ? <WarningRedIcon /> : <Warning />}
                      <p className={dataSource?.provider_state_not_list_alert_message ? 'state-not-listed-alert' : ''}>
                        {dataSource?.provider_state_not_list_alert_message
                          ? dataSource?.provider_state_not_list_alert_message
                          : 'We have auto-populated your service area but you can change it'}
                      </p>
                    </div>
                    {dataSource?.your_location && !dataSource?.provider_state_not_list_alert_message
                      ? (
                        <div className="global-warnings--stat">
                          <p>
                            <strong>{`Your Location: ${dataSource?.your_location}`}</strong>
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                  </div>
                </Col>
              </Row>
              <div className="service-city">
                <Row className="row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5">
                  {showStates()}
                </Row>
                <ErrorMessage
                  errors={errors}
                  name="states"
                  render={({ message }) => (
                    <small name="cities-error" className="text-danger">
                      {message}
                    </small>
                  )}
                />
              </div>
              {isEmpty(states) ? (
                <Row>
                  <Col>
                    <div className="city-consent">
                      <InputField
                        name="is_provider_state_not_in_list"
                        id="is_provider_state_not_in_list_checkbox"
                        type="checkbox"
                        value={!isProviderStateNotInList}
                        checked={!!isProviderStateNotInList}
                        onClick={() => {
                          clearErrors();
                        }}
                      />
                      <label htmlFor="is_provider_state_not_in_list_checkbox">
                        Select if the state(s) you serve is not listed above
                      </label>
                    </div>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {/* Market City section Starts */}
              {states?.map((state, index) => {
                const stateData = dataSource?.states[state?.id];
                return (
                  <React.Fragment key={`selected-state${state?.id}`}>
                    {index === 0 ? (
                      <>
                        <Row>
                          <Col className="my-4">
                            <Row>
                              <Col>
                                <h3>Which city/cities do you serve?</h3>
                                <div className="header-divider" />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="global-warnings">
                              <div className="global-warnings--data">
                                <Warning />
                                <p>
                                  Used 60-mile radius for city auto-selection.
                                  You can edit the selections.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ''
                    )}
                    <Row>
                      <Col>
                        <div className="sub-head-city">
                          <h5>{stateData?.state_name}</h5>
                          <div>
                            {(stateData?.market_cities ?? [])?.length > 1 ? (
                              <>
                                (
                                <Button
                                  onClick={() => {
                                    const newMarketCities = [...(marketCities || [])];
                                    stateData?.market_cities?.forEach((cityId) => {
                                      if (isEmpty(newMarketCities?.filter(
                                        (cItem) => cItem?.id === cityId,
                                      ))) {
                                        newMarketCities.push({ id: cityId });
                                      }
                                    });
                                    setValue(
                                      'market_cities',
                                      newMarketCities,
                                    );
                                    if (!isEmpty(errors?.cities)) {
                                      clearErrors('cities');
                                    }
                                  }}
                                  className="select-link"
                                >
                                  Select All
                                </Button>
                                ) / (
                                <Button
                                  onClick={() => {
                                    const newMarketCities = [...(marketCities || [])];
                                    stateData?.market_cities?.forEach((cityId) => {
                                      const cityIndex = newMarketCities?.findIndex(
                                        (cItem) => cItem?.id === cityId,
                                      );
                                      if (cityIndex > -1) {
                                        newMarketCities.splice(cityIndex, 1);
                                      }
                                    });
                                    setValue(
                                      'market_cities',
                                      newMarketCities,
                                    );
                                    trigger('cities');
                                  }}
                                  className="select-link"
                                >
                                  Deselect All
                                </Button>
                                )
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="service-city">
                      <Row className="row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5">
                        {(stateData?.market_cities || []).map(
                          (marketCityId) => {
                            const id = `city${marketCityId}`;
                            const cityData = dataSource?.cities[marketCityId];
                            return (
                              <Col key={id}>
                                <div className="city-item">
                                  <CheckboxArray
                                    name="market_cities"
                                    id={id}
                                    currentValue={{
                                      id: marketCityId,
                                    }}
                                    // shouldUnregister
                                    className="custom-check"
                                    checkHandler={() => {
                                      if (!isEmpty(errors?.cities)) {
                                        clearErrors('cities');
                                      }
                                    }}
                                  />
                                  <div className="city-item--inner">
                                    <div className="check-box" />
                                    <div className="city-name">
                                      <div>{cityData?.market_city}</div>
                                      <span>(and surrounding areas)</span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          },
                        )}
                      </Row>
                    </div>
                    {states?.length === (index + 1) ? <InputField type="hidden" name="cities" /> : ''}
                  </React.Fragment>
                );
              })}
              {/* Market City section End */}

              {/* County section Starts */}
              {showCounty()}
              {/* County section End */}

              <Row>
                <Col>
                  <div className="action-wrapper">
                    <Button
                      type="submit"
                      disabled={savingDetails}
                      color="primary"
                    >
                      {savingDetails ? (
                        <div className="loader-dual-ring" />
                      ) : (
                        'Save & Continue'
                      )}
                    </Button>
                    <Button
                      color="link"
                      onClick={() => {
                        navigate('/signup/your-info');
                      }}
                    >
                      Previous Step
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </Container>
      )}
    </div>
  );
};

export default CitiesServed;
