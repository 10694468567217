import React from 'react';

const LocationHeavy = (props) => (
  <svg {...props} width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="7" width="92" height="92" rx="46" fill="#48C898" />
    <path d="M62.3489 61.9999C68.308 52.6491 67.5589 53.8157 67.7306 53.5719C69.9002 50.5118 71.0469 46.9102 71.0469 43.1562C71.0469 33.2009 62.9683 25 53 25C43.0642 25 34.9531 33.1847 34.9531 43.1562C34.9531 46.9078 36.1239 50.6037 38.3645 53.705L43.6508 62C37.9989 62.8686 28.3906 65.4569 28.3906 71.1562C28.3906 73.2338 29.7467 76.1946 36.2068 78.5018C40.7176 80.1128 46.6815 81 53 81C64.8152 81 77.6094 77.6671 77.6094 71.1562C77.6094 65.456 68.0124 62.8703 62.3489 61.9999ZM41.1054 51.9002C41.0873 51.872 41.0685 51.8445 41.0488 51.8173C39.1843 49.2523 38.2344 46.2123 38.2344 43.1562C38.2344 34.9512 44.8413 28.2812 53 28.2812C61.1418 28.2812 67.7656 34.9541 67.7656 43.1562C67.7656 46.2172 66.8336 49.154 65.07 51.6515C64.9119 51.86 65.7365 50.579 53 70.5644L41.1054 51.9002ZM53 77.7188C40.0944 77.7188 31.6719 73.9253 31.6719 71.1562C31.6719 69.2951 35.9996 66.2349 45.5896 65.0421L51.6164 74.499C51.9176 74.9717 52.4393 75.2578 52.9999 75.2578C53.5604 75.2578 54.0823 74.9716 54.3834 74.499L60.41 65.0421C70.0003 66.2349 74.3281 69.2951 74.3281 71.1562C74.3281 73.9018 65.9814 77.7188 53 77.7188Z" fill="white" />
    <path d="M53 34.9531C48.4768 34.9531 44.7969 38.633 44.7969 43.1562C44.7969 47.6795 48.4768 51.3594 53 51.3594C57.5232 51.3594 61.2031 47.6795 61.2031 43.1562C61.2031 38.633 57.5232 34.9531 53 34.9531ZM53 48.0781C50.2861 48.0781 48.0781 45.8702 48.0781 43.1562C48.0781 40.4423 50.2861 38.2344 53 38.2344C55.7139 38.2344 57.9219 40.4423 57.9219 43.1562C57.9219 45.8702 55.7139 48.0781 53 48.0781Z" fill="white" />
    <rect x="1.5" y="1.5" width="103" height="103" rx="51.5" stroke="#48C898" strokeWidth="3" />
  </svg>

);

export default LocationHeavy;
