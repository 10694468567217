import React from 'react';
import {
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { Link } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';

import CheckboxArray from '../../../shared/form-fields/checkbox-array';
import InputField from '../../../shared/form-fields/input-field';

const Checkbox = ({ name, question }) => {
  const { formState: { errors }, setValue, watch } = useFormContext();
  const value = watch(name);

  return (!isEmpty(question?.extra?.options) ? (
    <>
      {Object.keys(question?.extra?.options).length > 1 ? (
        <Col className="mb-5">
          <div className="mb-3">
            {question?.question}
            <span>
              (
              <Link
                to="#"
                onClick={() => {
                  setValue(name, Object.keys(question?.extra?.options).map((option) => ({
                    options: question?.extra?.options[option],
                    id: option,
                  })), { shouldValidate: true });
                }}
              >
                {' '}
                Select All
                {' '}

              </Link>
              /
              <Link
                to="#"
                onClick={() => setValue(name, [], { shouldValidate: true })}
              >
                {' '}
                Deselect All
                {' '}
              </Link>
              )
            </span>
          </div>
          {Object.keys(question?.extra?.options).map((option) => (
            <FormGroup check>
              <CheckboxArray
                name={name}
                id={option}
                defaultValue={[]}
                shouldUnregister
                currentValue={{ option: question?.extra?.options[option], id: option }}
              />
              <Label htmlFor={option}>
                {question?.extra?.options[option]}
              </Label>
            </FormGroup>
          ))}
          {Object.keys(question?.extra?.options).length > 1 ? (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <small className="text-danger">{message}</small>
              )}
            />
          ) : '' }
        </Col>
      ) : (
        <FormGroup check>
          <InputField
            name={name}
            id={name}
            type="checkbox"
            checked={!!value}
            shouldUnregister
            clearError
          />
          <Label className="form-check-label after-hour-rating" htmlFor={name}>
            I will provide license number later.
          </Label>
        </FormGroup>
      )}
      <span />
    </>
  ) : '');
};

export default Checkbox;
