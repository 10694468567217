import React from 'react';

const TickHeavyIcon = (props) => (
  <svg width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="7" width="92" height="92" rx="46" fill="#48C898" />
    <rect x="1.5" y="1.5" width="103" height="103" rx="51.5" stroke="#48C898" strokeWidth="3" />
    <path d="M79.7323 34.7444C78.0443 33.0541 75.3032 33.0551 73.613 34.7444L46.0715 62.2869L33.8305 50.046C32.1403 48.3557 29.4004 48.3557 27.7101 50.046C26.0198 51.7363 26.0198 54.4762 27.7101 56.1664L43.0107 71.467C43.8553 72.3116 44.9627 72.735 46.0703 72.735C47.1779 72.735 48.2865 72.3127 49.1311 71.467L79.7323 40.8647C81.4226 39.1756 81.4226 36.4345 79.7323 34.7444Z" fill="white" />
  </svg>

);
export default TickHeavyIcon;
