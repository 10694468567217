import React from 'react';

import InputField from '../../../shared/form-fields/input-field';

const TextField = ({ name }) => (
  <>
    <strong>License Number:</strong>
    <div>
      <InputField
        name={name}
        shouldUnregister
        clearError
      />
    </div>
  </>
);

export default TextField;
