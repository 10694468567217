import React, { useEffect, useState } from 'react';
import {
  Container, Label, Button, Row, Col, Form, FormGroup,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckrLogoIcon, TickIcon } from '../../assets/images/svg-icons';
import { scrollToError } from '../../helpers/utils';
import InputField from '../shared/form-fields/input-field';
import {
  getSafetyPolicy,
  saveSafetyPolicy,
} from '../../services/pro-signup-services';
import catchHandler from '../../helpers/catch-handler';
import BackgroundCheckModal from './sla-agreement/background-check-modal';

const SafetyPolicy = () => {
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [savingDetails, setSavingDetails] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [showBackgroundCheckModal, setShowBackgroundCheckModal] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    agree_bg_check_policy: yup
      .boolean()
      .oneOf(
        [true],
        'You Must Agree to our Background Check Policy to Proceed.',
      )
      .nullable(),
    is_convicted_of_a_felony: yup
      .string()
      .required('Please select an option')
      .nullable(),
    convicted_offence_details: yup.string().when('is_convicted_of_a_felony', {
      is: 'yes',
      then: yup.string().matches(/^(?!\s+$).*/, 'This field cannot have only blank spaces in it. ').required('Please enter the details').nullable(),
    }),
    offence_convicted_state: yup.string().when('is_convicted_of_a_felony', {
      is: 'yes',
      then: yup.string().matches(/^(?!\s+$).*/, 'This field cannot have only blank spaces in it. ').required('Please enter the details').nullable(),
    }),
  });

  const setDefaultValues = (data) => {
    const isCnvtedFlnyDefaults = () => {
      if (typeof data?.is_convicted_of_a_felony === 'number') {
        return Number(data?.is_convicted_of_a_felony) ? 'yes' : 'no';
      }
      return null;
    };
    const formDefaultValues = {
      agree_bg_check_policy: !!Number(data?.agree_bg_check_policy),
      convicted_offence_details: data?.convicted_offence_details ?? '',
      is_convicted_of_a_felony: isCnvtedFlnyDefaults(),
      offence_convicted_state: data?.offence_convicted_state ?? '',
    };
    return formDefaultValues;
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: setDefaultValues(),
  });

  const {
    formState: { errors },
    control,
    reset,
    setError,
    watch,
  } = methods;

  useEffect(() => {
    if (!isEmpty(dataSource)) {
      reset(setDefaultValues(dataSource));
    }
  }, [reset, dataSource]);

  useEffect(() => {
    setFetchingDetails(true);
    getSafetyPolicy()
      .then((res) => {
        setDataSource(res?.data);
        setFetchingDetails(false);
      })
      .catch((err) => {
        catchHandler(err, setError);
        setFetchingDetails(false);
      });
  }, [setError]);

  const onError = (formErrors) => {
    scrollToError(formErrors);
  };

  const isConvictedFelony = useWatch({
    control,
    name: 'is_convicted_of_a_felony',
  });

  const onSubmit = (data) => {
    const payload = {
      agree_bg_check_policy: data?.agree_bg_check_policy ? '1' : '0',
      convicted_offence_details: data?.convicted_offence_details ?? null,
      is_convicted_of_a_felony:
        data?.is_convicted_of_a_felony === 'yes' ? '1' : '0',
      offence_convicted_state: data?.offence_convicted_state ?? null,
    };
    setSavingDetails(true);
    saveSafetyPolicy(payload)
      .then((res) => {
        setSavingDetails(false);
        navigate('/signup/done', { state: { dashboardUrl: dataSource?.dashboard_url } });
      })
      .catch((err) => {
        setSavingDetails(false);
        catchHandler(err, setError);
      });
  };

  const { agree_bg_check_policy: agreeBgCheckPolicy } = watch();

  return (
    <div>
      {fetchingDetails ? (
        <div className="page-loader" />
      ) : (
        <Container>
          <div className="main-wrapper">
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                <Row>
                  <Col className="my-4">
                    <Row>
                      <Col xs="12">
                        <h3>Background and Safety Policy</h3>
                        <div className="header-divider" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xl="8" md="6">
                    <Row>
                      <Col>
                        <p>
                          Safety is very important to Lula. Our customers trust
                          us with their home and we want to make sure they are
                          in good hands.
                        </p>
                        <p>
                          Service providers must conduct background checks, or
                          permit Lula to conduct background checks, on all
                          Service Personnel who, in the course providing
                          services, enter a Lula Customer’s home or residence.
                          {' '}
                          <b>
                            If Lula is permitted to run the background check
                            then Lula has the right to be reimbursed $35 after
                            your first completed job on the platform.
                          </b>
                          {' '}
                          We have partnered with several 3rd party reputable
                          companies to handle our background checks:
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-2">
                        <h4> Have you have been convicted of a felony in the past 7 years?</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="form-wrapper">
                          <div className="form-item me-5">
                            <div className="form-radio">
                              <FormGroup check>
                                <InputField
                                  name="is_convicted_of_a_felony"
                                  type="radio"
                                  value="yes"
                                  id="radio1"
                                  clearError
                                  checked={!!(isConvictedFelony === 'yes')}
                                  hideFormError
                                />
                                <TickIcon />
                                <Label className="form-check-label" htmlFor="radio1">Yes</Label>
                              </FormGroup>
                            </div>
                          </div>
                          <div className="form-item">
                            <div className="form-radio">
                              <FormGroup check>
                                <InputField
                                  name="is_convicted_of_a_felony"
                                  type="radio"
                                  value="no"
                                  id="radio2"
                                  clearError
                                  checked={!!(isConvictedFelony === 'no')}
                                  hideFormError
                                />
                                <TickIcon />
                                <Label className="form-check-label" htmlFor="radio2">No</Label>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="is_convicted_of_a_felony"
                          render={({ message }) => (
                            <small name="states-error" className="text-danger">
                              {message}
                            </small>
                          )}
                        />
                      </Col>
                    </Row>
                    {isConvictedFelony === 'yes' ? (
                      <Row xs="1">
                        <Col className="mb-3 mt-4">
                          <div className="form-item">
                            <div className="form-group">
                              <Label>Please state offense and year</Label>
                              <InputField
                                name="convicted_offence_details"
                                type="textarea"
                                shouldUnregister
                                clearError
                                className="textarea-lg"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="mb-3">
                          <div className="form-item">
                            <div className="form-group">
                              <Label>
                                Please provide county/state of offense
                              </Label>
                              <InputField
                                name="offence_convicted_state"
                                type="text"
                                shouldUnregister
                                clearError
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                    <Row>
                      <Col className="mt-3 mb-5">
                        <div className="form-single-box">
                          <div className="form-check-box">
                            <InputField
                              name="agree_bg_check_policy"
                              type="checkbox"
                              id="checkbox1"
                              clearError
                              checked={!!agreeBgCheckPolicy}
                              hideFormError
                            />
                            <label htmlFor="checkbox1">
                              <TickIcon />
                            </label>
                          </div>
                          <Label htmlFor="checkbox1" className="label">
                            I agree to Lula&lsquo;s
                            <Link
                              to="#"
                              onClick={() => setShowBackgroundCheckModal(true)}
                            >
                              {' '}
                              Background Check Policy
                            </Link>
                            .
                          </Label>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="agree_bg_check_policy"
                          render={({ message }) => (
                            <small className="text-danger">{message}</small>
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="4" md="6">
                    <div className="logo-box">
                      <CheckrLogoIcon />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="action-wrapper">
                      <Button
                        color="primary"
                        disabled={savingDetails}
                      >
                        {savingDetails ? (
                          <div className="loader-dual-ring" />
                        ) : (
                          'Save & Continue'
                        )}
                      </Button>
                      <Link
                        to="/signup/sla-agreement"
                        className="btn-link"
                      >
                        Previous Step
                      </Link>
                    </div>
                  </Col>
                </Row>

              </Form>
            </FormProvider>
          </div>
        </Container>
      )}
      {showBackgroundCheckModal ? (
        <BackgroundCheckModal
          closeModal={() => setShowBackgroundCheckModal(false)}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default SafetyPolicy;
