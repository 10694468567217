import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { isEmpty } from 'lodash';

const DropZone = (props) => {
  const {
    onChange,
    uploadBoundary,
    onChangeHandler,
    isMultiple,
    maxFiles,
    disabled,
    accept,
    setError,
    clearErrors,
    propName,
    hideErrorMessage,
    enableSetErrorMessageInHookForm,
    dragActiveBoundary,
  } = props;

  const onDrop = (acceptedFiles) => {
    if (!isEmpty(acceptedFiles)) {
      onChange(acceptedFiles);
    }
    clearErrors(propName);
    onChangeHandler();
  };

  const {
    getRootProps, getInputProps, fileRejections, isDragActive,
  } = useDropzone({
    onDrop,
    accept,
    multiple: isMultiple,
    maxFiles,
    disabled,
  });

  useEffect(() => {
    if (!isEmpty(fileRejections) && enableSetErrorMessageInHookForm) {
      setError(propName, { type: 'custom', fileRejections });
    }
  }, [setError, fileRejections, propName, enableSetErrorMessageInHookForm]);

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {(dragActiveBoundary && !isDragActive) || !dragActiveBoundary ? uploadBoundary : ''}
        {dragActiveBoundary && isDragActive ? dragActiveBoundary : ''}
      </div>
      {!hideErrorMessage && fileRejections ? (
        <div className="error_text">
          {fileRejections?.map(({ file, errors }) => errors?.map((error) => (
            <React.Fragment key={error?.code}>
              {error?.code !== 'too-many-files' ? (
                <p>
                  {file?.path}
                  {' - '}
                  {error?.message}
                </p>
              ) : ''}
            </React.Fragment>
          ))) }
        </div>
      ) : ''}
    </>
  );
};

const DropZoneFileUploader = ({
  name: propName,
  uploadBoundary,
  onChangeHandler,
  isMultiple = false,
  maxFiles,
  accept,
  hideErrorMessage,
  disabled,
  enableSetErrorMessageInHookForm,
  dragActiveBoundary,
}) => {
  const { control, setError, clearErrors } = useFormContext();
  return (
    <Controller
      control={control}
      name={propName}
      render={({ field: { onChange } }) => (
        <DropZone
          onChange={onChange}
          propName={propName}
          uploadBoundary={uploadBoundary}
          onChangeHandler={onChangeHandler}
          hideErrorMessage={hideErrorMessage}
          isMultiple={isMultiple}
          maxFiles={maxFiles}
          disabled={disabled}
          accept={accept}
          clearErrors={clearErrors}
          setError={setError}
          enableSetErrorMessageInHookForm={enableSetErrorMessageInHookForm}
          dragActiveBoundary={dragActiveBoundary}
        />
      )}
    />
  );
};
export default DropZoneFileUploader;
