import React from 'react';

import Checkbox from '../form-fields/checkbox';
import Dropdown from '../form-fields/dropdown';
import RadioButton from '../form-fields/radio-button';
import TextField from '../form-fields/text-field';

const AnswerField = ({ name, question }) => {
  const getInput = () => {
    if (question.type === 'text') {
      return <TextField name={name} question={question} />;
    }
    if (question.type === 'radio') {
      return <RadioButton name={name} question={question} />;
    }
    if (question.type === 'checkbox') {
      return <Checkbox name={name} question={question} />;
    }
    if (question.type === 'dropdown') {
      return <Dropdown name={name} question={question} />;
    }

    // eslint-disable-next-line no-console
    console.error('Unhandled answer type', question);
    return null;
  };

  return (getInput());
};

export default AnswerField;
