export const setLocalStorage = (key, value, append) => {
  let stringValue = value;
  if (typeof value === 'object') {
    stringValue = JSON.stringify(value);
    if (append) {
      stringValue = JSON.stringify({
        ...getLocalStorage(key),
        ...value,
      });
    }
  }

  localStorage.setItem(key, stringValue);
};

export const getLocalStorage = (key) => {
  const storageItem = localStorage.getItem(key);
  try {
    return JSON.parse(storageItem);
  } catch (err) {
    return storageItem;
  }
};

export const scrollToError = (formErrors, reverse) => {
  const elements = (Object.keys(formErrors)
  || []).map((name) => document
    .getElementsByName(name)[reverse ? document.getElementsByName(name).length - 1 : 0]);
  const validElements = (elements || []).filter((el) => !!el);
  validElements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const isEmptyValue = (value) => value === undefined
          || value === null
          || value === ''
          || (typeof value === 'object' && Object.keys(value).length === 0)
          || (typeof value === 'string' && value.trim().length === 0);

export const generateDropdownOptions = (
  data,
  valueParam,
  labelParam,
) => (data || []).map((item) => ({
  ...item,
  value: item[valueParam],
  label: item[labelParam],
}));

export const generateDropdownOptionsFromHash = (data) => Object.keys(data || {}).map((key) => ({
  value: key,
  label: data[key],
}));

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return (array || []).reduce((obj, item) => ({
    ...obj,
    [item[key]]: item,
  }), initialValue);
};
