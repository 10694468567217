import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Button,
  Form,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { InofIconBlack } from '../../../../assets/images/svg-icons';
import InputField from '../../../shared/form-fields/input-field';
import RadioArray from '../../../shared/form-fields/radio-array';

const ServiceConfirmationLicenceModal = ({
  hideModal,
  lastSelectedService,
  onConfirmHandler,
}) => {
  const isGeneralContractor = lastSelectedService?.slug === 'general-contractor';

  const schema = Yup.object().shape({
    ...(!isGeneralContractor
      ? { checkbox: Yup.boolean().oneOf([true], 'This field is required').nullable() } : {}),
    isAbleToPullPermit: Yup.string().required('Please choose an option'),
  });

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      checkbox: false,
      isAbleToPullPermit: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    onConfirmHandler(formData);
  };

  const {
    formState: { errors },
  } = methods;

  const yesOrNoOptions = [
    { id: 1, label: 'Yes' },
    { id: 0, label: 'No' },
  ];

  return (
    <Modal isOpen className="signup-modal" centered>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => hideModal(lastSelectedService?.service_category_id)}
          >
            Select Service Info
          </ModalHeader>
          <ModalBody>
            <h5 className="mb-2">{lastSelectedService?.label}</h5>
            <p>
              To be approved as a
              {' '}
              {lastSelectedService?.label || ''}
              {' '}
              Lula pro you will need to meet state and
              local licensing requirements.
            </p>
            {!isGeneralContractor ? (
              <div className="hightlight-box mb-30">
                <InofIconBlack />
                <div>
                  <p>
                    Only select this if you are a licensed
                    {' '}
                    {lastSelectedService?.label || ''}
                    {' '}
                    pro with at least 2 years of on the job experience! If you are a handyman,
                    then please do not select this service and choose &quot;Handyman&quot; only.
                  </p>
                  <div className="form-box-dark">
                    <div className="form-check-box">
                      <InputField
                        hideFormError
                        name="checkbox"
                        type="checkbox"
                        id="licence"
                        maxLength="50"
                      />
                    </div>
                    <Label htmlFor="licence" className="label">
                      I am a licensed
                      <span>
                        {' '}
                        {lastSelectedService?.licensed_question_label || ''}
                      </span>
                      .
                    </Label>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="checkbox"
                    render={({ message }) => (
                      <small className="text-danger">{message}</small>
                    )}
                  />
                </div>
              </div>
            ) : ''}
            <div className="hightlight-box">
              <InofIconBlack />
              <div className="question-wrapper">
                <p>
                  {isGeneralContractor
                    ? 'Are you able to pull the required permits necessary for renovations?'
                    : 'Are you able to pull a permit if required for jobs that require them?'}
                </p>
                <Row>
                  <RadioArray
                    type="radio"
                    name="isAbleToPullPermit"
                    options={yesOrNoOptions}
                    colProps={{ xs: 'auto', md: '2' }}
                    formGroupClass="mb-2"
                  />
                </Row>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="modal-btn"
              outline
              onClick={() => hideModal(lastSelectedService?.service_category_id)}
            >
              Discard
            </Button>
            {' '}
            <Button type="submit" color="primary" className="modal-btn">
              Confirm
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ServiceConfirmationLicenceModal;
