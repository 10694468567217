import React, { useEffect, useState, useCallback } from 'react';
import {
  Container, Row, Col, Button, Form,
} from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  forEach, isArray, isEmpty, isObject,
} from 'lodash';
import { ErrorMessage } from '@hookform/error-message';

import CheckboxArray from '../../shared/form-fields/checkbox-array';
import useServiceQuestions from './hooks/use-service-questions';
import ServiceQuestions from './service-questions';
import ServiceConfirmationLicenceModal from './components/service-licence-confirmation-modal';
import {
  convertArrayToObject,
  isEmptyValue,
  scrollToError,
} from '../../../helpers/utils';
import {
  getServiceOffered,
  saveServiceOffered,
} from '../../../services/pro-signup-services';
import catchHandler from '../../../helpers/catch-handler';
import { fetchProfile } from '../profile';
import { getStepCount } from '../../wizard/progress';
import { useProfile } from '../../../context/profile-context/profile-context';
import ServiceFreeQuoteConfirmationModal from './components/service-free-quote-confirmation-modal';

const ServicesOffered = () => {
  const [showConfirmLicenceModal, setShowConfirmLicenceModal] = useState(false);
  const [lastSelectedService, setLastSelectedService] = useState([]);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [savingDetails, setSavingDetails] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);
  const [showConfirmFreeQuote, setShowConfirmFreeQuoteModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { setProfileDetails } = useProfile();

  const LimitToSelectService = 3;

  const [questionsSchema] = useServiceQuestions(selectedServices);

  const schema = Yup.object().shape({
    service_categories: Yup.array().min(1, 'Atleast one should be checked'),
    questions: questionsSchema,
  });

  const buildFormData = useCallback(() => {
    if (!isEmpty(dataSource)) {
      const defaultServiceCategories = dataSource?.selected_service_categories?.map((item) => ({
        ...item,
        id: item?.service_category_id,
      }));
      const questionAnswersArray = convertArrayToObject(
        dataSource?.provider_question_answers,
        'service_question_id',
      );
      const questions = {};
      dataSource?.selected_service_categories?.forEach((service) => {
        service?.service_questions?.forEach((question) => {
          const questionAnswerObj = questionAnswersArray[question?.service_question_id];
          if (!isEmpty(questionAnswerObj)) {
            if (question.type === 'checkbox') {
              questions[`question_${question?.service_question_id}`] = !!questionAnswerObj?.answer;
            }
            if (question.type === 'text') {
              questions[`question_${question?.service_question_id}`] = questionAnswerObj?.answer;
            }
          }
        });
      });

      const permitQuestionAnswersArray = convertArrayToObject(
        dataSource?.provider_service_permit_question_answers,
        'service_question_id',
      );

      const permitQuestion = {};
      dataSource?.selected_service_categories?.forEach((service) => {
        service?.service_permit_questions?.forEach((question) => {
          const questionAnswerObj = permitQuestionAnswersArray[question?.service_question_id];
          if (!isEmpty(questionAnswerObj)) {
            if (question?.question_type === 'service-permit') {
              permitQuestion[`service_${service?.service_category_id}`] = questionAnswerObj;
            }
          }
        });
      });

      const freeQuoteServices = {};
      dataSource?.free_quote_provided_services?.forEach((item) => {
        freeQuoteServices[`service_${item?.service_category_id}`] = item?.has_provide_free_quote;
      });

      const defaultValues = {
        service_categories: defaultServiceCategories,
        questions,
        free_quote_provided_services: freeQuoteServices,
        pull_permit_provided_services: permitQuestion,
      };
      return defaultValues || {};
    }
    return {};
  }, [dataSource]);

  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(),
    resolver: yupResolver(schema),
    context: { selectedServices },
  });
  const {
    watch,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = methods;

  useEffect(() => {
    setFetchingDetails(true);
    getServiceOffered()
      .then((res) => {
        setFetchingDetails(false);
        setDataSource(res?.data);
      })
      .catch((err) => {
        setFetchingDetails(false);
        catchHandler(err, setError);
      });
  }, [setError]);

  useEffect(() => {
    if (!isEmpty(dataSource)) {
      reset(buildFormData(dataSource));
    }
  }, [dataSource, reset, buildFormData]);

  const onSubmit = (formData) => {
    setSavingDetails(true);
    const { questions, ...postData } = formData;
    const serviceQuestions = [];
    forEach(questions || {}, (answer, question) => {
      if (isEmptyValue(answer)) {
        return;
      }
      let realValue;
      if (isObject(answer) && answer.value) {
        // Dropdown
        realValue = answer.value;
      } else if (isArray(answer)) {
        // Checkbox
        realValue = answer;
      } else if (typeof answer === 'boolean') {
        realValue = answer ? '1' : '0';
      } else {
        realValue = answer;
      }
      let result;
      let questionId;
      if ((result = /question_(\d+)/.exec(question)) !== null) {
        [, questionId] = result;
        serviceQuestions.push({
          service_question_id: questionId,
          answer: realValue,
        });
      }
    });

    const freeQuoteServices = Object.keys(
      formData?.free_quote_provided_services,
    )?.map((item) => {
      const data = formData?.free_quote_provided_services[item];
      const serviceId = item?.split('_')?.[1];
      return {
        service_category_id: serviceId,
        has_provide_free_quote: data,
      };
    });
    const payload = {
      free_quote_provided_services: freeQuoteServices,
      service_categories: postData?.service_categories?.map((service) => {
        const permitQuestionAns = formData?.pull_permit_provided_services?.[`service_${service?.service_category_id}`];
        let dependedField;
        let licenseQtnField;
        service?.service_questions?.forEach((question) => {
          const dependedQtn = service?.service_questions?.filter(
            (item) => item?.slug === `${question?.slug}-later`,
          );
          if (!isEmpty(dependedQtn)) {
            dependedField = { ...dependedQtn[0] };
            licenseQtnField = question;
          }
        });

        const dependedFieldQtnAns = serviceQuestions?.filter(
          (obj) => Number(obj?.service_question_id)
            === dependedField?.service_question_id,
        );
        const licenseFieldQtnAns = serviceQuestions?.filter(
          (obj) => Number(obj?.service_question_id)
            === licenseQtnField?.service_question_id,
        );
        if (
          !isEmpty(dependedFieldQtnAns)
          && Number(dependedFieldQtnAns[0]?.answer) === 1
        ) {
          return {
            service_category_id: service?.service_category_id,
            ...(!isEmpty(dependedFieldQtnAns)
              ? { service_question_answers: dependedFieldQtnAns }
              : {}),
            ...(!isEmpty(permitQuestionAns)
              ? { service_permit_question_answers: [permitQuestionAns] }
              : {}),
          };
        }
        if (!isEmpty(licenseFieldQtnAns) && licenseFieldQtnAns[0]?.answer) {
          return {
            service_category_id: service?.service_category_id,
            ...(!isEmpty(licenseFieldQtnAns)
              ? { service_question_answers: licenseFieldQtnAns }
              : {}),
            ...(!isEmpty(permitQuestionAns)
              ? { service_permit_question_answers: [permitQuestionAns] } : {}),
          };
        }
        return {
          service_category_id: service?.service_category_id,
          ...(!isEmpty(permitQuestionAns)
            ? { service_permit_question_answers: [permitQuestionAns] } : {}),
        };
      }),
    };
    saveServiceOffered(payload)
      .then((res) => {
        fetchProfile(setSavingDetails, navigate, setProfileDetails, {
          current_step: getStepCount(location?.pathname) - 1,
        });
      })
      .catch((err) => {
        setSavingDetails(false);
        catchHandler(err, setError, true);
      });
  };

  const onError = (formErrors) => {
    const formattedErrors = {};
    Object.keys(formErrors || {})?.forEach((item) => {
      if (item === 'questions') {
        Object.keys(formErrors?.[item])?.forEach((errorKey) => {
          formattedErrors[`${item}.${errorKey}`] = formErrors?.[item]?.[errorKey];
        });
      } else {
        formattedErrors[item] = formErrors?.[item];
      }
    });
    scrollToError(formattedErrors, true);
  };

  const {
    service_categories: serviceCategories,
    free_quote_provided_services: freeQuoteProvidedServices,
    pull_permit_provided_services: pullPermitProvidedServices,
  } = watch();

  useEffect(() => {
    setSelectedServices(serviceCategories);
  }, [serviceCategories]);

  const checkHandler = (service) => {
    setLastSelectedService(service);
    if (!isEmpty(service?.service_questions)) {
      setShowConfirmLicenceModal(true);
    } else if (service?.slug === 'general-contractor') {
      setShowConfirmLicenceModal(true);
    } else {
      setShowConfirmFreeQuoteModal(true);
    }
  };

  const unCheckHandler = (serviceId) => {
    const newServices = serviceCategories?.filter(
      (item) => item?.service_category_id !== serviceId,
    );
    if (freeQuoteProvidedServices?.[`service_${serviceId}`]) {
      const freeQuoteServices = { ...freeQuoteProvidedServices };
      delete freeQuoteServices?.[`service_${serviceId}`];
      setValue('free_quote_provided_services', freeQuoteServices);
    }
    if (!isEmpty(pullPermitProvidedServices?.[`service_${serviceId}`])) {
      const pullPermitServices = { ...pullPermitProvidedServices };
      delete pullPermitServices?.[`service_${serviceId}`];
      setValue('pull_permit_provided_services', pullPermitServices);
    }
    setValue('service_categories', newServices);
  };

  const isDisabledOption = (id) => {
    const isSelected = serviceCategories?.some(
      (existingValue) => existingValue?.id?.toString() === id?.toString(),
    );
    if (!isSelected) {
      return serviceCategories?.length >= LimitToSelectService;
    }
    return false;
  };

  return fetchingDetails ? (
    <div className="page-loader" />
  ) : (
    <Container>
      <Row>
        <Col className="my-4">
          <Row>
            <Col xs="12" md="8">
              <h3>What Services Do You Provide?</h3>
              <div className="header-divider" />
              <p className="mt-3">
                Please select the
                {' '}
                {LimitToSelectService}
                {' '}
                services you are strongest at. After you
                successfully complete 8 jobs on our platform you will have the
                ability to request additional services added to your profile.
              </p>
            </Col>
            <Col xs="12" md="4">
              <div className="service-summary">
                <div className="service-summary--item">
                  {serviceCategories?.length ?? 0}
                  /
                  {LimitToSelectService}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <Row>
            <Col>
              <h4 className="h4-black">Most Frequently Requested Services</h4>
            </Col>
          </Row>
          <div className="service-city">
            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
              {(dataSource?.frequently_requested_services || []).map(
                (category) => (
                  <Col key={category?.service_category_id}>
                    <div
                      className={`city-item  ${
                        isDisabledOption(category?.service_category_id)
                          ? 'disabled'
                          : ''
                      }`}
                    >
                      <CheckboxArray
                        name="service_categories"
                        type="checkbox"
                        id={category?.service_category_id}
                        currentValue={{
                          ...category,
                          id: category?.service_category_id,
                        }}
                        checkHandler={checkHandler}
                        unCheckHandler={unCheckHandler}
                        className="custom-check"
                        disabled={isDisabledOption(
                          category?.service_category_id,
                        )}
                      />
                      <div className="city-item--inner">
                        <div className="check-box" />
                        <div className="city-name">
                          <div>{category?.label}</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ),
              )}
            </Row>
          </div>
          <Row>
            <Col>
              <h4 className="h4-black">All Other Services</h4>
            </Col>
          </Row>
          <div className="service-city">
            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
              {(dataSource?.service_categories || []).map((category) => (
                <Col key={category?.service_category_id}>
                  <div
                    className={`city-item ${
                      isDisabledOption(category?.service_category_id)
                        ? 'disabled'
                        : ''
                    }`}
                  >
                    <CheckboxArray
                      name="service_categories"
                      type="checkbox"
                      id={category?.service_category_id}
                      currentValue={{
                        ...category,
                        id: category?.service_category_id,
                      }}
                      checkHandler={checkHandler}
                      unCheckHandler={unCheckHandler}
                      disabled={isDisabledOption(category?.service_category_id)}
                      className="custom-check"
                    />
                    <div className="city-item--inner">
                      <div className="check-box" />
                      <div className="city-name">
                        <div>{category?.label}</div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <ErrorMessage
              errors={errors}
              name="service_categories"
              render={({ message }) => (
                <small name="states-error" className="text-danger">
                  {message}
                </small>
              )}
            />
          </div>

          {!isEmpty(
            serviceCategories?.filter(
              (item) => !isEmpty(item?.service_questions),
            ),
          ) ? (
            <Row>
              <Col className="my-4">
                <Row>
                  <Col>
                    <h3>Complete Licensing Info</h3>
                    <div className="header-divider" />
                  </Col>
                </Row>
              </Col>
            </Row>
            ) : (
              ''
            )}
          {serviceCategories?.map((service) => {
            if (!isEmpty(service?.service_questions)) {
              return (
                <React.Fragment key={service?.service_category_id}>
                  <Col>
                    <h3 className="mn-subheading">{service?.label}</h3>
                  </Col>
                  <Row>
                    <Col>
                      <div className="licence-number-outer">
                        <ServiceQuestions
                          questions={service?.service_questions}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              );
            }
            return '';
          })}
          <Row>
            <Col>
              <div className="action-wrapper">
                <Button type="submit" color="primary" disabled={savingDetails}>
                  {savingDetails ? (
                    <div className="loader-dual-ring" />
                  ) : (
                    'Save & Continue'
                  )}
                </Button>
                <Button
                  type="button"
                  color="link"
                  onClick={() => navigate('/signup/cities-served')}
                >
                  Previous Step
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
      {showConfirmLicenceModal ? (
        <ServiceConfirmationLicenceModal
          hideModal={(serviceId) => {
            unCheckHandler(serviceId);
            setShowConfirmLicenceModal(false);
          }}
          lastSelectedService={lastSelectedService}
          onConfirmHandler={(data) => {
            const serviceId = lastSelectedService?.service_category_id;
            const pullPermitDetails = { ...pullPermitProvidedServices };
            pullPermitDetails[`service_${serviceId}`] = {
              service_question_id:
                lastSelectedService?.service_permit_questions[0]
                  ?.service_question_id,
              answer: data?.isAbleToPullPermit,
            };
            setValue('pull_permit_provided_services', pullPermitDetails);
            setShowConfirmLicenceModal(false);
          }}
        />
      ) : (
        ''
      )}
      {showConfirmFreeQuote ? (
        <ServiceFreeQuoteConfirmationModal
          hideModal={(serviceId) => {
            unCheckHandler(serviceId);
            setShowConfirmFreeQuoteModal(false);
          }}
          lastSelectedService={lastSelectedService}
          onConfirmHandler={(data) => {
            const serviceId = lastSelectedService?.service_category_id;
            const freeQuoteServices = { ...freeQuoteProvidedServices };
            freeQuoteServices[`service_${serviceId}`] = Number(data?.freeQuote) === 1 ? 'yes' : 'no';
            setValue('free_quote_provided_services', freeQuoteServices);
            setShowConfirmFreeQuoteModal(false);
          }}
        />
      ) : (
        ''
      )}
    </Container>
  );
};

export default ServicesOffered;
