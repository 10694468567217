import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Form,
} from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { isEmpty, parseInt, size } from 'lodash';

import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { BrowseSmallIcon, InfoIcon } from '../../assets/images/svg-icons';
import SelectField from '../shared/form-fields/select-field';
import InputField from '../shared/form-fields/input-field';
import {
  convertArrayToObject,
  generateDropdownOptions,
  generateDropdownOptionsFromHash,
  scrollToError,
} from '../../helpers/utils';
import RadioArray from '../shared/form-fields/radio-array';
import {
  deleteGeneralInsuranceDocuments,
  getCompanyInfo,
  saveCompanyInfo,
  uploadGeneralInsuranceDocuments,
} from '../../services/pro-signup-services';
import catchHandler from '../../helpers/catch-handler';
// import AsyncSelectField from '../shared/form-fields/custom-async-select';
import CustomNumberField from '../shared/form-fields/custom-number-field';
import ReactDatePicker from '../shared/form-fields/react-date-picker';
import { getStepCount } from '../wizard/progress';
import { useProfile } from '../../context/profile-context/profile-context';
import { fetchProfile } from './profile';
import DropZoneFileUploader from '../shared/form-fields/custom-file-uploader';

const YourInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profileDetails } = useProfile();

  const [yourInfo, setYourInfo] = useState({});
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [savingDetails, setSavingDetails] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});
  const [isDocumentsUploading, setIsDocumentsUploading] = useState(false);

  const { setProfileDetails } = useProfile();

  const dateOfBirthTooltipContent = () => (
    <p>
      We need to verify your age,
      {' '}
      <br />
      {' '}
      and besides we like to give
      {' '}
      <br />
      {' '}
      birthday gifts!
    </p>
  );

  const externalReviewUrlTooltipContent = () => (
    <p>
      We use this as part of our vetting process to ensure
      {' '}
      <br />
      approval of quality providers that have a proven track
      {' '}
      <br />
      record of delivering professional service and
      {' '}
      <br />
      {' '}
      a great customer
      experience.
    </p>
  );

  const schema = yup.object().shape({
    date_of_birth: yup.date().nullable().required('This is required field'),
    // provider_phone_type_id: yup
    //   .string()
    //   .required('Please choose the type of phone you have'),
    provider_heard_about_id: yup
      .string()
      .required('Please choose how you heard about us'),
    provider_type_id: yup.string().required('Please choose an option'),
    company_name: yup.string().when('provider_type_id', {
      is: (val) => parseInt(val) === 10,
      then: yup
        .string()
        .matches(
          /^(?!\s+$).*/,
          'This field cannot have only blank spaces in it. ',
        )
        .required('Please enter your company name'),
    }),
    street_address: yup
      .string()
      .matches(
        /^(?!\s+$).*/,
        'This field cannot have only blank spaces in it. ',
      )
      .required('Please enter your street address'),
    city: yup
      .string()
      .matches(
        /^(?!\s+$).*/,
        'This field cannot have only blank spaces in it. ',
      )
      .required('Please enter your city'),
    state_id: yup.object().nullable().required('Please select your state'),
    zip: yup.string().required('Please enter your zip'),
    year_in_business: yup
      .object()
      .nullable()
      .required('Please select your experience'),
    who_do_you_serve: yup.string().required('Please choose an option'),
    is_insured: yup.string().required('Please choose an option'),
    insurance_agency_name: yup
      .string()
      .when(['is_insured', 'is_insurance_info_unknown'], {
        is: (val, isInsuranceInfoUnknown) => parseInt(val) === 1
          && parseInt(isInsuranceInfoUnknown) !== 1,
        then: yup
          .string()
          .matches(
            /^(?!\s+$).*/,
            'This field cannot have only blank spaces in it. ',
          )
          .required('Please enter your insurance agency name.'),
      }),
    insurance_agent_name: yup
      .string()
      .when(['is_insured', 'is_insurance_info_unknown'], {
        is: (val, isInsuranceInfoUnknown) => parseInt(val) === 1
          && parseInt(isInsuranceInfoUnknown) !== 1,
        then: yup
          .string()
          .matches(
            /^(?!\s+$).*/,
            'This field cannot have only blank spaces in it. ',
          )
          .required('Please enter your insurance agent name.'),
      }),
    // is_subsidiary_or_franchise: yup.string().when(['provider_type_id'], {
    //   is: (val) => parseInt(val) === 10,
    //   then: yup.string().required('Please choose an option'),
    // }),
    // subsidiary_or_franchise_name: yup
    //   .string()
    //   .when(['is_subsidiary_or_franchise', 'provider_type_id'], {
    //     is: (val, providerTypeId) => parseInt(val) === 1 && parseInt(providerTypeId) === 10,
    //     then: yup
    //       .string()
    //       .matches(
    //         /^(?!\s+$).*/,
    //         'This field cannot have only blank spaces in it. ',
    //       )
    //       .required('This field is required.'),
    //   }),
    number_of_employees: yup.string().when('provider_type_id', {
      is: (val) => parseInt(val) === 10,
      then: yup.string().required('Please choose an option'),
    }),
    // is_employees_work_on_commision: yup
    //   .string()
    //   .when(['number_of_employees', 'provider_type_id'], {
    //     is: (val, providerTypeId) => (parseInt(val) === 2 || parseInt(val) === 3)
    //       && parseInt(providerTypeId) === 10,
    //     then: yup.string().required('Please choose an option'),
    //   }),
    employees_count: yup
      .string()
      .when(['number_of_employees', 'provider_type_id'], {
        is: (val, providerTypeId) => parseInt(val) === 3 && parseInt(providerTypeId) === 10,
        then: yup
          .string()
          .test(
            'isAboveFive',
            '# of employees should not be less than 5',
            (val) => Number(val) >= 5,
          )
          .required('Please enter the number of employees.'),
      }),
    has_fleet: yup.string().when(['number_of_employees', 'provider_type_id'], {
      is: (val, providerTypeId) => (parseInt(val) === 2 || parseInt(val) === 3)
        && parseInt(providerTypeId) === 10,
      then: yup.string().required('Please choose an option'),
    }),
    // is_coordinator_work_in_field: yup
    //   .string()
    //   .when(['has_fleet', 'provider_type_id'], {
    //     is: (val, providerTypeId) => parseInt(val) === 1 && parseInt(providerTypeId) === 10,
    //     then: yup.string().required('Please choose an option'),
    //   }),
    good_candidate_reason: yup
      .string()
      .matches(
        /^(?!\s+$).*/,
        'This field cannot have only blank spaces in it. ',
      )
      .required('Please enter a reason'),
    // website_url: yup.string().url('Enter a valid url'),
    field_management_partner_id: yup
      .string()
      .required('Please choose an option'),
    fleet_management_software_name: yup
      .string()
      .when('field_management_partner_id', {
        is: (val) => val === 'other',
        then: yup.string().required('This is a required field'),
      }),
    has_worked_for_pm_company: yup.string().required('Please choose an option'),
    working_time_priority: yup.string().required('Please choose an option'),
    // referred_by_id: yup
    //   .object()
    //   .nullable().when('provider_heard_about_id', {
    //     is: (val) => Number(val) === 50 || Number(val) === 110,
    //     then: yup
    //       .object()
    //       .nullable().required('This is a required field'),
    //   }),
    who_referred_you: yup
      .string()
      .nullable()
      .when('provider_heard_about_id', {
        is: (val) => Number(val) === 50 || Number(val) === 110,
        then: yup.string().required('This is a required field'),
      }),
    has_capable_to_purchase_material: yup
      .string()
      .required('Please choose an option'),
    is_outsource_to_1099_subcontractors: yup.string().nullable().required('This is a required field'),
    is_acknowledged_not_outsource_lula_jobs: yup.string().nullable().when('is_outsource_to_1099_subcontractors', {
      is: (value) => Number(value) === 1,
      then: yup.string().nullable().test('isChecked', 'This is a required field', (value) => Number(value) === 1),
    }),
  });

  const getFieldManagementPartnerDefaultValue = useCallback(
    (data) => {
      // if user not completed second step then
      // assign field_management_softwares's default value as empty
      // there for no option will selected for first time form submission
      if (profileDetails?.completed_step > 0) {
        if (data?.field_management_software) {
          return data?.field_management_software;
        }
        return 'none';
      }
      return '';
    },
    [profileDetails?.completed_step],
  );

  const getYesOrNoOptionDefaultValue = (data) => {
    if (data === 'yes') {
      return 1;
    }
    if (data === 'no') {
      return 0;
    }
    return '';
  };

  const buildDefaultValues = useCallback(() => {
    const businessYearsOptions = convertArrayToObject(
      yourInfo?.business_years,
      'id',
    );
    const websiteUrl = yourInfo?.account_details?.website_url ?? '';
    const formData = {
      date_of_birth: yourInfo?.account_details?.date_of_birth
        ? new Date(yourInfo?.account_details?.date_of_birth)
        : null,
      company_name: yourInfo?.account_details?.company_name ?? '',
      street_address: yourInfo?.account_details?.street_address ?? '',
      city: yourInfo?.account_details?.city ?? '',
      zip: yourInfo?.account_details?.zip ?? '',
      suite: yourInfo?.account_details?.suite ?? '',
      website_url: websiteUrl ?? '',
      is_insured: yourInfo?.account_details?.is_insured ?? '',
      ...(!isEmpty(yourInfo?.account_details?.external_review_urls)
        ? {
          external_review_urls:
              yourInfo?.account_details?.external_review_urls.map((url) => ({
                url,
              })),
        }
        : { external_review_urls: [{ url: '' }] }),
      good_candidate_reason:
        yourInfo?.account_details?.good_candidate_reason || '',
      // provider_phone_type_id:
      //   yourInfo?.account_details?.provider_phone_type_id ?? '',
      provider_heard_about_id:
        yourInfo?.account_details?.provider_heard_about_id ?? '',
      provider_type_id: yourInfo?.account_details?.provider_type_id
        ? yourInfo?.account_details?.provider_type_id
        : '',
      who_do_you_serve: yourInfo?.account_details?.who_do_you_serve
        ? yourInfo?.account_details?.who_do_you_serve
        : '',
      state_id: yourInfo?.account_details?.state_id
        ? {
          label: yourInfo?.states[yourInfo?.account_details?.state_id],
          value: yourInfo?.account_details?.state_id,
        }
        : null,
      year_in_business:
        yourInfo?.account_details?.year_in_business !== null
          ? {
            label:
                businessYearsOptions?.[
                  yourInfo?.account_details?.year_in_business
                ]?.label,
            value:
                businessYearsOptions?.[
                  yourInfo?.account_details?.year_in_business
                ]?.id,
          }
          : null,
      // is_subsidiary_or_franchise:
      //   yourInfo?.account_details?.is_subsidiary_or_franchise ?? '',
      // subsidiary_or_franchise_name:
      //   yourInfo?.account_details?.subsidiary_or_franchise_name ?? '',
      is_insurance_info_unknown:
        yourInfo?.account_details?.is_insurance_info_unknown ?? '0',
      insurance_agency_name:
        yourInfo?.account_details?.insurance_agency_name ?? '',
      number_of_employees: yourInfo?.account_details?.number_of_employees ?? '',
      employees_count: yourInfo?.account_details?.employees_count ?? '',
      // is_employees_work_on_commision:
      //   yourInfo?.account_details?.is_employees_work_on_commision ?? '',
      has_fleet: yourInfo?.account_details?.has_fleet ?? '',
      // is_coordinator_work_in_field:
      //   yourInfo?.account_details?.is_coordinator_work_in_field ?? '',
      insurance_agent_name:
        yourInfo?.account_details?.insurance_agent_name ?? '',
      field_management_partner_id: getFieldManagementPartnerDefaultValue(
        yourInfo?.account_details,
      ),
      has_worked_for_pm_company: getYesOrNoOptionDefaultValue(
        yourInfo?.account_details?.has_worked_for_pm_company,
      ),
      // referred_by_id: yourInfo?.account_details?.referred_by_id
      //   ? {
      //     value: yourInfo?.account_details?.referred_by_id,
      //     label: yourInfo?.account_details?.referred_by,
      //   } : '',
      who_referred_you: yourInfo?.account_details?.who_referred_you ?? '',
      working_time_priority:
        yourInfo?.account_details?.working_time_priority ?? '',
      has_capable_to_purchase_material: getYesOrNoOptionDefaultValue(
        yourInfo?.account_details?.has_capable_to_purchase_material,
      ),
      fleet_management_software_name:
        yourInfo?.account_details?.fleet_management_software_name ?? '',
      is_outsource_to_1099_subcontractors: getYesOrNoOptionDefaultValue(
        yourInfo?.account_details?.is_outsource_to_1099_subcontractors,
      ),
      is_acknowledged_not_outsource_lula_jobs:
      yourInfo?.account_details?.is_acknowledged_not_outsource_lula_jobs ?? 0,
    };

    return formData;
  }, [
    getFieldManagementPartnerDefaultValue,
    yourInfo?.account_details,
    yourInfo?.business_years,
    yourInfo?.states,
  ]);

  const yesOrNoOptions = [
    { id: 1, label: 'Yes' },
    { id: 0, label: 'No' },
  ];

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: buildDefaultValues(),
  });

  const {
    control,
    reset,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = methods;

  const {
    is_insured: hasLiabilityInsurance,
    provider_type_id: providerTypeId,
    is_insurance_info_unknown: isInsuranceInfoUnknown,
    number_of_employees: numberOfEmployees,
    provider_heard_about_id: providerHeardAboutId,
    field_management_partner_id: fieldManagementPartnerId,
    external_review_urls: externalReviewUrls,
    // is_employees_work_on_commision: isWorkOnCommision,
    // is_subsidiary_or_franchise: isSubsidiaryOrFranchise,
    // is_coordinator_work_in_field: isCoordinatorWorkInField,
    is_outsource_to_1099_subcontractors: isOutsourceTo1099Subcontractors,
    is_acknowledged_not_outsource_lula_jobs: isAcknowledgedNotOutsourceLulaJobs,
  } = watch();

  useEffect(() => {
    setFetchingDetails(true);
    getCompanyInfo()
      .then((res) => {
        setFetchingDetails(false);
        if (!isEmpty(res?.data)) {
          setYourInfo(res?.data);
        }
        if (!isEmpty(res?.data?.account_details?.gl_insurance_documents)) {
          setUploadedFile(res?.data?.account_details?.gl_insurance_documents?.[0]);
        }
      })
      .catch((error) => {
        setFetchingDetails(false);
        catchHandler(error, setError, true);
      });
  }, [setError]);

  const onSubmit = (formData) => {
    const payload = {
      date_of_birth: formData?.date_of_birth
        ? moment(formData?.date_of_birth).format('DD-MM-YYYY')
        : '',
      // provider_phone_type_id: formData?.provider_phone_type_id,
      provider_heard_about_id: formData?.provider_heard_about_id,
      provider_type_id: formData?.provider_type_id,
      ...(isProviderTypeCompany()
        ? { company_name: formData?.company_name }
        : {}),
      street_address: formData?.street_address,
      suite: formData?.suite,
      city: formData?.city,
      state_id: formData?.state_id?.value,
      zip: formData?.zip,
      year_in_business: formData?.year_in_business?.value,
      website_url: formData?.website_url ?? '',
      who_do_you_serve: formData?.who_do_you_serve,
      external_review_urls: formData?.external_review_urls?.map(
        (url) => url?.url,
      ),
      is_insured: formData?.is_insured,
      good_candidate_reason: formData?.good_candidate_reason,
      ...(isProviderTypeCompany()
        ? {
          // is_subsidiary_or_franchise: formData?.is_subsidiary_or_franchise,
          number_of_employees: formData?.number_of_employees,
        }
        : {}),
      // ...(isProviderTypeCompany()
      // && parseInt(formData?.is_subsidiary_or_franchise)
      //   ? {
      //     subsidiary_or_franchise_name:
      //         formData?.subsidiary_or_franchise_name ?? null,
      //   }
      //   : {}),
      ...(parseInt(formData?.is_insured)
      && parseInt(formData?.is_insurance_info_unknown) === 0
        ? {
          insurance_agency_name: formData?.insurance_agency_name,
          insurance_agent_name: formData?.insurance_agent_name,
        }
        : {}),
      ...(parseInt(formData?.is_insured)
        ? { is_insurance_info_unknown: formData?.is_insurance_info_unknown }
        : {}),
      ...(isProviderTypeCompany()
      && parseInt(formData?.number_of_employees) === 3
        ? { employees_count: parseInt(formData?.employees_count) }
        : {}),
      ...(isProviderTypeCompany()
      && (parseInt(formData?.number_of_employees) === 2
        || parseInt(formData?.number_of_employees) === 3)
        ? {
          // is_employees_work_on_commision:
          //     formData?.is_employees_work_on_commision,
          has_fleet: formData?.has_fleet,
        }
        : {}),
      // ...(isProviderTypeCompany() && parseInt(formData?.has_fleet)
      //   ? {
      //     is_coordinator_work_in_field:
      //         formData?.is_coordinator_work_in_field,
      //   }
      //   : {}),
      ...(formData?.field_management_partner_id !== 'none'
        ? {
          field_management_software: formData?.field_management_partner_id,
        }
        : { field_management_software: null }),
      ...(formData?.field_management_partner_id === 'other'
        ? {
          fleet_management_software_name:
              formData?.fleet_management_software_name,
        }
        : {}),
      ...(!isEmpty(formData?.who_referred_you)
      && (Number(formData?.provider_heard_about_id) === 50
        || Number(formData?.provider_heard_about_id) === 110)
        ? { who_referred_you: formData?.who_referred_you }
        : {}),
      // ...(((!isEmpty(formData?.referred_by_id)
      // && (Number(formData?.provider_heard_about_id) === 50
      // || Number(formData?.provider_heard_about_id) === 110))
      //   ? { referred_by_id: formData?.referred_by_id?.value } : {}),
      working_time_priority: formData?.working_time_priority,
      has_worked_for_pm_company:
        Number(formData?.has_worked_for_pm_company) === 1 ? 'yes' : 'no',
      has_capable_to_purchase_material:
        Number(formData?.has_capable_to_purchase_material) === 1 ? 'yes' : 'no',
      is_outsource_to_1099_subcontractors: Number(formData?.is_outsource_to_1099_subcontractors) === 1 ? 'yes' : 'no',
      ...(Number(formData?.is_outsource_to_1099_subcontractors) === 1 ? {
        is_acknowledged_not_outsource_lula_jobs:
      Number(formData?.is_acknowledged_not_outsource_lula_jobs),
      } : {}),
    };
    setSavingDetails(true);
    saveCompanyInfo(payload)
      .then((res) => {
        fetchProfile(setSavingDetails, navigate, setProfileDetails, {
          current_step: getStepCount(location?.pathname) - 1,
        });
      })
      .catch((err) => {
        setSavingDetails(false);
        catchHandler(err, setError, true);
      });
  };

  useEffect(() => {
    if (!isEmpty(yourInfo)) {
      reset(buildDefaultValues());
    }
  }, [reset, yourInfo, buildDefaultValues]);

  const { fields, append, remove } = useFieldArray({
    name: 'external_review_urls',
    control,
  });

  const onError = (formErrors) => {
    scrollToError(formErrors);
  };

  const isProviderTypeCompany = () => {
    if (parseInt(providerTypeId) === 10) {
      return true;
    }
    return false;
  };

  // const fetchReferralOptions = async (
  //   searchKeyWord,
  //   callback,
  //   referralType,
  // ) => {
  //   if (!isEmpty(searchKeyWord?.trim())) {
  //     try {
  //       const params = {
  //         ...(searchKeyWord ? { search: searchKeyWord?.trim() } : {}),
  //         ...(referralType ? { referral_type: referralType } : {}),
  //       };
  //       const res = await getReferral(params);
  //       const optionsResponse = res?.data?.map((item) => ({
  //         label: referralType === 'provider' ? item?.company_name : item?.name,
  //         value: item?.provider_user_id,
  //       }));
  //       if (callback) {
  //         callback(optionsResponse);
  //       }
  //     } catch (e) {
  //       callback([]);
  //     }
  //   }
  // };
  // const getReferralType = () => {
  //   if (Number(providerHeardAboutId) === 50) {
  //     return 'provider_user';
  //   }
  //   if (Number(providerHeardAboutId) === 110) {
  //     return 'provider';
  //   }
  //   return '';
  // };

  // const getReferralOptions = (searchKeyWord, callback) => {
  //   fetchReferralOptions(searchKeyWord, callback, getReferralType());
  // };

  // const getReferralInputOptions = debounce(getReferralOptions, 500);

  const imageUploadHandler = () => {
    const filesToUpload = getValues('gl_insurance_documents');
    if (!isEmpty(filesToUpload) && isEmpty(uploadedFile)) {
      const formData = new FormData();
      formData.append('files', filesToUpload[0]);
      setIsDocumentsUploading(true);
      uploadGeneralInsuranceDocuments(formData)
        .then((res) => {
          toast.success(res?.data?.message || 'Insurance document uploaded successfully');
          setUploadedFile(res?.data?.[0]);
          setValue('gl_insurance_documents', null);
          setIsDocumentsUploading(false);
        })
        .catch((err) => {
          setIsDocumentsUploading(false);
        });
    }
  };

  const imageDeleteHandler = (imageId) => {
    deleteGeneralInsuranceDocuments({ provider_user_document_id: imageId }).then((response) => {
      setUploadedFile({});
      toast.success(response?.message || 'Deleted Successfully');
    }).catch((err) => {
      toast.error(err?.data?.message);
    });
  };

  return fetchingDetails ? (
    <div className="page-loader" />
  ) : (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <Container>
          <Row>
            <Col className="intro-section">
              <h2>
                Welcome,
                {' '}
                {profileDetails?.first_name
                  ? `${profileDetails?.first_name}!`
                  : ''}
              </h2>
              <p>Please answer all questions below.</p>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <h4>
                Are you representing a company with field staff or just
                yourself?
              </h4>
            </Col>
          </Row>
          <Row>
            <RadioArray
              type="radio"
              name="provider_type_id"
              options={(yourInfo?.provider_types ?? []).map((item) => ({
                ...item,
                id: item?.provider_type_id,
              }))}
              colProps={{ xs: 12 }}
              formGroupClass="mb-2"
            />
          </Row>
          {isProviderTypeCompany() ? (
            <Row>
              <Col xs="12" md="4" className="mt-2">
                <FormGroup className="mb-3">
                  <Label for="">Company Name</Label>
                  <InputField name="company_name" type="text" />
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {isProviderTypeCompany() ? (
            <>
              <Row>
                <Col className="mb-2">
                  <h4>How many employees does your company have?</h4>
                </Col>
              </Row>
              <Row>
                <RadioArray
                  type="radio"
                  name="number_of_employees"
                  options={(yourInfo?.employees_in_business_list ?? []).map(
                    (item) => ({
                      ...item,
                      id: item?.id,
                    }),
                  )}
                  colProps={{ xs: '12', md: '6', lg: '2' }}
                  formGroupClass="mb-2"
                />
              </Row>

              {parseInt(numberOfEmployees) === 2
              || parseInt(numberOfEmployees) === 3 ? (
                <>
                  <Row>
                    <Col className="mb-2 mt-4">
                      <h4>
                        Does your company have a fleet manager that assigns jobs
                        to your employees in the field?
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <RadioArray
                      type="radio"
                      name="has_fleet"
                      options={yesOrNoOptions}
                      colProps={{ xs: '12', md: '6', lg: '2' }}
                      formGroupClass="mb-2"
                    />
                  </Row>
                  {parseInt(numberOfEmployees) === 3 ? (
                    <Row>
                      <Col xs="12" md="4" className="mt-2">
                        <FormGroup className="mb-3 mt-3">
                          <Label for="">
                            Enter # of Employees (Include Yourself)
                          </Label>
                          <CustomNumberField
                            name="employees_count"
                            type="text"
                            className="form-control"
                            allowNegative={false}
                            decimalSeparator={false}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </>
                ) : (
                  ''
                )}
            </>
          ) : (
            ''
          )}
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4">
              <FormGroup className="mb-3">
                <Label>
                  What is your date of birth?
                  <i
                    data-tip=""
                    data-for="info16"
                    data-event="click focus"
                    className="info-icon-outer"
                  >
                    <InfoIcon />
                  </i>
                  <ReactTooltip
                    id="info16"
                    getContent={dateOfBirthTooltipContent}
                    border
                    color="#fff"
                    globalEventOff="click"
                    arrowColor="#283A5D"
                    className="tooltip info-tooltip"
                  />
                </Label>
                <ReactDatePicker
                  name="date_of_birth"
                  className="form-control date-picker"
                  popperClassName="date-picker--pop"
                  dateFormat="MMMM dd, yyyy"
                  maxDate={new Date(moment().subtract(18, 'years'))}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Select your birth date"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup className="mb-3">
                <Label>
                  {isProviderTypeCompany()
                    ? 'How many years have you been in business?'
                    : 'How many years of experience do you have?'}
                </Label>
                <SelectField
                  options={generateDropdownOptions(
                    yourInfo?.business_years,
                    'id',
                    'label',
                  )}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  name="year_in_business"
                  className="form-control-select"
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup className="mb-3">
                <Label>
                  Company Website or Facebook Business Page (optional)
                </Label>
                <InputField
                  name="website_url"
                  placeholder="https://"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <h4>Do you use fleet management software?</h4>
            </Col>
          </Row>
          <Row>
            <RadioArray
              type="radio"
              name="field_management_partner_id"
              options={
                !isEmpty(yourInfo.field_management_softwares)
                  ? [
                    { slug: 'none', label: 'None' },
                    ...(yourInfo.field_management_softwares ?? []),
                  ].map((item) => ({
                    ...item,
                    id: item?.slug,
                  }))
                  : []
              }
              colProps={{ xs: '12', md: '6', lg: '2' }}
              formGroupClass="mb-2"
            />
          </Row>
          {fieldManagementPartnerId === 'other' ? (
            <Row>
              <Col xs="12" md="4" className="mt-2">
                <FormGroup className="mb-3 mt-3">
                  <Label for="">
                    Enter your fleet management software name
                  </Label>
                  <InputField
                    type="text"
                    name="fleet_management_software_name"
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <h4>
                Have you ever done work for a property management company?
              </h4>
            </Col>
          </Row>
          <Row>
            <RadioArray
              type="radio"
              name="has_worked_for_pm_company"
              options={yesOrNoOptions}
              colProps={{ xs: '12', md: '6', lg: '2' }}
              formGroupClass="mb-2"
            />
          </Row>
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <h4>Do you service residential, commercial, or both?</h4>
            </Col>
          </Row>
          <Row>
            <RadioArray
              type="radio"
              name="who_do_you_serve"
              options={(yourInfo?.who_do_you_serves ?? []).map((item) => ({
                ...item,
                id: item?.id,
              }))}
              colProps={{ xs: '12', md: '6', lg: '2' }}
              formGroupClass="mb-2"
            />
          </Row>
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <h4>Does your company carry general liability insurance?</h4>
            </Col>
          </Row>
          <Row>
            <RadioArray
              type="radio"
              name="is_insured"
              options={yesOrNoOptions}
              colProps={{ xs: '12', md: '6', lg: '2' }}
              formGroupClass="mb-2"
            />
          </Row>
          {parseInt(hasLiabilityInsurance) === 1 ? (
            <>
              {!(parseInt(isInsuranceInfoUnknown) === 1) ? (
                <Row>
                  <Col xs="12" md="4">
                    <FormGroup className="mb-3">
                      <Label>Insurance Agency</Label>
                      <InputField
                        name="insurance_agency_name"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="4">
                    <FormGroup className="mb-3">
                      <Label for="">Insurance Agent Full Name</Label>
                      <InputField
                        name="insurance_agent_name"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              <Row>
                <Col xs="12" className="mt-2">
                  <FormGroup className="mb-3">
                    <InputField
                      name="is_insurance_info_unknown"
                      checked={parseInt(isInsuranceInfoUnknown) === 1}
                      value={!Number(isInsuranceInfoUnknown) ? 1 : 0}
                      handleOnchangeValue
                      type="checkbox"
                      id="no_general_liability"
                      className="custom-mr-5"
                    />
                    <Label htmlFor="no_general_liability">
                      Check here if you don’t know your company’s general
                      liability insurance agency information
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6" lg="4" className="mt-2">
                  {!isDocumentsUploading && size(uploadedFile) < 1 ? (
                    <DropZoneFileUploader
                      name="gl_insurance_documents"
                      className="form-control"
                      uploadBoundary={(
                        <div className="uploaded-docs--card upload-document">
                          <i>
                            <BrowseSmallIcon />
                          </i>
                          <p>
                            Drag General Liability Insurance here or
                            <br />
                            <span>Browse files</span>
                          </p>
                        </div>
                        )}
                      dragActiveBoundary={<div className="uploaded-docs--card drop-here">Drop Here</div>}
                      isMultiple={false}
                      accept={{
                        'image/jpeg': [],
                        'image/png': [],
                        'application/pdf': [],
                      }}
                      maxFiles={1}
                      hideErrorMessage
                      onChangeHandler={imageUploadHandler}
                      enableSetErrorMessageInHookForm
                    />
                  ) : ''}
                  {isDocumentsUploading ? (
                    <div className="image-upload-loader">
                      <div className="spinner-border text-primary" role="status" />
                      <span>
                        Uploading...
                      </span>
                    </div>
                  ) : ''}

                  {!isEmpty(errors?.gl_insurance_documents)
                    ? (() => {
                      let errorMessage = 'Something went wrong';
                      errors?.gl_insurance_documents?.fileRejections?.forEach((item) => {
                        item?.errors?.forEach((error) => {
                          if (error?.code === 'file-invalid-type') {
                            errorMessage = `${item?.file?.path}
                              ${' - '}
                              File type must be jpg, png, jpeg, pdf`;
                          } else if (error?.message) {
                            errorMessage = error?.message;
                          }
                        });
                      });
                      toast.error(errorMessage);
                      clearErrors('gl_insurance_documents');
                    })()
                    : ''}
                  {!isEmpty(uploadedFile)
                    ? (
                      <div
                        className="pro-uploaded--image"
                        role="presentation"
                      >
                        <button className="pro-close-btn" type="button" onClick={() => imageDeleteHandler(uploadedFile?.provider_user_document_id)}>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="14" cy="14" r="14" fill="#FF4949" />
                            <g clipPath="url(#clip0_2567_3594)">
                              <path d="M13.038 14.0086L8.94955 9.91996C8.68347 9.65401 8.68347 9.22401 8.94955 8.95807C9.21549 8.69212 9.64549 8.69212 9.91144 8.95807L14.0001 13.0467L18.0886 8.95807C18.3546 8.69212 18.7845 8.69212 19.0504 8.95807C19.3165 9.22401 19.3165 9.65401 19.0504 9.91996L14.962 14.0086L19.0504 18.0972C19.3165 18.3631 19.3165 18.7931 19.0504 19.0591C18.9179 19.1918 18.7436 19.2584 18.5695 19.2584C18.3954 19.2584 18.2212 19.1918 18.0886 19.0591L14.0001 14.9705L9.91144 19.0591C9.77878 19.1918 9.60464 19.2584 9.43049 19.2584C9.25635 19.2584 9.08221 19.1918 8.94955 19.0591C8.68347 18.7931 8.68347 18.3631 8.94955 18.0972L13.038 14.0086Z" fill="white" />
                            </g>
                            <defs>
                              <clipPath id="clip0_2567_3594">
                                <rect width="10.5" height="10.5" fill="white" transform="matrix(-1 0 0 1 19.25 8.75)" />
                              </clipPath>
                            </defs>
                          </svg>

                        </button>
                        <img
                          {...uploadedFile?.file_extension?.toLowerCase() === 'pdf' ? {
                            onClick: () => { window?.open(uploadedFile?.download_url, '_blank'); },
                          } : {}}
                          src={
                        uploadedFile?.file_extension?.toLowerCase() === 'pdf'
                          ? '/Images/pdf-img.png'
                          : uploadedFile?.url
                      }
                          alt=""
                        />
                      </div>
                    )
                    : ''}
                </Col>

              </Row>
            </>
          ) : (
            ''
          )}
          {' '}

          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <h4>
                Do you also work nights and weekends? (i.e After 5pm and
                Sat/Sun)
                {' '}
              </h4>
            </Col>
          </Row>
          <Row>
            <RadioArray
              type="radio"
              name="working_time_priority"
              options={(yourInfo?.working_time_priority ?? []).map((item) => ({
                ...item,
                id: item?.slug,
              }))}
              colProps={{
                xs: '12',
                md: '6',
                lg: '3',
                xl: '2',
              }}
              formGroupClass="mb-2"
            />
          </Row>
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <h4>
                Are you capable of purchasing materials upfront needed to
                perform the job? (You will be reimbursed in full)
              </h4>
            </Col>
          </Row>
          <Row>
            <RadioArray
              type="radio"
              name="has_capable_to_purchase_material"
              options={yesOrNoOptions}
              colProps={{
                xs: '12',
                md: '6',
                lg: '2',
              }}
              formGroupClass="mb-2"
            />
          </Row>
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <h4>Do you outsource work to 1099 subcontractors?</h4>
            </Col>
          </Row>
          <Row>
            <RadioArray
              type="radio"
              name="is_outsource_to_1099_subcontractors"
              options={yesOrNoOptions}
              colProps={{
                xs: '12',
                md: '6',
                lg: '2',
              }}
              handleAttributeFunction={() => setValue('is_acknowledged_not_outsource_lula_jobs', 0)}
              formGroupClass="mb-2"
            />
          </Row>
          {Number(isOutsourceTo1099Subcontractors) === 1 ? (
            <Row>
              <Col className="mb-2">
                <div className="acknowledge-box">
                  <p>
                    Lula
                    {' '}
                    <span>does not</span>
                    {' '}
                    allow pros to outsource or
                    subcontract (1099) jobs claimed through the platform. Please
                    acknowledge this and then you can proceed with application.
                  </p>
                  <FormGroup>
                    <InputField
                      name="is_acknowledged_not_outsource_lula_jobs"
                      checked={parseInt(isAcknowledgedNotOutsourceLulaJobs) === 1}
                      value={!Number(isAcknowledgedNotOutsourceLulaJobs) ? 1 : 0}
                      handleOnchangeValue
                      type="checkbox"
                      hideFormError
                      id="is_acknowledged_not_outsource_lula_jobs"
                      className="custom-mr-5"
                    />
                    <Label htmlFor="is_acknowledged_not_outsource_lula_jobs">
                      I acknowledge that I can’t outsource Lula jobs
                    </Label>
                  </FormGroup>
                  <ErrorMessage
                    errors={errors}
                    name="is_acknowledged_not_outsource_lula_jobs"
                    render={({ message }) => (
                      <small className="text-danger">{message}</small>
                    )}
                  />
                </div>

              </Col>
            </Row>
          ) : ''}
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <h4>How did you hear about Lula?</h4>
            </Col>
          </Row>
          <Row>
            <RadioArray
              type="radio"
              name="provider_heard_about_id"
              options={(yourInfo?.provider_heard_abouts ?? []).map((item) => ({
                ...item,
                id: item?.provider_heard_about_id,
              }))}
              colProps={{
                xs: '12',
                md: '6',
                lg: '3',
                xl: '2',
              }}
              formGroupClass="mb-2"
              handleAttributeFunction={() => {
                // setValue('referred_by_id', '');
                setValue('who_referred_you', '');
              }}
            />
          </Row>
          {Number(providerHeardAboutId) === 50
          || Number(providerHeardAboutId) === 110 ? (
            <Row>
              <Col xs="12" md="4" className="mt-4">
                <FormGroup className="mb-3">
                  <Label for="">Put who referred you</Label>
                  {/* <AsyncSelectField
                    name="referred_by_id"
                    loadOptions={getReferralInputOptions}
                    isClearable
                  /> */}
                  <InputField name="who_referred_you" type="text" />
                </FormGroup>
              </Col>
            </Row>
            ) : (
              ''
            )}
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="8" className="mt-4">
              <FormGroup className="mb-eraser position-relative">
                <Label for="">
                  Please provide us the URL to any reviews about your services
                  (optional)
                  <i
                    data-for="info"
                    data-tip=""
                    data-event="click focus"
                    className="info-icon-outer"
                  >
                    <InfoIcon />
                  </i>
                  <ReactTooltip
                    id="info"
                    getContent={externalReviewUrlTooltipContent}
                    border
                    globalEventOff="click"
                    color="#fff"
                    arrowColor="#283A5D"
                    className="tooltip info-tooltip"
                  />
                </Label>
                {fields.map((item, index) => (
                  <div className="review-input" key={item?.id}>
                    <InputField
                      name={`external_review_urls.${index}.url`}
                      useFieldArrayErrorKey={`external_review_urls.${index}`}
                      placeholder="(e.g. Yelp, FB, Google, Angies List, BBB, Merchant Circle, Yellow Pages, etc.)"
                    />
                    <Button
                      onClick={() => remove(index)}
                      className="remove-link"
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </FormGroup>
            </Col>

            <Col xs="12">
              {isEmpty(
                externalReviewUrls?.filter((item) => isEmpty(item?.url)),
              ) ? (
                <Link
                  to="#"
                  className="blue-link"
                  onClick={() => append({
                    url: '',
                  })}
                >
                  + Add Another Review URL
                </Link>
                ) : (
                  ''
                )}
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <h4>Where can we send you physical mail?</h4>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="8">
              <FormGroup className="mb-3">
                <Label for="">Street Address</Label>
                <InputField name="street_address" maxLength="100" />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup className="mb-3">
                <Label for="">Apartment, suite, etc.</Label>
                <InputField name="suite" maxLength="10" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4">
              <FormGroup className="mb-3">
                <Label for="">City</Label>
                <InputField name="city" maxLength="30" />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup className="mb-3">
                <Label for="">State</Label>
                <SelectField
                  name="state_id"
                  options={
                    generateDropdownOptionsFromHash(yourInfo?.states) || []
                  }
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="form-control-select"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup className="mb-3">
                <Label for="">Zip code</Label>
                <CustomNumberField
                  format="#####"
                  name="zip"
                  className="form-control"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <hr className="global-divider" />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="8">
              <FormGroup className="mb-3">
                <Label for="">
                  {isProviderTypeCompany()
                    ? 'Why would your company be a good addition to the Lula platform?'
                    : 'Why would you be a good addition to the Lula platform?'}
                </Label>
                <InputField
                  name="good_candidate_reason"
                  type="textarea"
                  className="custom-textarea"
                  placeholder="I would be a good candidate as a Lula provider because....."
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="action-wrapper">
                <Button color="primary" type="submit" disabled={savingDetails}>
                  {savingDetails ? (
                    <div className="loader-dual-ring" />
                  ) : (
                    'Save & Continue'
                  )}
                </Button>
                {/* <Button color="link">
                Previous Step
              </Button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </FormProvider>
  );
};

export default YourInfo;
