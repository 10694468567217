import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { ErrorMessage } from '@hookform/error-message';
import 'react-datepicker/dist/react-datepicker.css';

const ReactDatePicker = (props) => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const {
    children,
    hideFormError,
    name: propsName,
    clearError,
    shouldUnregister,
    popperClassName,
    wrapperClassName,
    ...inputProps
  } = props;

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propsName}
        render={({ field: { onChange, onBlur, value } }) => (
          <DatePicker
            wrapperClassName={wrapperClassName}
            popperClassName={popperClassName}
            selected={value}
            onBlur={onBlur}
            onChange={(event) => {
              onChange(event);
              if (clearError) {
                clearErrors();
              }
            }}
            {...inputProps}
          />
        )}
      />
      {!hideFormError ? (
        <ErrorMessage
          errors={errors}
          name={propsName}
          render={({ message }) => (
            <small className="text-danger">{message}</small>
          )}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default ReactDatePicker;
