import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'reactstrap';

const CheckboxArray = (props) => {
  const {
    control,
    clearErrors,
    trigger,
  } = useFormContext();

  const {
    children,
    hideFormError,
    name: propsName,
    shouldUnregister,
    currentValue,
    unCheckHandler,
    checkHandler,
    clearAllErrors,
    defaultValue = [],
    triggerAllFields,
    ...inputProps
  } = props;

  return (
    <Controller
      name={propsName}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field: { onBlur, onChange, value } }) => (
        <Input
          name={propsName}
          onBlur={onBlur}
          checked={(value || [])?.some(
            (existingValue) => existingValue.id?.toString() === currentValue.id?.toString(),
          )}
          onChange={(event) => {
            if (event.target.checked) {
              onChange([...value, currentValue]);
              if (checkHandler) {
                checkHandler(currentValue);
              }
            } else {
              onChange(
                value?.filter(
                  (selectedValue) => selectedValue.id?.toString() !== currentValue.id?.toString(),
                ),
              );
              if (unCheckHandler) {
                unCheckHandler(currentValue.id);
              }
            }
            if (clearAllErrors) {
              clearErrors();
            }
            if (triggerAllFields) {
              trigger();
            }
          }}
          value={currentValue}
          {...inputProps}
          type="checkbox"
        >
          {children}
        </Input>
      )}
    />
  );
};

export default CheckboxArray;
