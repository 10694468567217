/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Container,
  List,
  Label,
  Button,
  Row,
  Col,
  Form,
  Progress,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { TickIcon } from '../../../assets/images/svg-icons';
import { scrollToError } from '../../../helpers/utils';
import InputField from '../../shared/form-fields/input-field';
import {
  getSlaAgreement,
  saveSlaAgreement,
} from '../../../services/pro-signup-services';
import catchHandler from '../../../helpers/catch-handler';
import { fetchProfile } from '../profile';
import SlaAgreementModal from './sla-agreement-modal';
import BackgroundCheckModal from './background-check-modal';
import { getStepCount } from '../../wizard/progress';
import LulaProScoringMetricsInfoModal from './lula-pro-scoring-metrics-info-modal';
import { useProfile } from '../../../context/profile-context/profile-context';

const SlaAgreement = () => {
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [savingDetails, setSavingDetails] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [showSlaAgreement, setShowSlaAgreement] = useState(false);
  const [showBackgroundCheckModal, setShowBackgroundCheckModal] = useState(false);
  const [showLulaScoringMetricsInfo, setShowLulaScoringMetricsInfo] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { setProfileDetails } = useProfile();

  const schema = yup.object().shape({
    agree_service_terms: yup
      .boolean()
      .oneOf([true], 'You Must Agree to our SLA to Proceed.')
      .nullable(),
    acknowledged_for_lula_commission: yup
      .boolean()
      .oneOf([true], 'You Must Agree to our 12% take rate terms to Proceed.')
      .nullable(),
  });

  const setDefaultValues = (data) => ({
    agree_service_terms: !!Number(data?.sla_agreement),
    acknowledged_for_lula_commission: !!Number(data?.acknowledged_for_lula_commission),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: setDefaultValues(),
  });

  const {
    formState: { errors },
    reset,
    watch,
    setError,
  } = methods;

  useEffect(() => {
    if (!isEmpty(dataSource)) {
      reset(setDefaultValues(dataSource));
    }
  }, [reset, dataSource]);

  useEffect(() => {
    setFetchingDetails(true);
    getSlaAgreement()
      .then((res) => {
        setFetchingDetails(false);
        setDataSource(res?.data);
      })
      .catch((err) => {
        catchHandler(err, setError);
        setFetchingDetails(false);
      });
  }, [setError]);

  const onError = (formErrors) => {
    scrollToError(formErrors);
  };

  const {
    agree_service_terms: agreeServiceTerms,
    acknowledged_for_lula_commission: acknowledgedForLulaCommission,
  } = watch();

  const onSubmit = (data) => {
    const payload = {
      agree_service_terms: data?.agree_service_terms ? '1' : '0',
      acknowledged_for_lula_commission: data?.acknowledged_for_lula_commission ? '1' : '0',
    };
    setSavingDetails(true);
    saveSlaAgreement(payload)
      .then((res) => {
        fetchProfile(setSavingDetails, navigate, setProfileDetails, {
          current_step: getStepCount(location?.pathname) - 1,
        });
      })
      .catch((err) => {
        setSavingDetails(false);
        catchHandler(err, setError);
      });
  };

  const handleSlaAgreementClose = () => {
    setShowSlaAgreement(false);
  };

  const handleBackgroundCheckPopupClose = () => {
    setShowBackgroundCheckModal(false);
  };

  const listItems = [
    {
      id: 1,
      text: 'Lula charges no upfront fee and only takes a commission at the time you are paid for a successfully completed job.',
    },
    {
      id: 2,
      text: 'You acknowledge that you are a 1099 subcontractor and a not an employee of Lula.',
    },
    {
      id: 3,
      text: 'For most trades you will have the ability to setup your own pricing once you are approved as a Lula vendor. The more competitive your pricing is the more jobs you will see. is',
    },
    {
      id: 4,
      text: 'Lula will pay providers directly to their bank account within 1-3 business days after completing a job.',
    },
    {
      id: 5,
      text: 'You are not required to accept every job request, claim only the jobs you want.',
    },
    {
      id: 6,
      text: "You understand that Lula doesn't accept all vendors that sign up. Lula wants the best of the best.",
    },
    {
      id: 7,
      text: 'You acknowledge that Lula takes a small commission fee off the total price of the completed job. Lula doesn\'t make any money unless you "the provider" is making money.',
    },
    {
      id: 8,
      text: 'Lula\'s commission fee is 12% for a successfully completed job.',
    },
  ];

  return (
    <div>
      {fetchingDetails ? (
        <div className="page-loader" />
      ) : (
        <Container>
          <div className="main-wrapper">
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                <Row>
                  <Col className="my-4">
                    <Row>
                      <Col xs="12">
                        <h3>Service Level Agreement & Lula's Commission Fee</h3>
                        <div className="header-divider" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md="8" lg="8">
                    <Row>
                      <Col className="sla-intro-section">
                        <h4>Before you proceed, you need to completely read and agree with our Service Level Agreement and Provider Fee Model.</h4>
                        <List className="sla-list">
                          {listItems?.map((item) => (<li key={`item_${item?.id}`}>{item?.text}</li>))}
                        </List>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-5 mt-3">
                        <div className="form-single-confirm">
                          <div className="form-single-box">
                            <div className="form-check-box">
                              <InputField
                                name="agree_service_terms"
                                checked={!!agreeServiceTerms}
                                type="checkbox"
                                id="checkbox1"
                                hideFormError
                              />
                              <label htmlFor="checkbox1">
                                <TickIcon />
                              </label>
                            </div>
                            <Label htmlFor="checkbox1" className="label">
                              I agree to the
                              <Link
                                to="#"
                                onClick={() => {
                                  setShowSlaAgreement(true);
                                }}
                              >
                                {' '}
                                Service Level Agreement (SLA)
                              </Link>
                              .
                            </Label>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name="agree_service_terms"
                            render={({ message }) => (
                              <small className="text-danger">{message}</small>
                            )}
                          />
                          <div className="form-single-box">
                            <div className="form-check-box">
                              <InputField
                                name="acknowledged_for_lula_commission"
                                checked={!!acknowledgedForLulaCommission}
                                type="checkbox"
                                id="checkbox2"
                                hideFormError
                              />
                              <label htmlFor="checkbox2">
                                <TickIcon />
                              </label>
                            </div>
                            <Label htmlFor="checkbox2" className="label">
                              I acknowledge that Lula takes a 12% commission fee at the time I'm paid
                              for a successfully completed job.
                            </Label>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name="acknowledged_for_lula_commission"
                            render={({ message }) => (
                              <small className="text-danger">{message}</small>
                            )}
                          />
                        </div>
                      </Col>
                    </Row>

                  </Col>
                  <Col md="4" lg="4">
                    <div className="example-card">
                      <div className="coming-soon">
                        <h4>(Coming Soon)</h4>
                      </div>
                      <div className="example-card--top">
                        <svg width="197" height="197" viewBox="0 0 197 197" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.07" d="M49.25 147.75C39.5093 138.009 32.8757 125.599 30.1883 112.088C27.5008 98.5773 28.8801 84.573 34.1518 71.8461C39.4234 59.1192 48.3506 48.2414 59.8045 40.5881C71.2584 32.9349 84.7245 28.85 98.5 28.85C112.275 28.85 125.742 32.9349 137.195 40.5881C148.649 48.2414 157.577 59.1192 162.848 71.8461C168.12 84.573 169.499 98.5773 166.812 112.088C164.124 125.599 157.491 138.009 147.75 147.75" stroke="#283A5D" strokeWidth="17.7935" strokeLinecap="round" />
                          <path d="M49.25 147.751C42.2395 140.74 36.8065 132.314 33.3141 123.035C29.8218 113.756 28.3507 103.839 28.9991 93.9454C29.6475 84.0523 32.4006 74.4116 37.0742 65.668C41.7478 56.9243 48.2342 49.2792 56.0998 43.2437C63.9653 37.2082 73.0288 32.9215 82.6841 30.6702C92.3395 28.4189 102.364 28.2549 112.088 30.1891C121.812 32.1232 131.011 36.1111 139.07 41.886C147.128 47.6609 153.862 55.0897 158.819 63.6758" stroke="#C5C831" strokeWidth="17.7935" strokeLinecap="round" />
                          <text x="50%" y="50%" textAnchor="middle" strokeWidth="10px" dy=".1em" fill="#283A5D" fontSize="61px" fontWeight="800">
                            {dataSource?.lula_provider_scores?.pro_score || 0}
                          </text>
                          <text x="50%" y="54%" textAnchor="middle" strokeWidth="0px" dy="1.2em" fill="#FF8E00" fontSize="17px" fontWeight="600" letterSpacing="0px">
                            Example
                          </text>
                        </svg>
                      </div>
                      <div className="example-card--bottom">
                        <h3>Improve Your Lula Pro Score, Pay a Lower Fee</h3>
                        <p>
                          <strong>Lula takes a 12% commission</strong>
                          , but that can be reduced to 10% based on performance
                        </p>
                        {/* <div className="white-card">
                          <h6>
                            Lula's Fee for you is
                            {' '}
                            <strong>
                              {dataSource?.lula_provider_scores?.pro_fee || 0}
                              %
                            </strong>
                          </h6>
                          <div className="fee-tracker">
                            <div className="end-percentage">
                              {dataSource?.lula_provider_scores?.pro_fee_max_range || 0}
                              %
                            </div>
                            <div className="tracker">
                              <div className="tracker--middle">
                                <svg width="214" height="23" viewBox="0 0 214 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <line x1="210.293" y1="16.3435" x2="3.45078" y2="16.3435" stroke="url(#paint0_linear_0_1)" strokeWidth="6.68692" strokeLinecap="round" />
                                  <line x1="132.5" y1="10" x2="132.5" y2="23" stroke="#FCFDFF" />
                                  <line x1="148.5" y1="10" x2="148.5" y2="23" stroke="#FCFDFF" />
                                  <line x1="163.5" y1="10" x2="163.5" y2="23" stroke="#FCFDFF" />
                                  <line x1="180.5" y1="10" x2="180.5" y2="23" stroke="#FCFDFF" />
                                  <line x1="196.5" y1="10" x2="196.5" y2="23" stroke="#FCFDFF" />
                                  <path
                                    d="M141 10L135.804 2.5L146.196 2.5L141 10Z"
                                    fill="#1D66FF"
                                    style={{
                                      right: '12px',
                                    }}
                                  />
                                  <defs>
                                    <linearGradient id="paint0_linear_0_1" x1="213.637" y1="12.5" x2="0.107316" y2="12.5002" gradientUnits="userSpaceOnUse">
                                      <stop stopColor="#48C898" />
                                      <stop offset="0.5" stopColor="#FFC700" />
                                      <stop offset="1" stopColor="#FF0000" />
                                    </linearGradient>
                                  </defs>
                                </svg>
                              </div>
                              <div className="tracker--bottom">
                                <div className="start-value">0</div>
                                <div className="divider" />
                                <div className="end-value">100</div>
                              </div>

                            </div>
                            <div className="start-percentage">
                              {dataSource?.lula_provider_scores?.pro_fee_min_range || 0}
                              %
                            </div>
                          </div>
                        </div> */}
                        <div className="white-card">
                          <h6>Performance Metrics used to Calculate Your Lula Pro Score</h6>
                          <div className="score-progress">
                            <div className="score-progress--row">
                              <div className="label">Pricing</div>
                              <div className="score">
                                <Progress
                                  className="lemon"
                                  style={{
                                    height: '6px',
                                  }}
                                  value={dataSource?.lula_provider_scores?.score_metrics?.pricing || 0}
                                />
                              </div>
                              <div className="value">{dataSource?.lula_provider_scores?.score_metrics?.pricing || 0}</div>
                            </div>
                            <div className="score-progress--row">
                              <div className="label">Tenant Rating</div>
                              <div className="score">
                                <Progress
                                  className="green"
                                  style={{
                                    height: '6px',
                                  }}
                                  value={dataSource?.lula_provider_scores?.score_metrics?.tenant_rating || 0}
                                />
                              </div>
                              <div className="value">{dataSource?.lula_provider_scores?.score_metrics?.tenant_rating || 0}</div>
                            </div>
                            <div className="score-progress--row">
                              <div className="label">Punctuality</div>
                              <div className="score">
                                <Progress
                                  className="green"
                                  style={{
                                    height: '6px',
                                  }}
                                  value={dataSource?.lula_provider_scores?.score_metrics?.punctuality || 0}
                                />
                              </div>
                              <div className="value">{dataSource?.lula_provider_scores?.score_metrics?.punctuality || 0}</div>
                            </div>
                            <div className="score-progress--row">
                              <div className="label">Engagement</div>
                              <div className="score">
                                <Progress
                                  className="lemon"
                                  style={{
                                    height: '6px',
                                  }}
                                  value={dataSource?.lula_provider_scores?.score_metrics?.engagement || 0}
                                />
                              </div>
                              <div className="value">{dataSource?.lula_provider_scores?.score_metrics?.engagement || 0}</div>
                            </div>
                          </div>
                          <Button className="btn-link" onClick={() => setShowLulaScoringMetricsInfo(true)}>Learn More About Scoring Metrics</Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="action-wrapper">
                      <Button
                        color="primary"
                        disabled={savingDetails}
                      >
                        {savingDetails ? (
                          <div className="loader-dual-ring" />
                        ) : (
                          'Save & Continue'
                        )}
                      </Button>
                      <Link
                        to="/signup/competency-test"
                        className="btn-link"
                      >
                        Previous Step
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </div>
        </Container>
      )}
      {showLulaScoringMetricsInfo
        ? (
          <LulaProScoringMetricsInfoModal
            closeModal={() => setShowLulaScoringMetricsInfo(false)}
          />
        ) : ''}
      {showSlaAgreement ? (
        <SlaAgreementModal
          closeModal={handleSlaAgreementClose}
        />
      ) : (
        ''
      )}
      {showBackgroundCheckModal ? (
        <BackgroundCheckModal
          closeModal={handleBackgroundCheckPopupClose}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default SlaAgreement;
