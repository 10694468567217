import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  Col, Input, Label, FormGroup,
} from 'reactstrap';

const RadioArray = (props) => {
  const {
    control,
    formState: { errors },
    trigger,
    clearErrors,
  } = useFormContext();

  const {
    children,
    hideFormError,
    name: propsName,
    clearError,
    shouldUnregister,
    triggerValidationOnChange,
    defaultValue = '',
    currentValue,
    options,
    colProps,
    formGroupClass,
    handleAttributeFunction,
    ...inputProps
  } = props;

  return (
    <>
      <Controller
        name={propsName}
        control={control}
        defaultValue={defaultValue}
        shouldUnregister={shouldUnregister}
        render={({ field }) => (
          <>
            {(options || []).map((option) => (
              <Col {...colProps} key={option?.id}>
                <FormGroup check className={formGroupClass}>
                  <Input
                    {...field}
                    {...inputProps}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      if (clearError) {
                        clearErrors(propsName);
                      }
                      if (triggerValidationOnChange) {
                        trigger();
                      }
                      if (handleAttributeFunction) {
                        handleAttributeFunction();
                      }
                    }}
                    checked={field.value?.toString() === option?.id?.toString()}
                    id={`${propsName}-${option?.id}`}
                    value={option?.id}
                  />
                  <Label
                    htmlFor={`${propsName}-${option?.id}`}
                    className="form-check-label"
                  >
                    {option?.label}
                  </Label>
                </FormGroup>
              </Col>
            ))}
          </>
        )}
      />
      {!hideFormError ? (
        <ErrorMessage
          errors={errors}
          name={propsName}
          render={({ message }) => (
            <small className="text-danger">{message}</small>
          )}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default RadioArray;
