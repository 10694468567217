import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { useProfile } from '../../context/profile-context/profile-context';
import { getProfile } from '../../services/pro-signup-services';

const navigateTo = (currentStep) => {
  switch (currentStep) {
    case 1: return '/signup/your-info';
    case 2: return '/signup/cities-served';
    case 3: return '/signup/services-offered';
    case 4: return '/signup/service-pricing';
    case 5: return '/signup/competency-test';
    case 6: return '/signup/sla-agreement';
    case 7: return '/signup/safety-policy';
    case 8: return '/signup/done';
    default: return '/signup/your-info';
  }
};

export const fetchProfile = (
  setLoading,
  navigate,
  setProfileDetails,
  payload = false,
) => {
  getProfile(payload).then((res) => {
    if (setLoading) {
      setLoading(false);
    }
    if (!isEmpty(res?.data)) {
      if (setProfileDetails) {
        setProfileDetails(res?.data);
      }
      if ((typeof (res?.data?.signup_current_step) === 'number') && !Number(res?.data?.is_provider_state_not_in_list)) {
        const signupStep = res?.data?.signup_current_step;
        const current = signupStep + 1;
        navigate(navigateTo(current));
      }
      if (Number(res?.data?.is_provider_state_not_in_list)) {
        navigate(navigateTo(8));
      }
    }
  }).catch(() => {
    if (setLoading) {
      toast('Something went wrong!');
      setLoading(false);
    }
  });
};

const Profile = () => {
  const [fetchingProfile, setFetchingProfile] = useState(false);
  const { setProfileDetails } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfile(setFetchingProfile, navigate, setProfileDetails);
  }, [navigate, setProfileDetails]);

  return (fetchingProfile ? <div className="page-loader" /> : '');
};

export default Profile;
