import { isEmpty } from 'lodash';
import * as yup from 'yup';

const useCitiesServed = (pickedStates, pickedMarketCities, dataSource) => {
  const validations = {};

  // const marketCitySchema = () => {
  //   const validationObj = {};
  //   pickedStates?.forEach((state) => {
  //     validationObj[`state_id_${state?.id}`] = yup.array().min(1, 'Please select city/cities');
  //   });
  //   return validationObj;
  // };

  const countySchema = () => {
    const validationObj = {};
    pickedMarketCities?.forEach((cityData) => {
      const counties = dataSource?.cities[cityData?.id]?.counties;
      if (!isEmpty(counties)) {
        validationObj[`market_city_id_${cityData?.id}`] = yup.array().min(1, 'Please select county/counties');
      }
    });

    return validationObj;
  };

  // validations.market_cities = yup.object(marketCitySchema());
  validations.cities = yup.string().when('states', {
    is: (val) => !isEmpty(val),
    then: yup.string().test('isCitySelected', 'Please select any city/cities.', () => {
      if (isEmpty(pickedMarketCities)) {
        return false;
      }
      return true;
    }),
  });
  validations.counties = yup.object(countySchema());
  validations.states = yup.array().when('is_provider_state_not_in_list', {
    is: (value) => value !== true,
    then: yup.array().min(1, 'Please select a state'),
  });

  const citiesSchema = yup.object().shape(validations);
  return [citiesSchema];
};

export default useCitiesServed;
