import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select from 'react-select';

const SelectField = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { name: propsName, shouldUnregister, ...inputProps } = props;

  return (
    <>
      <Controller
        name={propsName}
        control={control}
        shouldUnregister={shouldUnregister}
        render={({ field }) => (
          <Select
            name={propsName}
            {...field}
            classNamePrefix="selectbox"
            {...inputProps}
            value={field.value}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={propsName}
        render={({ message }) => (
          <small className="text-danger">{message}</small>
        )}
      />
    </>
  );
};

export default SelectField;
