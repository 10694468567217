import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

const ServicePricingRedirect = ({
  onSubmit,
  runningCountdownStopped,
}) => {
  const [countdown, setCountdown] = useState('');

  const timeoutRef = useRef(null);

  const doTimer = useCallback((value) => {
    setCountdown(value);
    timeoutRef.current = setTimeout(() => {
      const currentValue = value - 1;
      if (currentValue >= 0) {
        doTimer(currentValue);
      } else {
        onSubmit();
      }
    }, 1000);
  }, [timeoutRef, onSubmit]);

  useEffect(() => {
    if (!runningCountdownStopped) {
      doTimer(10);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [doTimer, runningCountdownStopped]);

  return (
    <p className="mt-3 font-600">
      You haven’t selected any hourly services so you don’t need to take any
      action on this page.
      <br />
      You will be redirect to the next step in
      {' '}
      {countdown}
      {' '}
      seconds.
    </p>
  );
};

export default ServicePricingRedirect;
