import React from 'react';

const ServicePricing = (props) => (
  <svg {...props} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2987 35.8239C13.5877 35.8239 9.15855 33.9894 5.82735 30.6582C2.49615 27.327 0.661621 22.8979 0.661621 18.1869C0.661621 13.4758 2.49622 9.0468 5.82735 5.71553C9.15848 2.38426 13.5877 0.549805 18.2987 0.549805C23.0097 0.549805 27.4388 2.38433 30.77 5.71553C34.1012 9.04673 35.9357 13.4758 35.9357 18.1869C35.9357 22.8979 34.1011 27.3269 30.77 30.6582C27.4389 33.9894 23.0097 35.8239 18.2987 35.8239V35.8239ZM18.2987 2.75444C9.78921 2.75444 2.86625 9.67739 2.86625 18.1869C2.86625 26.6963 9.78921 33.6193 18.2987 33.6193C26.8081 33.6193 33.7311 26.6963 33.7311 18.1869C33.7311 9.67739 26.8081 2.75444 18.2987 2.75444Z" fill="white" />
    <path d="M18.2987 17.0849C16.7791 17.0849 15.5429 15.8487 15.5429 14.3291C15.5429 12.8096 16.7791 11.5733 18.2987 11.5733C19.8182 11.5733 21.0545 12.8096 21.0545 14.3291C21.0545 14.9379 21.548 15.4314 22.1568 15.4314C22.7656 15.4314 23.2591 14.9379 23.2591 14.3291C23.2591 11.9728 21.6071 9.99646 19.401 9.49367V8.26638C19.401 7.65762 18.9075 7.16406 18.2987 7.16406C17.6899 7.16406 17.1964 7.65762 17.1964 8.26638V9.49367C14.9902 9.99646 13.3383 11.9728 13.3383 14.3291C13.3383 17.0643 15.5635 19.2895 18.2987 19.2895C19.8182 19.2895 21.0545 20.5258 21.0545 22.0453C21.0545 23.5649 19.8182 24.8011 18.2987 24.8011C16.7791 24.8011 15.5429 23.5649 15.5429 22.0453C15.5429 21.4366 15.0494 20.943 14.4406 20.943C13.8317 20.943 13.3383 21.4366 13.3383 22.0453C13.3383 24.4016 14.9902 26.378 17.1964 26.8808V28.1081C17.1964 28.7168 17.6899 29.2104 18.2987 29.2104C18.9075 29.2104 19.401 28.7168 19.401 28.1081V26.8808C21.6071 26.378 23.2591 24.4016 23.2591 22.0453C23.2591 19.3101 21.0339 17.0849 18.2987 17.0849Z" fill="white" />
  </svg>

);
export default ServicePricing;
