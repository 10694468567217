import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const BackgroundCheckModal = ({ closeModal }) => (
  <Modal isOpen toggle={closeModal} className="signup-modal sla-agreement-modal" centered>
    <ModalHeader toggle={closeModal} />
    <ModalBody>
      <div className="sla-agreement-data">
        <div className="welcome__custom-scroll-wrap">
          <h3 className="welcome__section-head">
            Lula, LLC
            <br />
            Background Check Policy
          </h3>
          <p>Last Modified: September 24, 2021</p>
          <p>
            By offering services on the Lula, LLC (“
            <b>Lula</b>
            ”) platform
            or to any Lula customer that requests services through Lula’s
            websites or applications (each, a “
            <b>Customer</b>
            ”), each
            service provider must either conduct a background check on any
            personnel providing services to Lula users (“
            <b>Service Personnel</b>
            ”) and provide a copy of such reports to
            Lula, or notify Lula prior to providing services and make the
            Service Personnel available to Lula for a background check,
            should Lula wish to conduct a background check on the Service
            Personnel.
          </p>

          <p>
            <b>Customer Services</b>
          </p>
          <p>
            Service providers must conduct background checks, or permit Lula
            to conduct background checks, on all Service Personnel who
            provide services to Customers. These background checks must
            include, at a minimum (to the full extent permitted by
            applicable law):
          </p>
          <ol>
            <li>
              Confirmation of a service provider’s professional license (if
              applicable);
            </li>
            <li>A social security trace;</li>
            <li>A multi-jurisdictional criminal search;</li>
            <li>At least a 7-year, on-site county criminal check;</li>
            <li>
              At least a five-year on-site county civil action check;
              {' '}
            </li>
            <li>A National Sex Offender Registry check; and</li>
            <li>A Domestic Terrorist Watch List check.</li>
          </ol>
          <p>
            No Service Personnel may provide services on or through the Lula
            platform until he or she has been approved. Without limiting
            Lula’s rights to approve or deny any Service Personnel, an
            acceptable background check must include (to the full extent
            permitted by applicable law) at a minimum (for the period
            covered by the background check) that the Service Provider:
          </p>
          <ol>
            <li>
              Has an active license in good standing if licensure is
              necessary to provide the services that he or she will provide
              on the Lula platform;
            </li>
            <li>
              Possesses a valid social security number matching the Service
              Personnel’s identity;
              {' '}
            </li>
            <li>
              Has a clean criminal record that complies with Lula’s internal
              policies and requirements for at least 7 years;
            </li>
            <li>Is not on any Sex Offender Registry; and</li>
            <li>Is not on the Domestic Terrorist Watch list.</li>
          </ol>
          <p>
            <b>Generally</b>
          </p>
          <p>
            If any Service Personnel has been a defendant in a civil or
            criminal action involving injury to any person (including as a
            result of providing goods or services similar to the services to
            be provided by such Service Personnel to Customers), Lula may
            refuse to allow such Service Personnel to provide services to
            any Customer.
            {' '}
          </p>
          <p>
            By offering services on or through the Lula platform, each
            service provider shall observe all state and federal
            requirements for conducting employment related background
            checks, including but not limited to obtaining the consent of
            the Service Personnel subject to a background check prior to
            providing Lula with a copy of the background check report and
            observing all state and federal law requirements.
          </p>
          <p>
            Background checks required to be conducted by service providers
            pursuant to this Policy must be performed at least once a year
            for each Service Personnel and results shall be provided to
            Lula, upon request. Lula may, in its sole discretion, require
            that a background check be conducted on a Service Personnel at
            any time in the event the Service Personnel is the subject of a
            complaint from or dispute with a Customer.
            {' '}
          </p>
          <p>
            Lula does not require Service Personnel to be tested for drug or
            alcohol use; however, Lula has a “Zero Tolerance” Policy for
            intoxication or the use of drugs or alcohol by Service Personnel
            at the time of providing Services or at any time that would
            affect the Service Personnel’s ability to perform Services.
            Service Personnel who violate Lula’s Zero Tolerance policy will
            be immediately and permanently barred from providing Services on
            the Lula platform.
          </p>
          <p>
            Lula may amend this Background Check Policy from time to time. A
            service provider’s (and a Service Personnel’s) continued use of
            or provision of Services under the Lula platform constitutes
            acceptance of the Background Check Policy, as it may be amended
            by Lula from time to time.
            {' '}
          </p>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

export default BackgroundCheckModal;
