import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import catchHandler from '../../../helpers/catch-handler';
import { getLulaProScoringMetrics } from '../../../services/pro-signup-services';

const LulaProScoringMetricsInfoModal = ({ closeModal }) => {
  const [sourceData, setSourceData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getLulaProScoringMetrics().then((res) => {
      setIsLoading(false);
      setSourceData(res?.data);
    }).catch((error) => {
      setIsLoading(false);
      closeModal();
      catchHandler(error, false);
    });
  }, [closeModal]);

  return (
    <Modal isOpen toggle={closeModal} className="signup-modal scoring-metrics-modal" centered>
      <ModalHeader toggle={closeModal}>
        <p className="modal-title">Lula Pro Scoring Metrics</p>
      </ModalHeader>
      <ModalBody>
        {isLoading ? <div className="page-loader" /> : (
          <div className="scoring-data">
            {sourceData?.content?.map((item) => (
              <React.Fragment key={item.heading}>
                <h4>{item?.heading}</h4>
                <p>
                  <strong>{item?.sub_heading}</strong>
                  {' '}
                  {item?.content}
                </p>
              </React.Fragment>
            ))}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default LulaProScoringMetricsInfoModal;
