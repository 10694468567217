import React from 'react';

const StatusHighIcon = (props) => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.43084 1.27695L0.199431 13.8012C0.0688063 14.0274 2.41759e-05 14.284 6.37194e-09 14.5451C-2.41631e-05 14.8063 0.0687105 15.0629 0.199293 15.2891C0.329876 15.5153 0.517706 15.7031 0.743899 15.8337C0.970092 15.9643 1.22668 16.033 1.48786 16.033H15.9495C16.2107 16.033 16.4673 15.9643 16.6935 15.8337C16.9197 15.7031 17.1075 15.5153 17.2381 15.2891C17.3687 15.0629 17.4374 14.8063 17.4374 14.5451C17.4374 14.284 17.3686 14.0274 17.2379 13.8012L10.0073 1.27695C9.87675 1.05082 9.68897 0.863041 9.46283 0.732488C9.2367 0.601934 8.98019 0.533203 8.71908 0.533203C8.45796 0.533203 8.20145 0.601934 7.97532 0.732488C7.74919 0.863041 7.56141 1.05082 7.43084 1.27695Z" fill="#EE404C" />
    <path d="M8.81274 5.13977H8.62411C8.15969 5.13977 7.7832 5.51626 7.7832 5.98067V10C7.7832 10.4645 8.15969 10.8409 8.62411 10.8409H8.81274C9.27716 10.8409 9.65364 10.4645 9.65364 10V5.98067C9.65364 5.51626 9.27716 5.13977 8.81274 5.13977Z" fill="#FFF7ED" />
    <path d="M8.71842 14.029C9.23493 14.029 9.65364 13.6103 9.65364 13.0938C9.65364 12.5773 9.23493 12.1586 8.71842 12.1586C8.20192 12.1586 7.7832 12.5773 7.7832 13.0938C7.7832 13.6103 8.20192 14.029 8.71842 14.029Z" fill="#FFF7ED" />
  </svg>

);
export default StatusHighIcon;
