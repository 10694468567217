import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'reactstrap';
import { useProfile } from '../../context/profile-context/profile-context';
import { logout } from '../../services/pro-signup-services';

import Progress from '../wizard/progress';

const Header = () => {
  const { profileDetails } = useProfile();

  const handleLogout = () => {
    logout().then((res) => {
      if (process.env.REACT_APP_LOGIN_URL) {
        window.open(`${process.env.REACT_APP_LOGIN_URL}`, '_self');
      }
      if (window?.smartlook) {
        window.smartlook('anonymize');
      }
    }).catch(() => {
      toast('Something went wrong!');
    });
  };

  return (
    <div className="main-header">
      <Container>
        <Row>
          <Col>
            <div className="main-header__outer">
              <div className="main-header__inner">
                <img src="https://lula.life/wp-content/themes/l/img/lula-logo.svg" alt="Lula logo" />
                <div className="d-flex">
                  <div className="username">
                    Hi
                    {' '}
                    {profileDetails?.first_name}
                    ,
                    {' '}
                  </div>
                  <Link to="#" onClick={handleLogout} className="signout"> Signout</Link>
                </div>
              </div>
              <Progress />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
