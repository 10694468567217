import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const SlaAgreementModal = ({ closeModal }) => (
  <Modal isOpen toggle={closeModal} className="signup-modal sla-agreement-modal" centered>
    <ModalHeader toggle={closeModal} />
    <ModalBody>
      <div className="sla-agreement-data">
        <h3 className="welcome__section-head">
          Lula Smarter Property Maintenance, Inc.
          <br />
          Service Level Agreement
        </h3>
        <p>Last Modified: December 1, 2022</p>
        <p>
          Please read this service level agreement (“
          <b>SLA</b>
          ”) carefully before accessing or using Lula Smarter Property
          Maintenance, Inc.’s (“
          <b>Lula</b>
          ”, “
          <b>we</b>
          ”, “
          <b>us</b>
          ”, or “
          <b>our</b>
          ”) mobile applications or websites (the “
          <b>Applications</b>
          ”).
          {' '}
          <b>
            By accessing, downloading, installing, or using the Applications
            and/or providing the Services (defined below), you and your company
            (in the event that you provide Services as or through a legal entity
            other than a natural person) (collectively, “you” and “your”) accept
            and agree to be bound and abide by this SLA, our privacy policy found
            at
            {' '}
            <a
              href="https://lula.life/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              www.lula.life/privacy-policy
            </a>
            {' '}
            (the “Privacy Policy”) our terms and conditions found at
            {' '}
            <a
              href="https://lula.life/terms-of-service/"
              target="_blank"
              rel="noreferrer"
            >
              www.lula.life/terms-of-service
            </a>
            {' '}
            (the “Terms and Conditions”), and our background check policy made
            available to you by Lula in your user account (the “Background Check
            Policy”), as they may be amended by Lula from time to time
          </b>
          . If you do not agree to this SLA, the Privacy Policy, Terms and
          Conditions, or Background Check Policy, you may not access, use, or
          install the Applications or provide the Services. In the event of a
          conflict between the terms of this SLA, the Privacy Policy, Terms and
          Conditions, and Background Check Policy, the terms of this SLA shall
          control and, together with the Privacy Policy, Terms and Conditions, and
          Background Check Policy, is the final and exclusive agreement with
          respect to your use of the Applications for the Services. As used
          herein, the words &ldquo;including&rdquo; and &ldquo;include&rdquo; mean
          &ldquo;including, but not limited to.&rdquo; Notwithstanding the
          foregoing, if you have secured a right to use the Applications and
          provide the Services under a separate written Lula service level
          agreement setting forth the material terms governing your right to use
          the Applications and provide the Services that is signed by you and Lula
          (a “
          <b>Written SLA</b>
          ”), then the terms of the Written SLA shall control over and supersede
          the terms of this SLA.
          {' '}
        </p>
        <u>
          <b>
            Acceptance, Insurance, Licensure, Non-Discrimination, and Substance
            Use.
          </b>
        </u>
        <p>
          Lula reserves the right to accept or deny your access to the
          Applications, and your ability to provide the Services through the
          Applications. You agree that, as conditions to your use of the
          Applications and delivery of the Services, at all times during the term
          of this SLA you and your Personnel will:
          {' '}
        </p>

        <ul>
          <li>
            <p>
              (a) Provide proof of, upon Lula’s request, and maintain insurance
              coverage (including general business liability insurance) at
              commercially reasonable levels sufficient to cover any claims that
              may arise from you or your Personnel providing the Services,
              including coverage against injury to persons or property that may
              arise from your delivery of the Services as well as coverage for
              your indemnification obligations hereunder (“
              <b>Covered Obligations</b>
              ”); (b) ensure that such insurance is primary (insurer of first
              resort) with respect to all matters arising out of your or your
              Personnel’s performance under this SLA and the Covered Obligations;
              (c) except to the extent prohibited by applicable law, require its
              insurer to waive all rights of subrogation against Lula’s insurer
              and Lula (or any person entitled to indemnity under this SLA); and
              (d) assign your and your Personnel’s rights to proceeds received
              from such insurance in connection with any Covered Obligations;
            </p>
          </li>
          <li>
            <p>
              Provide proof of (upon Lula’s request) and maintain applicable
              permits, licenses, and other certifications necessary for you to
              provide the Services;
              {' '}
            </p>
          </li>
          <li>
            <p>
              Not discriminate, in hiring Personnel or in the provision of
              Services, against anyone on the basis of race, color, creed,
              religion, national origin, sex, disability, sexual orientation, age,
              marital status, or status with regard to public assistance; and
            </p>
          </li>
          <li>
            <p>
              Not use, abuse, or be under the influence of any intoxicating
              substance (including alcohol, illegal drugs, or other impairing
              substances) during the provision of Services, other than legally
              prescribed and over-the-counter medications and then only to the
              extent that use of such medications does not materially interfere
              with your or your Personnel’s ability to provide the Services is a
              safe and professional manner.
              {' '}
            </p>
          </li>
        </ul>
        <u>
          <b>Background Checks and Licensure </b>
        </u>
        <p>
          You agree, as a condition to your use of the Applications and delivery
          of the Services, that at all times during your provision of Services,
          you and your Personnel will abide by the Background Check Policy and
          that all of your Personnel will have completed a satisfactory background
          check prior to delivering services to any Lula Customer. If Lula orders
          or conducts a background check for any of your Personnel, you agree to
          obtain the necessary consent from your Personnel for such check and that
          Lula is authorized to deduct the fees for such background check from any
          amounts payable to you for any job that you perform for Customers. Lula
          will charge and you will a background check fee of $35 per Personnel,
          per background check (the “
          <b>Background Check Fee</b>
          ”). Lula may increase the Background Check Fee at any time in its
          discretion with or without notice. If requested by Lula, you agree to
          complete, sign, and return a self-certification form (in the form
          approved by Lula) stating that you have performed background checks on
          all Personnel that provide Services. In addition to (but not in
          derogation of) to the requirements set forth in the Background Check
          Policy, you agree that neither you nor your Personnel will enter the
          home, dwelling, apartment, office, or other property of a Customer (as
          defined below) or its tenants unless the Customer has requested services
          through the Applications and you are conducting such entry solely to
          provide the Services for an Accepted Job Request that you accepted.
          {' '}
        </p>
        <p>
          You agree to provide to Lula, on an annual basis, written certifications
          that all of your Personnel hold and maintain the requisite professional
          licensure to perform the Services (and to provide copies of such
          licenses to Lula upon request).
        </p>
        <p>
          Lula may adopt additional personal, licensure, professional, background,
          and other standards and requirements for Personnel from time to time.
          You and your Personnel shall adhere to these standards at all times
          while providing Services.
        </p>
        <p>
          Failure to (a) maintain required licensure or certifications for
          Personnel; (b) conduct background checks on Personnel; or (c) complete
          the self-certification form provided by Lula may result in suspension or
          termination of your account with Lula (and any jobs in process), without
          refund.
          {' '}
        </p>
        <u>
          <b>Rights</b>
        </u>
        <p>
          You acknowledge and agree that the Applications are provided to you
          under license, and are not sold to you. You do not acquire any ownership
          interest in, or any other rights to, the Applications under this SLA
          other than to use the Applications in accordance with the license
          granted, and subject to all terms, conditions, and restrictions, under
          this SLA and the Terms and Conditions. Lula and its licensors reserve
          and shall retain their entire right, title, and interest in and to the
          Applications, including all copyrights, trademarks, and other
          intellectual property rights therein or relating thereto, except as
          expressly granted to you in this SLA or the Terms and Conditions.
        </p>
        <u>
          <b>Customer Relationships</b>
        </u>
        <p>
          By allowing you to receive Job Requests and service Accepted Job
          Requests, Lula will be placing you in a position of trust with its
          Services Providers, customers, and its customers tenants (each, a “
          <b>Lula Relationship</b>
          ”). Therefore, you agree that at all times while you are providing
          services to Lula or the Lula Relationships, and for a period of one (1)
          year following the latter of the last date that you provide services to
          Lula or the Lula Relationships or the date that your user account is
          deactivated from the Applications, you agree that you shall not solicit,
          induce, influence, or encourage any Lula Relationship to alter,
          terminate, or breach its contractual relationship with Lula, or solicit
          the business of such Lula Relationship to provide goods or services that
          are similar to the Services or that would compete with the goods and
          services offered by Lula. Notwithstanding the foregoing, nothing in this
          section restricts you from soliciting business in the normal course of
          your business (such as through general advertisements), so long as you
          do not use any Confidential Information in such advertisements and such
          solicitations are not directed at any Lula Relationship. You agree that
          while interacting with any Lula Relationship, that you and your
          Personnel shall communicate and operate in a manner that would not
          damage Lula’s relationship with any Lula Relationships.
        </p>
        <u>
          <b>Services</b>
        </u>
        <p>
          The Applications constitute a technology platform that enables you to
          accept Job Requests that are submitted by customers (the “
          <b>Customers</b>
          ”) pertaining to property maintenance and other property services (and
          corresponding goods) designated within the Applications, as updated and
          modified by Lula from time to time (the “
          <b>Services</b>
          ”). Notwithstanding the foregoing and any list set forth within the
          Applications, you are limited to providing only the Services that you
          and your Personnel are legally and professionally (as determined in
          Lula’s sole discretion) qualified to provide, in each case within your
          Personnel’s professional licensure and certifications, and you may not
          select Job Requests outside of your licensure and certifications. You
          may also only provide Services for jobs within the permitted
          geographical proximity to your Personnel (as set out in the
          Applications). Lula reserves the right to reject one or more Service
          designations with respect to you and to limit the Job Requests that may
          be made available to or accepted by you. A “
          <b>Job</b>
          ” means a project
          for the delivery of the Services to a Customer that is generated through
          Lula’s Applications. A “
          <b>Job Request</b>
          ” means a request for a Job
          that is (a) created by a Customer and listed through the Applications;
          (b) available for you to claim or accept through the Applications
          (including being within the permitted physical geographical proximity to
          your Personnel using the Applications at the time such request is issued
          by the Customer). An “
          <b>Accepted Job Request</b>
          ” means a Job Request
          that has been accepted by you or your Personnel in the manner permitted
          by the Applications. “
          <b>Personnel</b>
          ” means you, your employees, your
          contractors, and your owners (as applicable).
          {' '}
        </p>
        <p>
          The selection of Job Requests will be made at your sole discretion. You
          are not obligated to accept Job Requests; however, once you accept a Job
          Request, you are obligated to perform the Job according to the terms of
          this SLA.
          {' '}
        </p>
        <p>
          Lula is not rendering any professional opinions as to the sufficiency or
          appropriateness of any Services that you may provide to any Customer.
          You shall actively supervise all Personnel providing the Services (or
          Additional Services, if applicable) and shall promptly take remedial
          action with respect to any such Personnel upon your discovery of facts
          or circumstances that could give rise to damages or liability for Lula,
          its Customers, or its other third-party providers of Services (“
          <b>Service Providers</b>
          ”).
        </p>

        <p>
          Once you are onsite, you may provide quotes or bids to a Customer for
          services that are additional to the services the Customer requested
          within the Job Request (“
          <b>Additional Services</b>
          ”); provided, that, if the Additional Services are a type of services
          included in the Services, otherwise offered by Lula, or a planned
          expansion of the Services, (a) you must first contact Lula before
          delivering any such quote or bid; (b) Lula reserves the sole right to
          approve or deny any such Additional Services; (c) the Customer’s payment
          for and receipt of the Additional Services shall be conducted solely
          through the Applications (or other means approved by Lula) and is
          subject to this SLA; and (d) Lula shall be deemed to be the customer of
          such Additional Services for the purposes of payments made for such
          Additional Services. In the event that the Additional Services are not
          the type of services included within the Services or are not facilitated
          through the Applications, (aa) such Additional Services provided shall
          be provided solely by you in your individual capacity and not as an
          agent, Service Provider, or affiliate of Lula and (bb) LULA EXPRESSLY
          DISCLAIMS, AND, TO THE FULLEST EXTENT PERMITTED BY LAW, YOU HEREBY
          WAIVE, RELEASE, AND SHALL INDEMNIFY AND HOLD LULA HARMLESS FROM AND
          AGAINST ANY AND ALL LIABILITY, COSTS, DAMAGES, PENALTIES, ASSESSMENTS,
          THIRD PARTY CLAIMS, WARRANTIES, TAXES, OR OBLIGATIONS OF ANY KIND
          ARISING OR RESULTING FROM THE DELIVERY OF SUCH ADDITIONAL SERVICES.
          {' '}
        </p>
        <p>
          After you have provided the Services, Customers will have the
          opportunity to rate your delivery of the Services and leave additional
          feedback about you. By using the Applications and in exchange for the
          other consideration provided to you hereunder, you consent to receiving
          such feedback and to Lula’s modification, retention, or deletion of such
          feedback in Lulu’s sole discretion. You agree that under no
          circumstances will you or your Personnel contact the Customer or
          otherwise respond to a Customer regarding his or her feedback.
        </p>
        <u>
          <b>Arrival Times and Performance</b>
        </u>
        <p>
          You must arrive at each Job location of an Accepted Job Request, and
          Lula must receive confirmation of your arrival, within the time frame
          requested by the Customer for the Job Request (in each case, the “
          <b>Arrival Time</b>
          ”). All Accepted Job Requests must be completed by you within a
          reasonable time of arrival and as designated by Lula (if so designated).
          {' '}
        </p>
        <p>
          If you (a) cancel an Accepted Job Request within 24 hours of the Arrival
          Time (or within the same day the Accepted Job Request is to be
          performed), or (b) do not arrive at an Accepted Job Request by the
          Arrival Time, then you shall pay to Lula a $35 cancellation fee (the “
          <b>Cancellation Fee</b>
          ”) on the same day as such cancellation or no-show. Lula may adjust or
          waive the Cancellation Fee at any time at its discretion, with or
          without notice to you and without impairing its rights.
        </p>
        <p>
          If you arrive at the Job location of an Accepted Job Request that has
          been confirmed by Lula and you are unable to complete the Accepted Job
          Request within the scheduled Job time due to the failure of a Customer
          or its tenants or customers to have an individual present within a
          reasonable time of the scheduled Job arrival time (as designated by
          Lula) that results in you being unable to access areas of the Job for
          which Services are to be provided, Lula shall reserve the option to pay
          to you a one-time fee of $50 at its sole discretion.
        </p>
        <u>
          <b>Fees &amp; Set Off</b>
        </u>
        <p>
          You understand that all fees payable by a Customer for the performance
          of Services for an Accepted Job Request will be as set forth by Lula in
          the Job Request confirmation (&ldquo;
          <b>Fees</b>
          &rdquo;) and belong solely to Lula. To the extent that you retain any
          ownership interest in the Fees, you hereby assign your rights in the
          Fees to Lula. Lula reserves the right to increase, adjust, and/or waive
          the Fees at any time at its sole discretion, with or without notice to
          you. By accepting any Job Request, you agree to not contest any of the
          Fees established by Lula for the Job and acknowledge that you have no
          rights or interest in the Fees or Transaction Costs (defined below).
          Transaction Costs shall be deemed fully earned by Lula upon a Customer’s
          submission of a Job Request. After Lula receives confirmation that you
          have completed the Job, Lula will pay to you an amount equal to 85%-90%
          of the Fees payable by the Customer for the completed Job (the “
          <b>Service Fee</b>
          ”) within two (2) to three (3) business days following Lula’s
          confirmation of your completion of the Job; provided, that payment of
          Service Fees for the first Job you complete shall be paid no sooner than
          five (5) days following completion of the Job. Lula may adjust the
          Service Fee at any time with or without notice to you. Fees will be
          inclusive of applicable taxes where required by law and may be adjusted
          as follows:
          {' '}
        </p>

        <ul>
          <li>
            <p>
              Once you are onsite at a Job, you may request that Lula adjust the
              Fees based on your good faith determination that the actual Job
              exceeds the project scope provided to you in the Job Request. If
              Lula, in our sole discretion, approves your Fee adjustment, Lula
              will (a) provide written confirmation of the Fee adjustment to you;
              (b) adjust the Fees within the Job Request; and (c) pay you the
              adjusted Service Fees upon Lula’s notification that you have
              completed the Job. If Lula does not approve your Fee adjustment, the
              Fees shall remain unchanged. However, if the ticket for an Accepted
              Job Request has a “not to exceed” amount listed, and your requested
              Fee increase does not exceed the “not to exceed” amount, Lula’s
              prior approval will not be required, so long as you report the
              additional Fee amount to Lula and such additional amounts are
              collected and remitted solely by Lula.
            </p>
          </li>
          <li>
            <p>
              Lula reserves the right to establish, remove, and/or adjust the Fees
              and Service Fees for any or all Services at any time in Lula’s sole
              discretion. You acknowledge and agree that Fees and Service Fees
              applicable in certain geographical areas may fluctuate.
              {' '}
            </p>
          </li>
          <li>
            <p>
              Lula may from time to time provide certain users with promotional
              offers and discounts that may result in different amounts charged
              for the same or similar services or goods obtained through the
              Applications. Lula may apply such promotional offers and discounts
              to the Fees without notice to you and you agree that such
              promotional offers and discounts shall have no bearing on your
              delivery of the Services (and that your Service Fees will be
              proportionally adjusted).
            </p>
          </li>
          <li>
            <p>
              Lula may set off against and deduct from any amounts owing to you,
              the full amount of any Transaction Fees, Cancellation Fees,
              Application subscription fees, and costs, expenses, losses, or
              liabilities incurred by Lula as a result of your or your Personnel’s
              performance during, or failure to perform, a Job Request.
              {' '}
            </p>
          </li>
        </ul>
        <p>
          The Service Fees payable to you shall not include and Lula reserves the
          right to deduct from the total amount payable by a Customer for an
          Accepted Job Request, all Transaction Fees. “
          <b>Transaction Fees</b>
          ”
          means (a) 12% of the Fees payable by a Customer for Services
          performed by you for a completed Accepted Job Request, and (b) any
          additional transaction, service, or surcharge fees charged by Lula to a
          Customer in addition to the quoted Fees for an Accepted Job Request. The
          Transaction Fees may be updated, changed, or modified from time to time
          by Lula.
          {' '}
        </p>

        <p>
          Any subscription fees that Lula charges for your use of the Applications
          are payable to Lula by the first day of each calendar month. Upon
          creation of your account with the Applications, you agree to provide a
          credit card and/or bank account with routing information for payment of
          any fees payable to Lula and authorize Lula and its third party service
          providers (including Stripe) to make a one-time debit and credit to your
          account for the purpose of authorizing and verifying such payment
          method. By accepting a Job Request, you also authorize Lula and its
          third party service providers (including Stripe) to automatically charge
          your credit card and/or bank account associated with your account for
          the full amount of the Fees.
          {' '}
        </p>

        <p>
          All payments and payment information are/is processed through third
          party solution providers (such as Stripe) and Lula disclaims any and all
          liability with respect to the safeguarding, storing, processing, or
          transmitting your payment information including credit card numbers,
          bank account and routing numbers, and other financial data that you
          provide to us to facilitate the payment of the Fees, Transaction Fees,
          or subscription amounts (if any) for the Applications.
        </p>
        <p>
          You shall not initiate, and hereby waive, all rights to initiate any
          collection action or to take any action with respect to the filing of
          mechanics, materialman’s, or any other type of lien against a Customer,
          its tenants, its customers, or their property for the payment of any
          amounts. Lula retains all rights to initiate any such collection
          actions.
          {' '}
        </p>
        <u>
          <b>Payment Information; Third Parties</b>
        </u>
        <p>
          The Applications may be made available or accessed in connection with
          third party services and content (including payment processing) provided
          by third parties (including Stripe) that Lula does not control. You
          acknowledge that different terms of use and privacy policies may apply
          to your use of such third party services and that Lula does not endorse
          such third party services and content and in no event shall Lula be
          responsible or liable for any products or services of such third
          parties. Additionally, these third parties are not parties to this
          contract and are not responsible for the provision or support of the
          Applications or Services in any manner. Your access to the Applications
          and receipt of the Service Fees using these third party’s devices and
          payment solutions is subject to the terms set forth in their terms of
          service and privacy policies. By using the Applications, you are
          agreeing to Lula’s use of these third parties (including Stripe) and
          their solutions (including payment solutions) and hereby release Lula,
          to the fullest extent permitted by law, from any and all liabilities,
          costs, expenses, warranties, claims, and obligations of any kind
          whatsoever with respect to these third party solutions.
          {' '}
        </p>
        <u>
          <b>Data</b>
        </u>
        <p>
          As part of your delivery of Services, certain data may be obtained by or
          entered into the Applications about a Job Request, the Customer, the
          Customer’s property, and the Customer’s tenants (the “
          <b>Job Data</b>
          ”). To the extent you or your Personnel retain any rights to Job Data,
          you (on behalf of yourself and your Personnel) hereby fully and
          irrevocably assign all of your right, title, and interest in and to such
          Job Data exclusively to Lula. All Job Data shall remain the sole and
          exclusive property of Lula and its Customers and you agree to execute
          any additional consents, assignments, or licenses requested by Lula to
          fully vest title to the same in Lula; provided, that, subject to the
          section titled “Confidentiality” below, Lula hereby provides you with a
          limited, revocable, non-sublicensable, and non-distributable license to
          access the Job Data to the extent necessary for you and your Personnel
          to complete the Job in the manner contemplated by the Job Request.
          {' '}
        </p>
        <u>
          <b>Intellectual Property</b>
        </u>
        <p>
          All intellectual property rights, including copyrights, patents, patent
          disclosures and inventions (whether patentable or not), trademarks
          service marks, trade secrets, know-how and other confidential
          information, trade dress, trade names, logos, corporate names and domain
          names, together with all of the goodwill associated therewith,
          derivative works and all other rights (collectively, &ldquo;
          <b>Intellectual Property Rights</b>
          &rdquo;) in and to all documents, work product and other materials that are
          delivered to you under this Agreement, including the Applications or
          materials delivered to you pursuant to the Applications or developed
          pursuant to the delivery of the Services shall be owned by Lula. Lula
          hereby reserves any and all rights not expressly provided for within
          this SLA.
          {' '}
        </p>
        <u>
          <b>Confidentiality</b>
        </u>
        <p>
          Lula may disclose or make available to you non-public proprietary and
          confidential information of Lula, our Service Providers, our Customers,
          and their affiliates (including personal information of such
          individuals, Lula’s Intellectual Property Rights, the Job Data, and
          trade secrets) (&ldquo;
          <b>Confidential Information</b>
          &rdquo;). You shall: (a) protect and safeguard the confidentiality of the
          Confidential Information with at least the same degree of care as you
          would protect your own Confidential Information, but in no event with
          less than a commercially reasonable degree of care; (b) not use the
          Confidential Information, or permit it to be accessed or used, for any
          purpose other than to perform your obligations under this Agreement for
          the delivery of the Services; and (c) not disclose any such Confidential
          Information to any person or entity, except to the your Personnel who
          need to know the Confidential Information to perform the Services. Lula
          retains all right, title, and interest in and to the Confidential
          Information. If you are required by applicable law or legal process to
          disclose any Confidential Information, you shall, prior to making such
          disclosure, use commercially reasonable efforts to notify Lula of such
          requirements to afford Lula the opportunity to seek, at Lula&lsquo;s sole cost
          and expense, a protective order or other remedy.
          {' '}
        </p>
        <u>
          <b>Location Services; Personal Information; APIs</b>
          {' '}
        </u>
        <p>
          You hereby expressly consent to our use of location-based services and
          expressly waive and release Lula, to the fullest extent permitted by
          law, from any and all liability, claims, causes of action or damages
          arising from your use of the Applications, or in any way relating to the
          precise location and other location-based services. You consent to,
          recognize, and agree that your and your Personnel’s location services
          will be provided to us and our Customers. You also expressly consent to
          Lula collecting personal information from or about you (including
          financial data) when you create an account for, and use, the
          Applications or provide the Services. By submitting information to Lula
          during the account creation process and/or by using the Applications,
          you consent to such collection and use of your personal data. You
          authorize and provide to Lula a fully paid, non-transferable, and
          limited license to utilize the Applications to interface with one or
          more of your application programming interfaces with respect to your
          Personnel fleet management software systems (“
          <b>Fleet Systems</b>
          ”) in order to provide data integration services between the
          Applications and your Fleet Systems. All updates, modifications, or
          integrations with your Fleet Systems are subject solely to your own
          internal change control process and are not the responsibility of Lula.
          You hereby release, discharge, and waive, to the fullest extent
          permitted by law, any and all claims, liabilities, damages, or other
          costs or obligations of or against Lula that may arise as a result of
          the integration between the Applications and the Fleet Systems.
        </p>
        <u>
          <b>Warranties</b>
        </u>
        <p>
          You represent and warrant that the Services shall be provided in a
          timely, workmanlike, and professional manner, using Personnel of
          commercially reasonable skill, experience, and licensure at the times
          required under each Accepted Job Request. In the event that you fail to
          meet the foregoing service warranty (as determined in good faith by Lula
          at Lula’s discretion), you agree to either, at Lula’s direction, (a)
          provide replacement Services for the failed Services using new
          Personnel, or (b) to pay and reimburse Lula for the procurement and
          delivery of replacement Services for the failed Services, using any
          third party service provider of Lula’s choice.
          {' '}
        </p>
        <p>
          You acknowledge and agree that we do not guarantee or warrant that files
          available for downloading from the internet or the Applications will be
          free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our
          Applications for any reconstruction of any lost data. WE WILL NOT BE
          LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
          ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
          INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, MOBILE DEVICE, DATA,
          OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE APPLICATIONS OR ANY
          ITEMS OBTAINED THROUGH THE APPLICATIONS OR TO YOUR DOWNLOADING OF ANY
          MATERIAL POSTED ON THEM, OR ON ANY WEBSITE LINKED TO THEM.
        </p>
        <p>
          YOUR USE OF THE APPLICATIONS, THEIR CONTENT, AND OTHER ITEMS OBTAINED
          THROUGH THE APPLICATIONS (INCLUDING YOUR USERNAME, PASSWORD, AND OTHER
          PERSONAL INFORMATION) IS AT YOUR OWN RISK. THE APPLICATIONS, THEIR
          CONTENT, AND ANY OTHER ITEMS OBTAINED THROUGH THE APPLICATIONS ARE
          PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS,
          WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
          LULA NOR ANY PERSON ASSOCIATED WITH LULA MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
          QUALITY, ACCURACY, OR AVAILABILITY OF THE APPLICATIONS. WITHOUT LIMITING
          THE FOREGOING, NEITHER LULA NOR ANYONE ASSOCIATED WITH LULA REPRESENTS
          OR WARRANTS THAT THE APPLICATIONS, THEIR CONTENT, OR ANY ITEMS OBTAINED
          THROUGH THE APPLICATIONS WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
          UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE APPLICATIONS, OR
          THE SERVER THAT MAKES THEM AVAILABLE, ARE FREE OF VIRUSES OR OTHER
          HARMFUL COMPONENTS, OR THAT THE APPLICATIONS OR ANY ITEMS OBTAINED
          THROUGH THE APPLICATIONS WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          {' '}
        </p>
        <p>
          BY USING THE APPLICATIONS, THEIR CONTENT, AND ANY OTHER ITEMS OBTAINED
          THROUGH THE APPLICATIONS, YOU REPRESENT AND WARRANT TO LULA THAT THE
          SERVICES AND ADDITIONAL SERVICES SHALL BE PROVIDED (a) IN A PROFESSIONAL
          AND WORKMANLIKE MANNER, USING PERSONNEL OF PROPER LICENSURE, SKILL,
          EXPERIENCE, AND ACCREDITATION; (b) IN ACCORDANCE WITH GENERALL
          RECOGNIZED INDUSTRY STANDARDS AND ALL APPLICABLE LAWS; (c) ONLY AT
          PORTIONS OF THE CUSTOMER’S PREMISES THAT ARE STRICTLY NECESSARY TO
          COMPLETE THE SERVICES; AND (d) FREE AND CLEAR OF ANY LIEN WHATSOEVER,
          INCLUDING MECHANIC OR MATERIAL LIENS OR SECURITY INTERESTS OF ANY KIND
          (INCLUDING WITH RESPECT TO ANY GOODS DELIVERED IN CONNECTION WITH THE
          SERVICES OR ADDITIONAL SERVICES) WITH RESPECT TO ANY CUSTOMER, ITS
          PROPERTY, OR PREMISES.
        </p>
        <p>
          LULA MAKES NO WARRANTIES TO YOU AND HEREBY DISCLAIMS ALL WARRANTIES OF
          ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING
          ANY (a) WARRANTY OF MERCHANTABILITY; (b) WARRANTY OF FITNESS FOR A
          PARTICULAR PURPOSE; (c) WARRANTY OF TITLE; OR (d) WARRANTY AGAINST
          INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER
          EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE,
          USAGE OF TRADE, OR OTHERWISE. THE FOREGOING DOES NOT AFFECT ANY
          WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <u>
          <b>Limitation of Liability</b>
        </u>
        <p>
          IN NO EVENT SHALL LULA, ITS LICENSORS, OTHER SERVICE PROVIDERS,
          EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY
          KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR
          USE, OR INABILITY TO USE, THE APPLICATIONS, ANY WEBSITES LINKED TO THEM,
          ANY CONTENT ON THE APPLICATIONS OR SUCH OTHER WEBSITES OR ANY ITEMS
          OBTAINED THROUGH THE APPLICATIONS OR SUCH OTHER WEBSITES, OR FOR ANY
          LOSS, CORRUPTION, OR THEFT OF FINANCIAL DATA ENTERED INTO THE
          APPLICATIONS OR ANY THIRD PARTY PAYMENT SOLUTION (INCLUDING SOLUTIONS
          MAINTAINED BY STRIPE), INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT
          LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
          LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
          SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED
          BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
          FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
          EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL LULA OR ITS
          AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR OTHER SERVICE
          PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR
          INABILITY TO USE THE APPLICATIONS OR THEIR CONTENT AND YOUR DELIVERY OF
          THE SERVICES OR ADDITIONAL SERVICES FOR (a) PERSONAL INJURY, PROPERTY
          DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF
          DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR
          MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT,
          EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES; OR (b) DIRECT DAMAGES IN
          AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAYABLE BY LULA
          TO YOU FOR SERVICES RENDERED. THE FOREGOING LIMITATIONS WILL APPLY
          WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING
          NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE
          FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME
          OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
          {' '}
        </p>
        <u>
          <b>Indemnification</b>
        </u>
        <p>
          To the full extent permitted by law, you agree to defend, indemnify, and
          hold harmless Lula, its affiliates, licensors, and other Service
          Providers, and its and their respective officers, directors, employees,
          contractors, agents, licensors, suppliers, successors, and assigns from
          and against any claims (including any claims for injury to person or
          property), liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys&lsquo; fees and the
          costs of pursuing any insurance providers) arising out of or relating to
          (a) your violation or breach of this SLA, including your failure to
          comply with the Background Check Policy; (b) your Personnel’s or your
          use of the Applications; (c) your Personnel’s or your use of Customer
          information and other Job Data obtained through the Applications; (d)
          the delivery of Services or Additional Services by you or your
          Personnel; and (e) the commission of a crime or the negligent conduct by
          you or your Personnel.
          {' '}
        </p>
        <u>
          <b>Term, Suspension, and Termination</b>
        </u>
        <p>
          Subject to Lula’s approval of you as a service provider for the
          Applications, as set forth above in this SLA, the term of this SLA
          commences when you download, install, or create a user account with the
          Applications and will continue in effect until terminated by you or Lula
          as set forth in this section.
          {' '}
        </p>
        <p>
          You may terminate this SLA by deleting the Applications and all copies
          thereof from your mobile devices. Lula may temporarily suspend your
          access (or one or more of your Personnel’s access) to the Applications
          and Job Requests at any time in its sole discretion and for any reason,
          including poor performance or workmanship in providing Services, failing
          to arrive at a job by the scheduled time, or for negative customer
          feedback. Lula may terminate this SLA with respect to you at any time
          and in its sole discretion without notice to you for any reason,
          including (a) poor performance or workmanship in providing the Services;
          (b) failing to arrive at a job by the scheduled time, (c) for negative
          customer feedback; (d) if Lula ceases to support the Applications (which
          Lula may do in its sole discretion); or (e) you breach a term of this
          SLA. Lula reserves the right to remotely disable the Applications on
          your and your Personnel’s mobile devices upon suspension or termination
          of the SLA.
        </p>
        <p>
          Upon termination of this SLA, all rights granted to you under this SLA
          will terminate; and you must cease all use of the Application and
          delivery of Services and delete all copies of the Application from your
          mobile device and account; provided, that, you complete any Accepted Job
          Requests (and all Services delivered pursuant to such Accepted Job
          Requests shall be subject to the terms of this SLA) and, provided
          further, that, Lula reserves the right to immediately terminate this
          SLA, notwithstanding any Accepted Job Requests, without liability to
          you, in its sole discretion. Suspension or termination of this SLA or
          access to the Applications will not limit any of Lula&lsquo;s rights or
          remedies at law or in equity. Any violation of Lula’s Terms and
          Conditions, Background Check Policy, or Privacy Policy by you are deemed
          a breach of this SLA.
        </p>
        <p>
          The sections of this SLA entitled “Data”, “Intellectual Property”,
          “Confidentiality”, “Indemnification”, “Warranties”, “Limitation of
          Liability”, “Indemnification”, “Term, Suspension, and Termination”,
          “Governing Law and Jurisdiction”, “Waiver and Severability”, “Entire
          Agreement”, “Cumulative Remedies”, “General”, and “Amendment”, as well
          as any provision of this SLA that, in order to give proper effect to its
          intent, should survive expiration or termination, will survive the
          expiration or earlier termination of this Agreement.
          {' '}
        </p>
        <u>
          <b>Governing Law and Jurisdiction</b>
        </u>
        <p>
          All matters relating to the Services and this SLA and any dispute or
          claim arising therefrom or related thereto (in each case, including
          non-contractual disputes or claims), shall be governed by and construed
          in accordance with the internal laws of the State of Kansas without
          giving effect to any choice or conflict of law provision or rule
          (whether of the State of Kansas or any other jurisdiction). Any legal
          suit, action, or proceeding arising out of, or related to, this SLA or
          the Services shall be instituted exclusively in the federal courts of
          the United States or the courts of the State of Kansas, in each case
          located in Kansas City, Kansas, although we retain the right to bring
          any suit, action, or proceeding against you for breach of this SLA in
          your country of residence or any other relevant country and also retain
          the right to submit all claims to binding arbitration located in Kansas
          City, Kansas. You waive any and all objections to the exercise of
          jurisdiction and venue over you by such courts or arbitration panels.
        </p>
        <p>
          TO THE FULL EXTEND PERMITTED BY LAW, ANY CAUSE OF ACTION OR CLAIM YOU
          MAY HAVE ARISING OUT OF OR RELATING TO THE SERVICES OR THIS SLA MUST BE
          COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
          OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <u>
          <b>Waiver and Severability</b>
        </u>
        <p>
          No waiver of by Lula of any term or condition set forth in this SLA
          shall be deemed a further or continuing waiver of such term or condition
          or a waiver of any other term or condition, and any failure of Lula to
          assert a right or provision under this SLA shall not constitute a waiver
          of such right or provision. If any provision of this SLA is held by a
          court or other tribunal of competent jurisdiction to be invalid, illegal
          or unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of this
          SLA will continue in full force and effect.
          {' '}
        </p>
        <u>
          <b>Entire Agreement</b>
        </u>
        <p>
          This SLA, the Terms of Use, and our Privacy Policy, constitute the sole
          and entire agreement between you and Lula with respect to the Services
          and supersede all prior and contemporaneous understandings, agreements,
          representations, and warranties, both written and oral, with respect to
          the Services. In the event that there is any inconsistency between
          statements in this SLA, the Terms of Use, and the Privacy Policy, then
          the statements in this SLA will control.
          {' '}
        </p>
        <u>
          <b>Cumulative Remedies</b>
        </u>
        <p>
          All rights and remedies provided hereunder for Lula are cumulative and
          not exclusive, and the exercise by Lula of any right or remedy does not
          preclude the exercise of any other rights or remedies that may now or
          subsequently be available to Lula at law, in equity, by statute, in any
          other agreement between the parties or otherwise.
        </p>
        <u>
          <b>General</b>
        </u>
        <p>
          You may not assign this SLA without Lula’s prior written approval. Lula
          may assign this SLA without your consent to: (a) a subsidiary or
          affiliate; (b) an acquirer of Lula’s equity, business or assets; or (c)
          a successor by merger. Any purported assignment in violation of this
          section shall be void. No joint venture, partnership, employment, or
          agency relationship exists between you, Lula or any other Service
          Provider, Customer, or any other person as a result of this SLA or use
          of the Services. At no times shall you be deemed to be the employee or
          agent of Lula. Neither you nor your employees or agents will be eligible
          for any employee benefits from Lula. You shall at all times be
          responsible for payment of all taxes, FICA, federal and state
          unemployment insurance contributions, state disability premiums, and all
          similar taxes and fees relating to fees paid to you and your employees
          and agents under this SLA. Lula&lsquo;s failure to enforce any right or
          provision in this SLA shall not constitute a waiver of such right or
          provision unless acknowledged and agreed to by Lula in writing. Boldface
          headings in this SLA are for convenience only and shall not be
          considered in construing or interpreting any of the terms or provisions
          hereof.
        </p>
        <u>
          <b>Amendment</b>
        </u>
        <p>
          Lula reserves the right to amend this SLA, the Terms of Use, the Privacy
          Policy, and the Background Check Policy from time to time. Your
          continued use of the Applications or provision of Services after such
          amendment constitutes your acceptance of the SLA, the Terms of Use, the
          Privacy Policy, and the Background Check Policy, as they may be amended.
        </p>
      </div>
    </ModalBody>
  </Modal>
);

export default SlaAgreementModal;
