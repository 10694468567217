import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import ScrollToTop from './components/shared/scroll-to-top';

import App from './App';
import { ProfileProvider } from './context/profile-context/profile-context';
import 'react-toastify/dist/ReactToastify.css';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Firefox extensions
      /^resource:\/\//i,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  Sentry.setTag('platform', 'lula-pros-frontend');
}
ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <ProfileProvider>
      <App />
      <ToastContainer
        position="bottom-right"
        theme="colored"
        className="react-toast-container"
      />
    </ProfileProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
