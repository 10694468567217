import React from 'react';

const ServicesOffered = (props) => (
  <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.1966 25.0006C17.4734 25.0006 16.0716 23.5988 16.0716 21.8756V10.1497C14.4065 9.08811 13.3931 7.26222 13.3931 5.26312C13.3931 3.07562 14.6056 1.09704 16.5574 0.0979368C16.835 -0.0431346 17.1654 -0.0315275 17.4306 0.130973C17.6957 0.294365 17.8574 0.581865 17.8574 0.893473V4.75329L19.1966 5.28901L20.5359 4.75329V0.893473C20.5359 0.581865 20.6975 0.294365 20.9627 0.130973C21.2279 -0.0315275 21.5582 -0.0431346 21.8359 0.0979368C23.7877 1.09794 25.0002 3.07562 25.0002 5.26401C25.0002 7.26222 23.9868 9.08812 22.3216 10.1506V21.8756C22.3216 23.5988 20.9199 25.0006 19.1966 25.0006ZM16.0716 2.73812C15.5029 3.43901 15.1788 4.32294 15.1788 5.26312C15.1788 6.77562 16.019 8.14615 17.3716 8.83901C17.6699 8.99169 17.8574 9.29883 17.8574 9.63365V21.8756C17.8574 22.614 18.4582 23.2149 19.1966 23.2149C19.935 23.2149 20.5359 22.614 20.5359 21.8756V9.63365C20.5359 9.29883 20.7234 8.99169 21.0216 8.83901C22.3743 8.14615 23.2145 6.77651 23.2145 5.26401C23.2145 4.32383 22.8904 3.4399 22.3216 2.73901V5.35776C22.3216 5.72294 22.0993 6.05062 21.7609 6.18722L19.5288 7.08008C19.3154 7.16579 19.0788 7.16579 18.8654 7.08008L16.6332 6.18722C16.294 6.05062 16.0716 5.72294 16.0716 5.35776V2.73812Z" fill="white" />
    <path d="M13.4589 25.0005H11.542C10.6143 25.0005 9.83214 24.2764 9.76161 23.3523L9.64911 21.8925C9.03661 21.7067 8.44375 21.4603 7.87679 21.1567L6.76429 22.1094C6.05982 22.7139 4.99554 22.6719 4.34018 22.0175L2.98304 20.6594C2.32768 20.0041 2.28661 18.9389 2.89018 18.2344L3.84286 17.1228C3.53929 16.5558 3.29286 15.963 3.10714 15.3505L1.64821 15.2389C0.724107 15.1692 0 14.3871 0 13.4594V11.5416C0 10.6139 0.724107 9.83174 1.64911 9.7612L3.10804 9.6496C3.29375 9.0362 3.54018 8.44335 3.84375 7.87727L2.89018 6.76388C2.28661 6.06031 2.32768 4.99602 2.98393 4.33888L4.33929 2.98263C4.99554 2.32638 6.06071 2.2862 6.76429 2.88977L7.87679 3.84245C8.44375 3.53888 9.03661 3.29245 9.64911 3.10674L9.76161 1.64692C9.83214 0.724595 10.6143 0.000488281 11.5411 0.000488281H12.5C12.9929 0.000488281 13.3929 0.399595 13.3929 0.893345C13.3929 1.3871 12.9929 1.7862 12.5 1.7862H11.5411L11.3804 3.87638C11.35 4.26567 11.0705 4.58977 10.6911 4.67727C9.82768 4.87638 9.00625 5.21656 8.25 5.69067C7.92054 5.8987 7.49286 5.86745 7.19554 5.6112L5.60179 4.24602L4.24643 5.60317L5.61161 7.19602C5.86607 7.49245 5.89732 7.92013 5.69018 8.25049C5.21696 9.00763 4.87589 9.82906 4.67679 10.6916C4.58929 11.0719 4.26429 11.3514 3.875 11.3808L1.78571 11.5416V13.4594L3.875 13.6201C4.26429 13.6496 4.58929 13.93 4.67679 14.3094C4.87589 15.1728 5.21607 15.9942 5.69018 16.7505C5.89732 17.0808 5.86518 17.5085 5.61161 17.805L4.24643 19.3978L5.60268 20.755L7.19554 19.3898C7.49286 19.1344 7.92054 19.1032 8.25 19.3103C9.00714 19.7835 9.82857 20.1246 10.6911 20.3237C11.0714 20.4121 11.3509 20.7362 11.3804 21.1246L11.5411 23.2148H13.458C13.4955 22.7237 13.9232 22.3567 14.417 22.3933C14.9089 22.4308 15.2768 22.8603 15.2384 23.3523C15.1679 24.2764 14.3857 25.0005 13.4589 25.0005Z" fill="white" />
    <path d="M6.25 12.5008C6.25 9.43476 8.44107 6.8428 11.4598 6.33744C11.9446 6.25084 12.4071 6.58387 12.4875 7.07048C12.5688 7.55709 12.2411 8.01691 11.7545 8.09816C9.6 8.45977 8.03571 10.3116 8.03571 12.5008C8.03571 15.5839 11.0982 17.7339 13.9884 16.7115C14.4509 16.5464 14.9634 16.7892 15.1286 17.2544C15.2929 17.7196 15.05 18.2294 14.5848 18.3937C10.5196 19.8321 6.25 16.8008 6.25 12.5008Z" fill="white" />
  </svg>
);
export default ServicesOffered;
