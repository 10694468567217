import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profileDetails, setProfileDetails] = useState({});

  const value = useMemo(
    () => ({
      setProfileDetails, profileDetails,
    }),
    [profileDetails, setProfileDetails],
  );
  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => {
  const {
    setProfileDetails, profileDetails,
  } = useContext(ProfileContext);
  return {
    setProfileDetails, profileDetails,
  };
};
