import React from 'react';

import { useFormContext } from 'react-hook-form';
import AnswerField from './components/answer-field';

const ServiceQuestions = ({ questions }) => {
  const { watch } = useFormContext();
  const formData = watch();
  return (questions?.map((question) => {
    const dependedField = questions?.filter((item) => item?.slug === `${question?.slug}-later`);
    const key = `question_${dependedField[0]?.service_question_id}`;
    if (formData?.questions?.[key]) {
      return '';
    }
    return (
      <AnswerField
        key={`qtn_${question?.service_question_id}`}
        name={`questions.question_${question?.service_question_id}`}
        question={question}
      />
    );
  }));
};

export default ServiceQuestions;
