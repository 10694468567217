import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
} from 'reactstrap';
import catchHandler from '../../../helpers/catch-handler';
import { convertArrayToObject, scrollToError } from '../../../helpers/utils';
import { getCompetencyTest, saveCompetencyTest } from '../../../services/pro-signup-services';
import InputField from '../../shared/form-fields/input-field';
import CustomRadioArray from '../../shared/form-fields/custom-radio-array';
import useCompetencyTest from './hooks/use-competency-test';
import { fetchProfile } from '../profile';
import { getStepCount } from '../../wizard/progress';
import { useProfile } from '../../../context/profile-context/profile-context';

const CompetencyTest = () => {
  const [fetchingDetails, setFetchingDetails] = useState(false); // used for show loading state
  const [dataSource, setDataSource] = useState([]); // used for store GET response data
  const [savingDetails, setSavingDetails] = useState(false); // used for show button loading

  const [questionsSchema] = useCompetencyTest(dataSource?.questionsData);

  const setDefaultValues = (data) => data?.formatDefaultValues || {};

  const navigate = useNavigate();
  const location = useLocation();

  const methods = useForm({
    mode: 'all',
    defaultValues: setDefaultValues(),
    resolver: yupResolver(questionsSchema),
  });
  const {
    reset,
    setError,
  } = methods;

  const { setProfileDetails } = useProfile();

  // fetching data source
  useEffect(() => {
    setFetchingDetails(true);
    getCompetencyTest().then((res) => {
      setFetchingDetails(false);
      if (!isEmpty(res?.data?.provider_service_categories)) {
        const formatDefaultValues = {};
        const questionsData = {};
        const defaultValuesOfQuestionAnswers = convertArrayToObject(res?.data?.provider_competency_test_answers, 'service_question_id');
        res?.data?.provider_service_categories?.forEach((item) => {
          item?.service_questions?.forEach((obj) => {
            if (!isEmpty(defaultValuesOfQuestionAnswers?.[obj?.service_question_id])) {
              formatDefaultValues[`question_${obj?.service_question_id}`] = defaultValuesOfQuestionAnswers?.[obj?.service_question_id]?.answer;
            }
            questionsData[`question_${obj?.service_question_id}`] = { ...obj, service_category_id: item?.service_category_id };
          });
        });
        setDataSource({
          ...res?.data,
          formatDefaultValues,
          questionsData,
          provider_competency_test_answers: defaultValuesOfQuestionAnswers,
        });
      } else {
        navigate('/signup/service-pricing');
      }
    }).catch((err) => {
      catchHandler(err, setError);
      setFetchingDetails(false);
    });
  }, [setError, navigate]);

  // set defaultValues
  useEffect(() => {
    if (!isEmpty(dataSource)) {
      reset(setDefaultValues(dataSource));
    }
  }, [reset, dataSource]);

  // Form submission
  const onSubmit = (formData) => {
    setSavingDetails(true);
    const serviceCategories = dataSource?.provider_service_categories?.map((item) => {
      const serviceQuestionAnswers = [];
      item?.service_questions?.forEach((question) => {
        serviceQuestionAnswers.push({
          service_question_id: question?.service_question_id,
          answer: formData?.[`question_${question?.service_question_id}`],
        });
      });
      return {
        service_category_id: item?.service_category_id,
        service_question_answers: serviceQuestionAnswers,
      };
    });
    const payload = {
      service_categories: serviceCategories,
    };
    saveCompetencyTest(payload).then((res) => {
      fetchProfile(setSavingDetails, navigate, setProfileDetails, {
        current_step: getStepCount(location?.pathname) - 1,
      });
    }).catch((err) => {
      setSavingDetails(false);
      catchHandler(err, setError);
    });
  };

  // on button click scroll to error
  const onError = (formErrors) => {
    scrollToError(formErrors);
  };

  // return body
  return (
    <div>
      {fetchingDetails ? (
        <div className="page-loader" />
      ) : (
        <Container>
          <Row>
            <Col className="my-4">
              <Row>
                <Col xs="12">
                  <h3>Competency Test</h3>
                  <div className="header-divider" />
                  <p className="mt-3 mb-0">
                    It&apos;s important that we have pros that have the skill
                    and knowledge to successfully complete tasks in their
                    various trades. Please provide us answers to the below,
                    and note that answering any incorrectly doesn&apos;t
                    necessarily mean you won&apos;t be approved.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
              {dataSource?.provider_service_categories?.map((item) => (
                <Row key={`service_category_id_${item?.service_category_id}`}>
                  <Col xs="12">
                    <h3 className="competency-heading">{item?.service_categories}</h3>
                  </Col>

                  <Col xs="12">
                    {item?.service_questions?.map((question, i) => {
                      const questionId = question?.service_question_id;
                      const answerObj = dataSource?.provider_competency_test_answers[
                        questionId
                      ];

                      const isQuestionAlreadyAnswered = () => {
                        if (!isEmpty(answerObj)) {
                          return true;
                        }
                        return false;
                      };

                      return (
                        <Row key={`question_id_${question?.service_question_id}`}>
                          <Col xs="12">
                            <h4 className="test-question">
                              {i + 1}
                              .
                              {' '}
                              {question?.question}

                            </h4>
                          </Col>
                          <Col xs="10" className="custom-pl">
                            {isQuestionAlreadyAnswered() ? (
                              <div>
                                <strong>Your Answer: </strong>
                                {question?.type === 'radio' ? question?.extra?.options[answerObj?.answer] : ''}
                                {question?.type === 'textarea' ? answerObj?.answer : ''}
                                {question?.type === 'text' ? answerObj?.answer : ''}
                              </div>
                            ) : (
                              <>
                                {question?.type === 'radio' ? (
                                  <div className="competency-test">
                                    <Row>
                                      <CustomRadioArray
                                        type="radio"
                                        name={`question_${question?.service_question_id}`}
                                        options={Object.keys(
                                          question?.extra?.options ?? {},
                                        ).map((radioOption) => ({
                                          id: radioOption,
                                          label:
                                              question?.extra?.options[radioOption],
                                        }))}
                                        colProps={{ xs: '12', md: '6' }}
                                      />
                                    </Row>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {question?.type === 'textarea' ? (
                                  <FormGroup>
                                    <InputField
                                      name={`question_${question?.service_question_id}`}
                                      type="textarea"
                                      placeholder="Type your answer here..."
                                      className="textarea-lg"
                                    />
                                  </FormGroup>
                                ) : (
                                  ''
                                )}
                                <Row>
                                  <Col xs="5">
                                    {question?.type === 'text' ? (
                                      <FormGroup>
                                        <InputField
                                          name={`question_${question?.service_question_id}`}
                                          type="text"
                                          placeholder="Type your answer here..."
                                        />
                                      </FormGroup>
                                    ) : (
                                      ''
                                    )}
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              ))}
              <Row>
                <Col>
                  <div className="action-wrapper">
                    {dataSource?.is_competency_test_completed ? (
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          navigate('/signup/sla-agreement');
                        }}
                      >
                        Save & Continue
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        color="primary"
                        disabled={savingDetails}
                      >
                        {savingDetails ? (
                          <div className="loader-dual-ring" />
                        ) : (
                          'Save & Continue'
                        )}
                      </Button>
                    )}
                    <Button
                      color="link"
                      onClick={() => {
                        navigate('/signup/service-pricing');
                      }}
                    >
                      Previous Step
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </Container>
      )}
    </div>
  );
};

export default CompetencyTest;
