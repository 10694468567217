import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from 'reactstrap';
import { get } from 'lodash';

const InputField = (props) => {
  const {
    control,
    formState: { errors },
    clearErrors,
    trigger,
  } = useFormContext();

  const {
    children,
    hideFormError,
    name: propsName,
    clearError,
    clearAllErrors,
    shouldUnregister,
    triggerValidationOnChange,
    defaultValue = '',
    handleOnchangeValue,
    useFieldArrayErrorKey,
    ...inputProps
  } = props;

  const showUseFieldArrayApiErrorMessage = () => {
    if (useFieldArrayErrorKey) {
      const { message: errorMessage = false } = get(errors, useFieldArrayErrorKey, {});
      return <small className="text-danger">{errorMessage}</small>;
    }
    return '';
  };

  return (
    <>
      <Controller
        name={propsName}
        control={control}
        defaultValue={defaultValue}
        shouldUnregister={shouldUnregister}
        render={({ field }) => (
          <Input
            name={propsName}
            {...field}
            onBlur={field.onBlur}
            onChange={(event) => {
              if (handleOnchangeValue) {
                field.onChange(event.target.value);
              } else {
                field.onChange(event);
              }
              if (clearError) {
                clearErrors(propsName);
              }
              if (clearAllErrors) {
                clearErrors();
              }
              if (triggerValidationOnChange) {
                trigger();
              }
            }}
            {...inputProps}
          >
            {children}
          </Input>
        )}
      />
      {!hideFormError ? (
        <ErrorMessage
          errors={errors}
          name={propsName}
          render={({ message }) => (
            <small className="text-danger">{message}</small>
          )}
        />
      ) : (
        ''
      )}
      {showUseFieldArrayApiErrorMessage()}
    </>
  );
};

export default InputField;
