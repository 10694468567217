import React from 'react';
import YourInfo from '../components/signup/your-info';
import ServicesOffered from '../components/signup/service-offered/services-offered';
import CompetencyTest from '../components/signup/competency-test/competency-test';
import SlaAgreement from '../components/signup/sla-agreement/sla-agreement';
import SafetyPolicy from '../components/signup/safety-policy';
import DonePage from '../components/signup/done';
import CitiesServed from '../components/signup/cities-served/cities-served';
import ServicePricing from '../components/signup/service-pricing/service-pricing';

const portalRoutes = [
  {
    path: '/signup/your-info',
    element: <YourInfo />,
    key: 'your-info',
  },
  {
    path: '/signup/cities-served',
    element: <CitiesServed />,
    key: 'cities-served',
  },
  {
    path: '/signup/services-offered',
    element: <ServicesOffered />,
    key: 'services-offered',
  },
  {
    path: '/signup/service-pricing',
    element: <ServicePricing />,
    key: 'service-pricing',
  },
  {
    path: '/signup/competency-test',
    element: <CompetencyTest />,
    key: 'competency',
  },
  {
    path: '/signup/sla-agreement',
    element: <SlaAgreement />,
    key: 'sla-agreement',
  },
  {
    path: '/signup/safety-policy',
    element: <SafetyPolicy />,
    key: 'safety-policy',
  },
  {
    path: '/signup/done',
    element: <DonePage />,
    key: 'done',
  },
  {
    path: '/',
    to: '/profile',
    key: 'initial',
    redirect: true,
  },
  {
    redirect: true,
    path: '*',
    to: '/profile',
    key: 'redirect',
  },
];

export default portalRoutes;
