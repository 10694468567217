import React, { useState } from 'react';
import {
  Container, Row, Col, List,
} from 'reactstrap';
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
} from '../../assets/images/svg-icons';
import MakeAWishModal from './make-a-wish-modal';

const Footer = () => {
  const [showMakeAWishModal, setShowMakeAWishModal] = useState(false);
  return (
    <React.Fragment>
      <div className="main-footer">
        <Container>
          <Row lg="4" md="2" sm="2">
            <Col xs="12">
              <a href="https://lula.life/">
                <img
                  src="https://lula.life/wp-content/themes/l/img/lula-logo.svg"
                  alt="Lula Logo"
                  className="lula-logo"
                  target="_blank"
                />
              </a>
              <a
                href="https://itunes.apple.com/us/app/lula-pros/id1207212221"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/Images/app_store.png" alt="iphone app" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.lula.lulaprovider"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/Images/google_play.png" alt="android app" />
              </a>
            </Col>
            <Col xs="6">
              <h5>Company</h5>
              <List>
                <li>
                  <a
                    href="https://lula.life/company"
                    className="footer-nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="https://lula.life/blog/"
                    className="footer-nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="https://lula.life/careers/"
                    className="footer-nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Careers
                  </a>
                </li>
                <li>
                  <a
                    href="https://lula.life/terms-of-service/"
                    className="footer-nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a
                    href="https://lula.life/privacy-policy/"
                    className="footer-nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </List>
            </Col>
            <Col xs="6">
              <h5>Platform</h5>
              <List>
                <li>
                  <a
                    href="https://lula.io/home-maintenance-providers"
                    className="footer-nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Become a Provider
                  </a>
                </li>
                <li>
                  <a
                    href="https://lula.life/how-it-works/"
                    className="footer-nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    How it Works
                  </a>
                </li>
                <li>
                  <a
                    href="https://lula.life/guarantee/"
                    className="footer-nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Guarantee
                  </a>
                </li>
                <li>
                  <a
                    href="https://lula.life/support/"
                    className="footer-nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Support
                  </a>
                </li>
              </List>
            </Col>
            <Col>
              <h5>Staying Connected</h5>
              <div className="social">
                <a
                  href="https://www.facebook.com/lulaondemand"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://twitter.com/lulaondemand"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.instagram.com/lulaondemand"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
              </div>
              <hr />
              <img
                src="/Images/make-a-wish.png"
                alt="Make a wish logo"
                className="make-a-wish"
                onClick={() => {
                  setShowMakeAWishModal(true);
                }}
                role="presentation"
              />
              <div className="call-wrapper">
                Call us: &nbsp;
                <a href="tel:8775236936"> 877-523-6936</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-tail">
        <p>Lula &#169; all rights reserved</p>
      </div>
      {showMakeAWishModal
        ? (
          <MakeAWishModal
            closeModal={() => setShowMakeAWishModal(false)}
          />
        ) : ''}
    </React.Fragment>
  );
};

export default Footer;
