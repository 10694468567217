import React from 'react';
import { Table } from 'reactstrap';
import { isEmpty } from 'lodash';

const ServicePricingListing = (props) => {
  const {
    mappings,
    type,
    dataSource,
    className,
    isLoading,
    customNoDataMessage,
    primaryKey,
  } = props;

  return (
    <>
      <Table responsive className={`${className || ''}`}>
        <thead>
          <tr>
            {mappings?.map((cell) => (
              <th
                className={cell?.className ?? ''}
                key={`table-heading-${cell?.key}`}
              >
                {cell?.renderLabel ? cell?.renderLabel(cell) : cell.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading
            && dataSource?.map((item, i) => (
              <React.Fragment key={`${type}-${item?.market_city_id}`}>
                <tr className="splitter-tr">
                  <td colSpan={mappings?.length} />
                </tr>
                {item?.pricing_details?.map((pricingObj) => (
                  <tr key={pricingObj[primaryKey]} className="data-tr">
                    {mappings?.map((cell) => (
                      <td
                        key={`pricing-${pricingObj[primaryKey]}-row-cell-${cell?.key}`}
                        className={`${cell?.className ? cell?.className : ''}`}
                        role="presentation"
                      >
                        {cell.render
                          ? cell.render(pricingObj)
                          : pricingObj[cell?.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          {isEmpty(dataSource) && customNoDataMessage && customNoDataMessage()}
        </tbody>
      </Table>
      {isLoading ? (
        <div className="loader-dual-ring" />
      ) : (
        ''
      )}
      {isEmpty(dataSource) && !isLoading && !customNoDataMessage ? (
        <span className="no-data">No Data</span>
      ) : (
        ''
      )}
    </>
  );
};

export default ServicePricingListing;
