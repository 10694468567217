import { isEmpty } from 'lodash';
import * as yup from 'yup';

const useCompetencyTest = (questionsData) => {
  const validations = {};

  const fillValidation = (question, name) => {
    if (question?.type === 'textarea' || question?.type === 'text') {
      validations[name] = yup
        .string()
        .matches(
          /^(?!\s+$).*/,
          'This field cannot have only blank spaces in it. ',
        )
        .required('This is a required field');
    } else if (question.type === 'radio') {
      validations[name] = yup
        .string().required('This is a required field');
    }
  };
  if (!isEmpty(questionsData)) {
    Object.keys(questionsData || {})?.forEach((questionKey) => {
      const questionObj = questionsData[questionKey];
      fillValidation(questionObj, questionKey);
    });
  }
  const questionsSchema = yup.object().shape(validations);
  return [questionsSchema];
};

export default useCompetencyTest;
