import React from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const MakeAWishModal = (props) => {
  const { closeModal } = props;
  return (
    <Modal isOpen toggle={closeModal} className="make-a-wish-modal">
      <ModalHeader toggle={closeModal}>
        Help Make a Child’s Wish Come True
      </ModalHeader>
      <ModalBody>
        <div>
          <h3>- A Note From CEO, Bo Lais</h3>
          <p>
            Losing a child is something no one can prepare for, no matter what
            the circumstances.On March 10, 2016 my five year old nephew, Hudson
            LaMar Lais, passed away in the comforting arms of his parents and
            surrounded by his loving family. Although he was not on this Earth
            long, his strength and resilience served as an inspiration to
            everyone who knew him. “Be strong like Hudson,” has become my
            family’s rallying cry in tough times. Hudson loved the water and
            feeling the wind at his back. In July of 2015, the Make-A-Wish
            Foundation made Hudson’s wish come true as our family was honored to
            receive a private cruise for the day on the Atlantic ocean. It was
            truly a magical experience for everyone involved. When I started
            Lula, I knew I wanted, in some small way, to give back and let other
            families experience the joy and blessing that we were able to share.
            That is why I am proud to say we donate a small percentage of every
            job transaction to the Make-A-Wish Foundation in Hudson’s honor. So
            a deep and heartfelt thank you to everyone who uses Lula for
            allowing me to honor the memory of a good kid and for helping make
            other families wishes come true.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <a href="http://stronglikehudson.com/" target="_blank" rel="noreferrer" className="btn-green-solid-type">
          READ HUDSON’S STORY
        </a>
      </ModalFooter>
    </Modal>
  );
};

export default MakeAWishModal;
