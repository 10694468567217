import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import PortalLayout from './components/layout/portal';
import Profile from './components/signup/profile';
import portalRoutes from './routes';
import './sass/App.scss';

function App() {
  const faviconEl = document.getElementById('favicon');

  useEffect(() => {
    if (faviconEl) {
      if (
        process.env.REACT_APP_FAVICON_NAME === 'fav-qa.png'
        || process.env.REACT_APP_FAVICON_NAME === 'fav-dev.png'
        || process.env.REACT_APP_FAVICON_NAME === 'fav-staging.png'
      ) {
        faviconEl.href = `/${process.env.REACT_APP_FAVICON_NAME}`;
      } else {
        faviconEl.href = '/favicon.png';
      }
    }
  }, [faviconEl]);

  return (
    <Routes>
      <Route element={<PortalLayout />}>
        {portalRoutes.map((prop) => {
          if (prop.redirect) {
            return (
              <Route
                key={prop.key}
                path={prop.path}
                element={<Navigate to={prop.to} key={prop.key} replace />}
              />
            );
          }
          return (
            <Route
              path={prop.path}
              element={prop.element}
              key={prop.key}
            />
          );
        })}
      </Route>
      <Route
        key="profile"
        path="/profile"
        element={<Profile />}
      />
    </Routes>
  );
}

export default App;
