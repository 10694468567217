import { toast } from 'react-toastify';

const catchHandler = (error, setError, setFieldError, handleError) => {
  if (error.response && error.response.data && error.response.data.message) {
    if (error.response.data.message) {
      const errorMessage = error.response.status === 401
        ? 'Something went wrong'
        : error.response.data.message || 'Something went wrong';
      toast.error(errorMessage);
    }
  }
  if (
    error.response
    && error.response.data
    && error.response.data.errors
    && setFieldError
  ) {
    Object.keys(error?.response?.data?.errors || {})?.forEach((field) => {
      const errorMessage = error.response.data.errors[field][0];
      setError(setFieldError ? field : 'general_errors', {
        type: 'manual',
        message: errorMessage,
      });
    });
  }
  if (handleError) {
    handleError(error);
  }
};
export default catchHandler;
