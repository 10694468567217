import React from 'react';

const WarningIcon = (props) => (
  <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 0C4.9346 0 0 4.93467 0 11.0001C0 17.0655 4.9346 22 11 22C17.0654 22 22 17.0655 22 11.0001C22 4.93467 17.0654 0 11 0ZM11 20C6.03733 20 2 15.9627 2 11.0001C2 6.03747 6.03733 2 11 2C15.9627 2 20 6.03747 20 11.0001C20 15.9627 15.9626 20 11 20Z" fill="#FF8E00" />
    <path d="M10.9998 4.66699C10.2647 4.66699 9.66675 5.26539 9.66675 6.00093C9.66675 6.73579 10.2647 7.33366 10.9998 7.33366C11.7349 7.33366 12.3329 6.73579 12.3329 6.00093C12.3329 5.26539 11.7349 4.66699 10.9998 4.66699Z" fill="#FF8E00" />
    <path d="M11 9.33301C10.4477 9.33301 10 9.78074 10 10.333V16.333C10 16.8853 10.4477 17.333 11 17.333C11.5523 17.333 12 16.8853 12 16.333V10.333C12 9.78074 11.5523 9.33301 11 9.33301Z" fill="#FF8E00" />
  </svg>

);
export default WarningIcon;
