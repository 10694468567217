import React from 'react';

const BrowseSm = (props) => (
  <svg
    {...props}
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4909 4.65162C17.1587 3.3235 16.3922 2.14445 15.3133 1.3017C14.2344 0.458946 12.9049 0.000795965 11.5358 1.03619e-06C10.1668 -0.000793893 8.83672 0.455812 7.75683 1.29731C6.67694 2.13881 5.90914 3.31697 5.57534 4.6447C4.00082 4.79247 2.54373 5.5411 1.50674 6.73509C0.46975 7.92909 -0.0674299 9.47667 0.00678097 11.0564C0.0809919 12.6361 0.760889 14.1265 1.90525 15.218C3.0496 16.3095 4.57046 16.9182 6.1519 16.9178H8.45815C8.66203 16.9178 8.85757 16.8368 9.00173 16.6926C9.1459 16.5484 9.2269 16.3529 9.2269 16.149C9.2269 15.9451 9.1459 15.7496 9.00173 15.6054C8.85757 15.4613 8.66203 15.3803 8.45815 15.3803H6.1519C5.54618 15.382 4.94605 15.2645 4.38576 15.0343C3.82547 14.8041 3.316 14.4659 2.88644 14.0388C2.01891 13.1763 1.52953 12.0045 1.52596 10.7812C1.5224 9.55792 2.00493 8.3833 2.86742 7.51577C3.7299 6.64823 4.90169 6.15886 6.125 6.15529C6.32225 6.17014 6.51799 6.11119 6.67424 5.98988C6.83049 5.86858 6.93611 5.69356 6.97062 5.49878C7.12716 4.40105 7.67447 3.39665 8.51205 2.67004C9.34963 1.94343 10.4212 1.54339 11.5301 1.54339C12.6389 1.54339 13.7105 1.94343 14.5481 2.67004C15.3857 3.39665 15.933 4.40105 16.0895 5.49878C16.1297 5.68679 16.2339 5.85502 16.3844 5.97475C16.5348 6.09448 16.7221 6.15829 16.9144 6.15529C18.1377 6.15529 19.3109 6.64124 20.1759 7.50625C21.0409 8.37126 21.5269 9.54447 21.5269 10.7678C21.5269 11.9911 21.0409 13.1643 20.1759 14.0293C19.3109 14.8943 18.1377 15.3803 16.9144 15.3803H14.6081C14.4042 15.3803 14.2087 15.4613 14.0645 15.6054C13.9204 15.7496 13.8394 15.9451 13.8394 16.149C13.8394 16.3529 13.9204 16.5484 14.0645 16.6926C14.2087 16.8368 14.4042 16.9178 14.6081 16.9178H16.9144C18.4842 16.9013 19.9884 16.2851 21.1186 15.1954C22.2488 14.1058 22.9195 12.6251 22.9932 11.0569C23.0669 9.48864 22.5381 7.95159 21.5151 6.76069C20.4921 5.5698 19.0523 4.81523 17.4909 4.65162Z"
      fill="#1D66FF"
    />
    <path
      d="M14.8332 11.3111C14.9782 11.4511 15.1724 11.5286 15.3739 11.5269C15.5755 11.5251 15.7683 11.4443 15.9109 11.3017C16.0534 11.1592 16.1342 10.9664 16.136 10.7648C16.1377 10.5633 16.0603 10.3691 15.9202 10.2241L12.0765 6.38035C11.9323 6.23623 11.7368 6.15527 11.533 6.15527C11.3291 6.15527 11.1336 6.23623 10.9895 6.38035L7.14573 10.2241C7.00569 10.3691 6.92821 10.5633 6.92996 10.7648C6.93171 10.9664 7.01256 11.1592 7.15509 11.3017C7.29762 11.4443 7.49044 11.5251 7.692 11.5269C7.89356 11.5286 8.08775 11.4511 8.23274 11.3111L10.7642 8.77961V18.4551C10.7642 18.659 10.8452 18.8545 10.9894 18.9987C11.1336 19.1428 11.3291 19.2238 11.533 19.2238C11.7369 19.2238 11.9324 19.1428 12.0766 18.9987C12.2207 18.8545 12.3017 18.659 12.3017 18.4551V8.77961L14.8332 11.3111Z"
      fill="#1D66FF"
    />
  </svg>
);

export default BrowseSm;
