import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { StatusHigh } from '../../../assets/images/svg-icons';
import { scrollToError } from '../../../helpers/utils';
import CustomNumberField from '../../shared/form-fields/custom-number-field';
import {
  addServicePricing,
  editServicePricing,
} from '../../../services/pro-signup-services';
import catchHandler from '../../../helpers/catch-handler';

const THRESHOLD_LIMIT = 80;

const ServicePricingModal = ({ closeModal, data }) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showWarningForAddtHour, setShowWarningForAddtHour] = useState(false);
  const [showWarningForFirstHour, setShowWarningForFirstHour] = useState(false);

  const generatePriceErrorMessage = (key) => {
    const minValue = data?.service_pricing_cap?.[key]?.min_price;
    const maxValue = data?.service_pricing_cap?.[key]?.max_price;
    return {
      type: 'manual',
      message: `The entered price must be between $${minValue} and $${maxValue}`,
    };
  };

  const schema = Yup.object().shape({
    first_hour: Yup.string()
      .required('This is required field')
      .test((val, { createError }) => {
        const minPrice = Number(data?.service_pricing_cap?.base_price_cap?.min_price);
        const maxPrice = Number(data?.service_pricing_cap?.base_price_cap?.max_price);
        if (minPrice && (val < minPrice)) {
          return createError(generatePriceErrorMessage('base_price_cap'));
        }
        if (maxPrice && (val > maxPrice)) {
          return createError(generatePriceErrorMessage('base_price_cap'));
        }
        return true;
      }),
    additional_hour: Yup.string()
      .required('This is required field')
      .test((val, { createError }) => {
        const minPrice = Number(data?.service_pricing_cap?.additional_hour_price_cap?.min_price);
        const maxPrice = Number(data?.service_pricing_cap?.additional_hour_price_cap?.max_price);
        if (
          minPrice
          && (val < minPrice)
        ) {
          return createError(
            generatePriceErrorMessage('additional_hour_price_cap'),
          );
        }
        if (
          maxPrice
          && (val > maxPrice)
        ) {
          return createError(
            generatePriceErrorMessage('additional_hour_price_cap'),
          );
        }
        return true;
      }),
  });

  const buildFormData = () => ({
    first_hour: data?.base_price ?? '',
    additional_hour: data?.additional_hour_price ?? '',
  });
  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(),
    resolver: yupResolver(schema),
  });
  const {
    formState: { errors },
    setError,
    watch,
  } = methods;

  const onSubmit = (formData) => {
    const payload = {
      service_category_id: data?.service_category_id,
      is_after_hour_pricing: data?.type === 'after_hour_rates' ? 1 : 0,
      base_price: Number(formData?.first_hour)?.toFixed(2),
      additional_hour_price: Number(formData?.additional_hour)?.toFixed(2),
      ...(!Number(data?.can_update_service_price) ? {
        market_city_id: data?.market_city_id,
        ...(data?.pricing_id ? { pricing_id: data?.pricing_id } : {}),
      } : {}),
    };
    setIsButtonLoading(true);
    if (Number(data?.can_update_service_price) === 1) {
      editServicePricing(data?.pricing_id, payload)
        .then((res) => {
          toast.success(res?.data?.message ?? 'Saved successfully');
          setIsButtonLoading(false);
          closeModal(true);
        })
        .catch((error) => {
          setIsButtonLoading(false);
          catchHandler(error, setError, true);
        });
    } else {
      addServicePricing(payload)
        .then((res) => {
          toast.success(res?.data?.message ?? 'Saved successfully');
          setIsButtonLoading(false);
          closeModal(true);
        })
        .catch((error) => {
          setIsButtonLoading(false);
          catchHandler(error, setError, true);
        });
    }
  };
  const onError = (formErrors) => {
    scrollToError(formErrors, true);
  };

  const calcThresholdValue = (key) => {
    const minValue = Number(data?.service_pricing_cap?.[key]?.min_price ?? 100);
    const maxValue = Number(data?.service_pricing_cap?.[key]?.max_price ?? 100);
    const threshold = (THRESHOLD_LIMIT / 100) * (maxValue - minValue) + minValue;
    return threshold;
  };

  const calcPercentage = (key, input) => {
    const inputValue = Number(input);
    const minValue = Number(data?.service_pricing_cap?.[key]?.min_price ?? 0);
    const maxValue = Number(data?.service_pricing_cap?.[key]?.max_price ?? 0);
    const range = maxValue - minValue;
    const correctedStartValue = inputValue - minValue;
    const percentage = (correctedStartValue * 100) / range;
    return 100 - percentage;
  };

  const showPercentage = (key, input = 0) => {
    const inputValue = Number(input);
    const minValue = Number(data?.service_pricing_cap?.[key]?.min_price ?? 0);
    const maxValue = Number(data?.service_pricing_cap?.[key]?.max_price ?? 0);
    if (inputValue >= minValue && inputValue <= maxValue) {
      return true;
    }
    return false;
  };

  const {
    first_hour: firstHour,
    additional_hour: additionalHour,
  } = watch();

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(data?.focusField);
      if (data?.focusField && element) {
        element.focus();
      }
    }, 0);
  }, [data?.focusField]);

  return (
    <Modal
      isOpen
      toggle={() => closeModal(false)}
      className="signup-modal sla-agreement-modal"
      centered
    >
      <ModalHeader toggle={() => closeModal(false)}>
        {Number(data?.can_update_service_price) === 1 ? 'Change' : 'Add'}
        {' '}
        Your Pricing
      </ModalHeader>
      <ModalBody>
        <div className="service-pricing-data">
          <div className="preferred-service">
            <div className="service-name">{data?.service_category}</div>
            <div className="hours">
              {data?.type === 'business_hour_rates'
                ? 'Business Hours (7am-5pm)'
                : 'After Hours, M-F 5pm-7am, Sat/Sun'}
            </div>
          </div>
          <div className="service-info">
            <h4>Your Rates</h4>
            <p>
              Please enter your rates for first hour and each additional hour.
              Note that higher prices will see fewer jobs.
            </p>
          </div>
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
              <Row>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label for="" className="mb-0">
                      For the first hour
                    </Label>
                    <div
                      className="input-outer"
                      role="presentation"
                      onClick={() => document.getElementById('first_hour').focus()}
                    >
                      <CustomNumberField
                        name="first_hour"
                        id="first_hour"
                        prefix="$"
                        className="form-control"
                        decimalScale={2}
                        hideFormError
                      />
                      {firstHour
                      && firstHour > calcThresholdValue('base_price_cap') ? (
                        <div
                          className="status error"
                          onMouseEnter={() => setShowWarningForFirstHour(true)}
                          onMouseLeave={() => setShowWarningForFirstHour(false)}
                        >
                          {showWarningForFirstHour ? (
                            <div className="price-bubble">
                              Higher rates will get fewer jobs
                            </div>
                          ) : ''}
                          <StatusHigh />
                          High
                        </div>
                        ) : (
                          ''
                        )}
                    </div>
                  </FormGroup>
                  <ErrorMessage
                    errors={errors}
                    name="first_hour"
                    render={({ message }) => (
                      <small className="text-danger">{message}</small>
                    )}
                  />
                </Col>
                <Col xs="12" md="8" className="text-center">
                  <div className="pricing-chart">
                    <div className="price min">
                      <span>
                        $
                        {data?.service_pricing_cap?.base_price_cap?.min_price
                          || 0}
                      </span>
                    </div>
                    <div className="price-progress">
                      <div className="chart-base">
                        <div
                          className="chart-overlay"
                          style={
                            showPercentage('base_price_cap', firstHour)
                              ? {
                                width: `${calcPercentage(
                                  'base_price_cap',
                                  firstHour,
                                )}%`,
                              }
                              : {
                                width: '100%',
                                borderLeft: 'none',
                              }
                          }
                        >
                          {showPercentage('base_price_cap', firstHour) ? (
                            <div
                              className="price-bubble"
                            >
                              <div className="your-price">
                                $
                                {firstHour || 0}
                              </div>
                              <small>Your Rate</small>
                            </div>
                          ) : ''}
                        </div>
                      </div>
                    </div>
                    <div className="price max">
                      <span>
                        $
                        {data?.service_pricing_cap?.base_price_cap?.max_price
                          || 0}
                      </span>
                      <small>(Cap/Max)</small>
                    </div>
                  </div>
                  <p className="pricing-label">
                    1st Hr Avg.
                    {' '}
                    {data?.service_category}
                    {' '}
                    Pricing in
                    {' '}
                    {data?.market_city}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label for="" className="mb-0">
                      For each additional hour
                    </Label>
                    <div
                      className="input-outer"
                      role="presentation"
                      onClick={() => document.getElementById('additional_hour').focus()}
                    >
                      <CustomNumberField
                        name="additional_hour"
                        id="additional_hour"
                        prefix="$"
                        className="form-control"
                        hideFormError
                        decimalScale={2}
                      />
                      {additionalHour
                      && additionalHour
                        > calcThresholdValue('additional_hour_price_cap') ? (
                          <div
                            className="status error"
                            onMouseEnter={() => setShowWarningForAddtHour(true)}
                            onMouseLeave={() => setShowWarningForAddtHour(false)}
                          >
                            {showWarningForAddtHour ? (
                              <div className="price-bubble">
                                Higher rates will get fewer jobs
                              </div>
                            ) : ''}
                            <StatusHigh />
                            High
                          </div>
                        ) : (
                          ''
                        )}
                    </div>
                  </FormGroup>
                  <ErrorMessage
                    errors={errors}
                    name="additional_hour"
                    render={({ message }) => (
                      <small className="text-danger">{message}</small>
                    )}
                  />
                </Col>
                <Col xs="12" md="8" className="text-center">
                  <div className="pricing-chart">
                    <div className="price min">
                      <span>
                        $
                        {data?.service_pricing_cap?.additional_hour_price_cap
                          ?.min_price || 0}
                      </span>
                    </div>
                    <div className="price-progress">
                      <div className="chart-base">
                        <div
                          className="chart-overlay"
                          style={
                            showPercentage(
                              'additional_hour_price_cap',
                              additionalHour,
                            )
                              ? {
                                width: `${calcPercentage(
                                  'additional_hour_price_cap',
                                  additionalHour,
                                )}%`,
                              }
                              : {
                                width: '100%',
                                borderLeft: 'none',
                              }
                          }
                        >
                          {showPercentage('additional_hour_price_cap', additionalHour) ? (
                            <div
                              className="price-bubble"
                            >
                              <div className="your-price">
                                $
                                {additionalHour}
                              </div>
                              <small>Your Rate</small>
                            </div>
                          ) : ''}
                        </div>
                      </div>
                    </div>
                    <div className="price max">
                      <span>
                        $
                        {data?.service_pricing_cap?.additional_hour_price_cap
                          ?.max_price || 0}
                      </span>
                      <small>(Cap/Max)</small>
                    </div>
                  </div>
                  <p className="pricing-label">
                    Avg. Hourly
                    {' '}
                    {data?.service_category}
                    {' '}
                    Pricing in
                    {' '}
                    {data?.market_city}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="service-price-btn-outer">
                    <Button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => closeModal(false)}
                    >
                      Discard
                    </Button>
                    <Button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isButtonLoading}
                    >
                      {isButtonLoading ? (
                        <div className="loader-dual-ring" />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ServicePricingModal;
