import React from 'react';
import { map } from 'lodash';

import SelectField from '../../../shared/form-fields/select-field';

const Dropdown = ({ name, question }) => {
  const options = map(question.options, (option) => ({
    label: option.label,
    value: option.slug,
  }));
  return (
    <div className="mb-5">
      <SelectField
        name={name}
        options={options}
        className="selectbox-regular"
        shouldUnregister
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

export default Dropdown;
