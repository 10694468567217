import { isEmpty } from 'lodash';
import * as yup from 'yup';

const useServiceQuestions = (services) => {
  const validations = {};
  const fillValidationAndDefault = (
    question,
    name,
    service,
    categoryQuestions,
  ) => {
    if (question?.type === 'checkbox') {
      const isLater = question?.slug?.split('-');
      if (!isLater?.includes('later')) {
        validations[name] = yup
          .boolean()
          .oneOf([true], 'This field is required')
          .nullable();
      }
    } else {
      const laterQuestion = categoryQuestions?.filter(
        (item) => item.slug === `${question?.slug}-later`,
      );
      if (!isEmpty(laterQuestion) && laterQuestion[0]?.service_question_id) {
        validations[name] = yup
          .string()
          .when(
            [
              `question_${laterQuestion[0]?.service_question_id}`,
            ],
            {
              is: (laterQtn) => !laterQtn,
              then: yup
                .string()
                .matches(
                  /^(?!\s+$).*/,
                  'This field cannot have only blank spaces in it. ',
                )
                .required('This is a required field'),
            },
          );
      } else {
        validations[name] = yup
          .string()
          .matches(
            /^(?!\s+$).*/,
            'This field cannot have only blank spaces in it. ',
          )
          .required('This is a required field');
      }
    }
  };

  if (!isEmpty(services)) {
    services?.forEach((service) => {
      // Loop through each question and build schema
      service?.service_questions?.forEach((question) => {
        fillValidationAndDefault(
          question,
          `question_${question.service_question_id}`,
          service,
          service?.service_questions,
        );
      });
    });
  }
  const questionsSchema = yup.object().shape(validations);
  return [questionsSchema];
};

export default useServiceQuestions;
