import React from 'react';

const CitiesServed = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.53857 11.0769H7.38473V12.9231H5.53857V11.0769Z" fill="white" />
    <path d="M12.9229 3.69238H14.769V5.53854H12.9229V3.69238Z" fill="white" />
    <path d="M5.53857 14.7693H7.38473V16.6154H5.53857V14.7693Z" fill="white" />
    <path d="M9.23096 11.0769H11.0771V12.9231H9.23096V11.0769Z" fill="white" />
    <path d="M16.6152 3.69238H18.4614V5.53854H16.6152V3.69238Z" fill="white" />
    <path d="M9.23096 14.7693H11.0771V16.6154H9.23096V14.7693Z" fill="white" />
    <path d="M12.9229 11.0769H14.769V12.9231H12.9229V11.0769Z" fill="white" />
    <path d="M12.9229 14.7693H14.769V16.6154H12.9229V14.7693Z" fill="white" />
    <path d="M23.0769 22.1538H22.1538V0.923077C22.1538 0.678262 22.0566 0.443473 21.8835 0.270363C21.7104 0.0972524 21.4756 0 21.2308 0H10.1538C9.90903 0 9.67424 0.0972524 9.50113 0.270363C9.32802 0.443473 9.23077 0.678262 9.23077 0.923077V4.61538C9.23077 4.8602 9.32802 5.09499 9.50113 5.2681C9.67424 5.44121 9.90903 5.53846 10.1538 5.53846C10.3987 5.53846 10.6334 5.44121 10.8066 5.2681C10.9797 5.09499 11.0769 4.8602 11.0769 4.61538V1.84615H20.3077V22.1538H12.9231V19.3846C12.9231 19.1398 12.8258 18.905 12.6527 18.7319C12.4796 18.5588 12.2448 18.4615 12 18.4615H8.30769C8.06288 18.4615 7.82809 18.5588 7.65498 18.7319C7.48187 18.905 7.38462 19.1398 7.38462 19.3846V22.1538H3.69231V9.23077H16.6154V19.3846C16.6154 19.6294 16.7126 19.8642 16.8857 20.0373C17.0589 20.2104 17.2936 20.3077 17.5385 20.3077C17.7833 20.3077 18.0181 20.2104 18.1912 20.0373C18.3643 19.8642 18.4615 19.6294 18.4615 19.3846V8.30769C18.4615 8.06288 18.3643 7.82809 18.1912 7.65498C18.0181 7.48187 17.7833 7.38462 17.5385 7.38462H2.76923C2.52442 7.38462 2.28963 7.48187 2.11652 7.65498C1.94341 7.82809 1.84615 8.06288 1.84615 8.30769V22.1538H0.923077C0.678262 22.1538 0.443473 22.2511 0.270363 22.4242C0.0972524 22.5973 0 22.8321 0 23.0769C0 23.3217 0.0972524 23.5565 0.270363 23.7296C0.443473 23.9027 0.678262 24 0.923077 24H23.0769C23.3217 24 23.5565 23.9027 23.7296 23.7296C23.9027 23.5565 24 23.3217 24 23.0769C24 22.8321 23.9027 22.5973 23.7296 22.4242C23.5565 22.2511 23.3217 22.1538 23.0769 22.1538ZM9.23077 20.3077H11.0769V22.1538H9.23077V20.3077Z" fill="white" />
  </svg>
);
export default CitiesServed;
