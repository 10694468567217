import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Button,
  Form,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { InofIconBlack } from '../../../../assets/images/svg-icons';
import RadioArray from '../../../shared/form-fields/radio-array';

const ServiceFreeQuoteConfirmationModal = ({
  hideModal,
  lastSelectedService,
  onConfirmHandler,
}) => {
  const schema = Yup.object().shape({
    freeQuote: Yup.string().required('Please choose an option'),
  });

  const methods = useForm({
    mode: 'all',
    defaultValues: { freeQuote: '' },
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    onConfirmHandler(formData);
  };

  const yesOrNoOptions = [
    { id: 1, label: 'Yes' },
    { id: 0, label: 'No' },
  ];

  return (
    <Modal isOpen className="signup-modal" centered>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => hideModal(lastSelectedService?.service_category_id)}
          >
            Select Service Info
          </ModalHeader>
          <ModalBody>
            <h5 className="mb-2">{lastSelectedService?.label}</h5>
            <p>
              These will be various jobs related to the
              {' '}
              {lastSelectedService?.label?.toLowerCase()}
              {' '}
              skill set. Please only select this service if you are proficient at this trade.
            </p>
            <div className="hightlight-box">
              <InofIconBlack />
              <div className="question-wrapper">
                <p>
                  For some larger jobs we will want to get estimates to present
                  to our customer. Are you willing to provide free quotes on
                  larger jobs?
                </p>
                <Row>
                  <RadioArray
                    type="radio"
                    name="freeQuote"
                    options={yesOrNoOptions}
                    colProps={{ xs: 'auto', md: '2' }}
                    formGroupClass="mb-2"
                  />
                </Row>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="modal-btn"
              outline
              onClick={() => hideModal(lastSelectedService?.service_category_id)}
            >
              Discard
            </Button>
            {' '}
            <Button type="submit" color="primary" className="modal-btn">
              Confirm
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ServiceFreeQuoteConfirmationModal;
