import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  Input,
} from 'reactstrap';

import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import ServicePricingModal from './service-pricing-modal';
import { completeServicePricing, getServicePricing } from '../../../services/pro-signup-services';
import catchHandler from '../../../helpers/catch-handler';
import ServicePricingRedirect from './service-pricing-redirect';
import { getStepCount } from '../../wizard/progress';
import { fetchProfile } from '../profile';
import { useProfile } from '../../../context/profile-context/profile-context';
import ServicePricingListing from './service-pricing-listing';

const ServicePricing = () => {
  const [showServicePricingModal, setShowServicePricingModal] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [showAfterHours, setShowAfterHours] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [runningCountdownStopped, setRunningCountdownStopped] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { setProfileDetails } = useProfile();

  const fetchServicePricingDetails = (setValueForShowAfterHour = false) => {
    setFetchingDetails(true);
    getServicePricing()
      .then((res) => {
        setFetchingDetails(false);
        if (setValueForShowAfterHour && (Number(res?.data?.is_after_hour_enabled) === 1)) {
          setShowAfterHours(true);
        }
        setDataSource(res?.data);
      })
      .catch((err) => {
        setFetchingDetails(false);
        catchHandler(err);
      });
  };

  useEffect(() => {
    fetchServicePricingDetails(true);
  }, []);

  const mapping = (type) => [
    {
      key: 'service_category',
      label: 'Service',
    },
    {
      key: 'market_city',
      label: 'Market',
    },
    {
      key: 'your_rates',
      label: 'Your Rates',
      render: (data) => (
        <div className="price">
          <div className="price--item">
            <div className="label">First Hour</div>
            {data?.base_price ? (
              <div className="added-price">
                $
                {data?.base_price}
              </div>
            ) : (
              <Button
                type="button"
                className="add-price"
                onClick={() => {
                  setShowServicePricingModal({ ...data, type, focusField: 'first_hour' });
                }}
              >
                Add Price
              </Button>
            )}
          </div>
          <div className="price--item">
            <div className="label">Each Addtl. Hour:</div>
            {data?.additional_hour_price ? (
              <div className="added-price">
                $
                {data?.additional_hour_price}
              </div>
            ) : (
              <Button
                type="button"
                className="add-price"
                onClick={() => {
                  setShowServicePricingModal({ ...data, type, focusField: 'additional_hour' });
                }}
              >
                Add Price
              </Button>
            )}
          </div>
        </div>
      ),
    },
    {
      key: 'market_city_average_rate',
      label: 'Your Market City Average Rates',
      render: (data) => (
        <div className="price">
          <div className="price--item">
            <div className="label">First Hour</div>
            <div className="amount">
              $
              {data?.service_pricing_cap?.base_price_cap?.min_price}
              -$
              {data?.service_pricing_cap?.base_price_cap?.max_price}
            </div>
          </div>
          <div className="price--item">
            <div className="label">Each Addtl. Hour:</div>
            <div className="amount">
              $
              {data?.service_pricing_cap?.additional_hour_price_cap?.min_price}
              -$
              {data?.service_pricing_cap?.additional_hour_price_cap?.max_price}
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'action',
      label: 'Action',
      render: (data) => (
        <Button
          color="secondary"
          block
          type="button"
          className={Number(data?.can_update_service_price) === 1 ? 'btn-blue-outline' : 'btn-blue-solid'}
          onClick={() => {
            setShowServicePricingModal({ ...data, type, focusField: 'first_hour' });
          }}
        >
          {Number(data?.can_update_service_price) === 1 ? 'Change Pricing' : 'Add Pricing'}
        </Button>
      ),
    },
  ];

  const onSubmit = () => {
    setRunningCountdownStopped(true);
    setIsLoading(true);
    completeServicePricing({
      is_after_hour_enabled: showAfterHours,
    }).then(() => {
      fetchProfile(setIsLoading, navigate, setProfileDetails, {
        current_step: getStepCount(location?.pathname) - 1,
      });
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      catchHandler(err, false);
    });
  };

  return fetchingDetails ? (
    <div className="page-loader" />
  ) : (
    <Container>
      <Row>
        <Col className="my-4">
          <Row>
            <Col xs="12">
              <h3>Service Pricing</h3>
              <div className="header-divider" />
              {!(isEmpty(dataSource?.after_hour_rates)
              && isEmpty(dataSource?.business_hour_rates)) ? (
                <p className="mt-0 mb-3">
                  Please add your prices for first hour and each additional hour
                  for each service. We work exclusively in the property management
                  space so all jobs will be for rental properties.
                </p>
                ) : (
                  <ServicePricingRedirect
                    onSubmit={onSubmit}
                    runningCountdownStopped={runningCountdownStopped}
                  />
                )}
            </Col>
          </Row>
          {!isEmpty(dataSource?.business_hour_rates) ? (
            <>
              <Row>
                <Col>
                  <h5 className="custom-h5">Business Hours Rates (7am - 5pm)</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ServicePricingListing
                    mappings={mapping('business_hour_rates')}
                    className="service-pricing-table service-pricing-table--splitter"
                    dataSource={dataSource?.business_hour_rates}
                    primaryKey="service_category_id"
                    type="business_hour_rates"
                  />
                </Col>
              </Row>
            </>
          ) : ''}
          {!isEmpty(dataSource?.after_hour_rates) ? (
            <>
              <Row>
                <Col>
                  <h5 className="custom-h5">After Hours, M-F 5pm-7am, Sat/Sun (Optional)</h5>
                  <p className="sub-text">
                    We do allow Lula pros to charge a higher price for after hours
                    service calls. However, please note that we use pricing as one
                    of the factors in sending jobs to our qualified Lula pros.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="custom-checkbox">
                    <FormGroup check>
                      <Input
                        id="afterhourRatesToogle"
                        type="checkbox"
                        checked={showAfterHours}
                        onChange={(e) => {
                          setShowAfterHours(e.target.checked);
                        }}
                      />
                      {' '}
                      <Label check className="after-hour-rating" htmlFor="afterhourRatesToogle">
                        I want to enter my rates for after hours.
                      </Label>
                      <span className="af-label">
                        <span>*</span>
                        After hour rates only apply
                        if tenants availability is strictly after hours only.
                      </span>
                    </FormGroup>
                  </div>
                </Col>
              </Row>

              {showAfterHours ? (
                <Row>
                  <Col>
                    <ServicePricingListing
                      mappings={mapping('after_hour_rates')}
                      className="service-pricing-table service-pricing-table--splitter"
                      dataSource={dataSource?.after_hour_rates}
                      primaryKey="service_category_id"
                      type="after_hour_rates"
                    />
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </>
          ) : ''}
          <Row>
            <Col>
              <div className="action-wrapper">
                <Button disabled={isLoading} color="primary" type="button" onClick={onSubmit}>
                  {isLoading ? (
                    <div className="loader-dual-ring" />
                  ) : (
                    'Save & Continue'
                  )}
                </Button>
                <Button color="link" type="button" onClick={() => navigate('/signup/services-offered')}>Previous Step</Button>
              </div>
            </Col>
          </Row>

        </Col>
      </Row>
      {!isEmpty(showServicePricingModal) ? (
        <ServicePricingModal
          data={showServicePricingModal}
          isAfterHourEnabled={showAfterHours}
          closeModal={(fetchListing = false) => {
            setShowServicePricingModal({});
            if (fetchListing) {
              fetchServicePricingDetails();
            }
          }}
        />
      ) : (
        ''
      )}
    </Container>
  );
};

export default ServicePricing;
