import React from 'react';

const AgreementIcon = (props) => (
  <svg {...props} width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.72316 8.93581H11.1696C11.5808 8.93581 11.9142 8.60241 11.9142 8.19117C11.9142 7.77993 11.5808 7.44653 11.1696 7.44653H3.72316C3.31192 7.44653 2.97852 7.77993 2.97852 8.19117C2.97852 8.60241 3.31192 8.93581 3.72316 8.93581Z" fill="white" />
    <path d="M3.72316 11.9141H11.1696C11.5808 11.9141 11.9142 11.5807 11.9142 11.1694C11.9142 10.7582 11.5808 10.4248 11.1696 10.4248H3.72316C3.31192 10.4248 2.97852 10.7582 2.97852 11.1694C2.97852 11.5807 3.31192 11.9141 3.72316 11.9141Z" fill="white" />
    <path d="M3.72316 14.8928H11.1696C11.5808 14.8928 11.9142 14.5594 11.9142 14.1482C11.9142 13.737 11.5808 13.4036 11.1696 13.4036H3.72316C3.31192 13.4036 2.97852 13.737 2.97852 14.1482C2.97852 14.5594 3.31192 14.8928 3.72316 14.8928Z" fill="white" />
    <path d="M5.21244 17.8713C3.98065 17.8713 2.97852 18.8735 2.97852 20.1053C2.97852 21.337 3.98065 22.3392 5.21244 22.3392C6.44422 22.3392 7.44635 21.337 7.44635 20.1053C7.44635 18.8735 6.44422 17.8713 5.21244 17.8713ZM5.21244 20.8499C4.80184 20.8499 4.4678 20.5159 4.4678 20.1053C4.4678 19.6947 4.80184 19.3606 5.21244 19.3606C5.62303 19.3606 5.95708 19.6947 5.95708 20.1053C5.95708 20.5159 5.62303 20.8499 5.21244 20.8499Z" fill="white" />
    <path d="M23.3703 8.95757L23.8969 8.43106C24.1877 8.14025 24.1877 7.6688 23.8969 7.37799C23.6061 7.08718 23.1346 7.08718 22.8438 7.37799L22.3173 7.9045L21.7907 7.37799C21.4999 7.08718 21.0284 7.08718 20.7376 7.37799L19.3606 8.75503V5.21248C19.3606 5.0225 19.2846 4.82834 19.1431 4.68656C19.1428 4.68632 19.1426 4.68607 19.1424 4.68582C19.0291 4.57254 14.7218 0.265141 14.6729 0.216442C14.5374 0.0818111 14.3468 0 14.1482 0H0.74464C0.3334 0 0 0.3334 0 0.74464V24.6724C0 25.0836 0.3334 25.417 0.74464 25.417H18.616C19.0272 25.417 19.3606 25.0836 19.3606 24.6724V15.0734L23.8969 10.5372C24.1877 10.2464 24.1877 9.77494 23.8969 9.48413L23.3703 8.95757ZM13.8022 18.5256C13.1798 19.1482 12.4014 19.5583 11.5552 19.7196C11.7166 18.8734 12.1267 18.095 12.7492 17.4726L18.1049 12.1168L19.158 13.1699L13.8022 18.5256ZM14.8927 2.54235L16.8182 4.46784H14.8927V2.54235ZM17.8713 23.9278H1.48923V1.48928H13.4035V5.21248C13.4035 5.62372 13.7369 5.95712 14.1481 5.95712H17.8713V10.2443L11.6961 16.4195C10.6701 17.4454 10.074 18.7864 9.99739 20.2244L9.15371 21.068C8.8629 21.3589 8.8629 21.8303 9.15371 22.1211C9.44447 22.4119 9.91597 22.412 10.2068 22.1211L11.0505 21.2774C12.4884 21.2009 13.8294 20.6047 14.8553 19.5787L17.8713 16.5627V23.9278ZM20.2111 12.1168L19.158 11.0638L21.2642 8.95762L22.3173 10.0107L20.2111 12.1168Z" fill="white" />
  </svg>
);
export default AgreementIcon;
