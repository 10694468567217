import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormGroup, Label } from 'reactstrap';
import { TickIcon } from '../../../../assets/images/svg-icons';

import InputField from '../../../shared/form-fields/input-field';

const RadioButton = ({ name, question }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <div className="form-outer">
      <div className="mb-3">{question?.question}</div>
      <div className="form-wrapper">
        {Object.keys(question?.extra?.options || {}).map((option) => (
          <FormGroup check key={`${name}.${option}`} className="anti-form-check">
            <div className="form-item">
              <div className="form-radio">
                <Controller
                  name={name}
                  control={control}
                  shouldUnregister
                  render={({ field: { onBlur, onChange, value } }) => (
                    <InputField
                      name={name}
                      type="radio"
                      id={`${name}.${option}`}
                      value={option}
                      onChange={onChange}
                      onBlur={onBlur}
                      hideFormError
                      clearError
                      checked={option === value}
                    />
                  )}
                />
                <TickIcon />
                <Label check for={`${name}.${option}`} name={name}>
                  {question?.extra?.options[option]}
                </Label>
              </div>
            </div>
          </FormGroup>
        ))}
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <small className="text-danger">{message}</small>
        )}
      />
    </div>
  );
};

export default RadioButton;
