/* eslint-disable max-len */
import React from 'react';
import {
  Container, Button, Row, Col,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';

import { LocationHeavyIcon, TickHeavyIcon } from '../../assets/images/svg-icons';
import { useProfile } from '../../context/profile-context/profile-context';

const DonePage = () => {
  const { state } = useLocation();

  const { profileDetails } = useProfile();

  return (
    <Container>
      <Row>
        <Col
          md={{
            offset: 1,
            size: 10,
          }}
        >
          {Number(profileDetails?.is_provider_state_not_in_list)
            ? (
              <div className="text-center done-page area-not-listed">
                <LocationHeavyIcon />
                <div className="title-outer">
                  <h1 className="title">We will contact you once we are in your area!</h1>
                </div>
              </div>
            ) : (
              <div className="text-center done-page">
                <TickHeavyIcon />
                <div className="title-outer">
                  <h1 className="title">Application Received!</h1>
                  <div className="header-divider" />
                </div>
                <p>
                  Your application has been submitted and is in the queue for review. The next step is to upload any necessary documentation, such as business general liability insurance. The review process takes 2-3 business days.
                </p>
                <div className="hightlight-msg">
                  <p>
                    Lula has a unique vetting process that all service providers are evaluated on in order to be considered for approval. Not all service provider applicants will be accepted; however, you will be notified either way with the reason behind Lula&lsquo;s decision.
                  </p>
                  <p>
                    Please call
                    <a href="tel:+18775236936"> 1 (877) 523-6936 </a>
                    or email
                    <a href="mailto:providerdocs@lula.life">
                      {' '}
                      providerdocs@lula.life
                      {' '}
                    </a>
                    with any questions.
                  </p>
                </div>
              </div>
            )}

        </Col>
      </Row>
      <Row>
        <Col>
          {Number(profileDetails?.is_provider_state_not_in_list) ? (
            <div className="action-wrapper provider-state-not-listed-page">
              <Button
                className="btn-primary btn-big"
                onClick={() => {
                  window.open('https://lula.life/', '_self');
                }}
              >
                Get Back to Lula Website
              </Button>
            </div>
          )
            : (
              <div className="action-wrapper">
                <Button
                  className="btn-primary btn-big"
                  onClick={() => {
                    if (state?.dashboardUrl) {
                      window.open(state?.dashboardUrl, '_self');
                    }
                  }}
                  disabled={!state?.dashboardUrl}
                >
                  Continue to My Account
                </Button>
              </div>
            )}
        </Col>
      </Row>

    </Container>
  );
};

export default DonePage;
